import React, {Component} from 'react';
import classes from "./AddPhoto.module.css";
import Image from '../../../model/Image';

class AddPhotoButton extends Component {

    state = {
        inputRef: React.createRef()
    };

    handleClick = () => {
        this.state.inputRef.current.click();
    };

    handleUploadedImage = (e) => {
        const [file] = e.target.files;
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                this.props.handlePhotoAdded(new Image(file, event.target.result, true));
            };
            reader.readAsDataURL(file);
        }
    };

    render() {
        const containerClasses = [classes.PhotoButtonContainer];
        if (this.props.className) {
            containerClasses.push(this.props.className);
        }
        return (
            <div className={classes.Container}>
                <input ref={this.state.inputRef} type="file" accept={['.png', '.jpg']} multiple={false} onChange={e => {
                    this.handleUploadedImage(e);
                    this.state.inputRef.current.value = '';
                }}/>
                <div className={containerClasses.join(' ')} onClick={this.handleClick}>
                    <div>
                        <i className="fas fa-upload" />
                        {this.props.label}
                    </div>
                </div>
            </div>
        );
    }
}

export default AddPhotoButton;
