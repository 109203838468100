import React, {Component, createRef} from "react";
import {SearchContext} from "../../../constants/SearchContexts";
import classes from "./SelectedContext.module.css";


class SelectedContext extends Component {

    state = {
        isDropdownExpanded: false,
        wrapperRef: createRef()
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick);
    }

    handleClick = (event) => {
        if (!!this.state.wrapperRef && !!this.state.wrapperRef.current && !this.state.wrapperRef.current.contains(event.target)) {
            this.setState({isDropdownExpanded : false});
        }
    }

    toggleDropdown = () => {
        this.setState({isDropdownExpanded: !this.state.isDropdownExpanded});
    }

    render() {
        return(
            <div ref={this.state.wrapperRef}>
                <div>
                    {this.props.searchContext === SearchContext.Investors &&
                    <div className={classes.HeaderOptionActive} onClick={this.toggleDropdown}>
                        Investors <span className={classes.Caret}><i className="fas fa-caret-down"/></span>
                    </div>
                    }
                    {this.props.searchContext === SearchContext.Startups &&
                    <div className={classes.HeaderOptionActive} onClick={this.toggleDropdown}>
                        Startups <span className={classes.Caret}><i className="fas fa-caret-down"/></span>
                    </div>
                    }
                    {this.props.searchContext === SearchContext.Personal &&
                    <div className={classes.HeaderOptionActive} onClick={this.toggleDropdown}>
                        People <span className={classes.Caret}><i className="fas fa-caret-down"/></span>
                    </div>
                    }
                </div>
                {this.state.isDropdownExpanded &&
                <div className={classes.Dropdown}>
                    {[SearchContext.All, SearchContext.Investors, SearchContext.Startups, SearchContext.Personal].filter(searchContext => searchContext !== this.props.searchContext).map(searchContext => {
                        return (
                            <div className={classes.DropdownOption} onClick={() => {
                                this.toggleDropdown();
                                this.props.setSearchContext(searchContext);
                            }}>
                                {/*TODO: this is a hack*/}
                                {searchContext !== SearchContext.Personal ? searchContext : 'People'}
                            </div>
                        )
                    })}
                </div>
                }
            </div>
        );
    }
}

export default SelectedContext;
