import React from 'react';

import classes from './noresult.module.css';

const NoResults = () => {
    return (
        <div className={classes.NoResultsContainer}>
            <div className={classes.NoResultsInner}>
                No results found. Try a different query.
            </div>
        </div>
    );
};

export default NoResults;