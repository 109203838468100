import React, {Fragment} from 'react';
import classes from './Footer.module.css';
import {NavLink} from "react-router-dom";

const Footer = () => {
    return (
        <Fragment>
            <div className={classes.FooterContainer}>
                <div className={classes.FooterInner}>
                    <div className={classes.ContactOption}>
                        <a href="https://www.linkedin.com/company/climatehub/" target="_blank">linkedin.com/company/climatehub</a>
                    </div>
                    <div className={classes.ContactOption}>
                        <a href="https://twitter.com/climatehub_io" target="_blank">twitter.com/climatehub_io</a>
                    </div>
                    <div className={classes.ContactOption}>
                        <a href="mailto:hello@climatehub.io?Subject=Hello%20ClimateHub" target="_blank">hello@climatehub.io</a>
                    </div>
                    <div className={classes.ContactOption}>
                        <NavLink to='/terms' target='_blank'>Terms of Service</NavLink>
                    </div>
                    <div className={classes.ContactOption}>
                        <NavLink to='/privacy' target='_blank'>Privacy Policy</NavLink>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Footer;
