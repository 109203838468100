import {FocusAreaFilterOptions} from './filteroptions/FocusAreaFilterOptions';
import {TechnologyFilterOptions} from './filteroptions/TechnologyFilterOptions';
import {BusinessModelFilterOptions} from './filteroptions/BusinessModelFilterOptions';
import {CompanySizeFilterOptions} from './filteroptions/CompanySizeFilterOptions';
import {FundingStageFilterOptions} from './filteroptions/FundingStageFilterOptions';

export const CompanySearchFilters = [
    {
        id: 'focusAreas',
        displayName: 'Focus Areas',
        filterOptions: FocusAreaFilterOptions
    },
    {
        id: 'technologies',
        displayName: 'Technology',
        filterOptions: TechnologyFilterOptions
    },
    {
        id: 'businessModels',
        displayName: 'Business Model',
        filterOptions: BusinessModelFilterOptions
    },
    {
        id: 'numEmployees',
        displayName: 'Startup size',
        filterOptions: CompanySizeFilterOptions
    },
    {
        id: 'fundingStage',
        displayName: 'Funding',
        filterOptions: FundingStageFilterOptions
    }
];
