import React, {Component, createRef} from "react";
import classes from "./EditOverview.module.css";
import SubmitButton from "../../../Forms/SubmitButton/SubmitButton";
import GlobalPopup from "../../../Popup/GlobalPopup/GlobalPopup";
import AppContext from "../../../../context/AppProvider";
import _ from "lodash";
import InputLabel from "../../../Forms/Label/InputLabel";
import stepClasses from "../../Styles/Steps.module.css";
import TextArea from "../../../Forms/Input/TextArea";
import sharedClasses from "../styles/EditableFieldStyles.module.css";

class EditOverview extends Component {

    static contextType = AppContext;

    state = {
        isEditing: false,
        isSubmitting: false,
        wrapperRef: createRef(),
        description: null,
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick);
        this.setState({
            description: this.props.profile.description,
        });
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick);
    }

    handleClick = (event) => {
        if (!!this.state.wrapperRef && !!this.state.wrapperRef.current && !this.state.wrapperRef.current.contains(event.target)) {
            this.closeEditModal();
        }
    }

    openEditModal = () => {
        this.setState({isEditing: true});
    }

    closeEditModal = () => {
        this.setState({isEditing: false, isSubmitting: false, newValue: null});
    }

    selectDescription = (description) => {
        this.setState({description: description});
    }

    handleSave = async () => {
        if (this.state.description !== this.props.profile.description) {
            this.setState({isSubmitting: true}, async () => {
                const updatedProfile = _.cloneDeep(this.props.profile);
                updatedProfile.description = this.state.description;
                await this.context.apiGatewayClient.updateProfile(updatedProfile);
                this.setState({
                    isSubmitting: false,
                    isEditing: false,
                    description: null,
                });
                this.props.setProfile(updatedProfile);
            })
        }
    }

    render() {
        return (
            <div>
                <div className={classes.EditIcon} onClick={this.openEditModal}>
                    Edit overview
                </div>
                {this.state.isEditing &&
                <GlobalPopup onOutsideClick={this.closeEditModal}>
                    <div className={sharedClasses.PopupContainer} ref={this.state.wrapperRef}>
                        <div className={classes.Title}>
                            <h3>Edit {this.props.title}</h3>
                        </div>
                        <div className={sharedClasses.ProfileEditPopup}>
                            <InputLabel required={false}
                                        className={stepClasses.InputLabel}>{this.props.title}</InputLabel>
                            <TextArea value={this.state.description} onChange={this.selectDescription}
                                      placeholder={this.props.title}/>
                        </div>
                        <div className={sharedClasses.SubmitContainer}>
                            <SubmitButton className={classes.CancelOption} onClick={this.closeEditModal}>
                                Cancel
                            </SubmitButton>
                            <SubmitButton className={classes.SaveOption}
                                          isDisabled={this.state.description === this.props.description}
                                          onClick={this.handleSave}
                                          isSubmitting={this.state.isSubmitting}>
                                Save
                            </SubmitButton>
                        </div>
                    </div>
                </GlobalPopup>
                }
            </div>
        );
    }
}

export default EditOverview;
