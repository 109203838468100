import React, {Component, Fragment} from "react";
import classes from "./ProfilePage.module.css";
import sharedClasses from "../../styles/Styles.module.css";
import GlobalPopup from "../Popup/GlobalPopup/GlobalPopup";
import FollowersPopup from "./FollowersPopup/FollowersPopup";
import SocialIcon from "./SocialIcon/SocialIcon";
import AppContext from "../../context/AppProvider";
import Recommendations from "../Recommendations/Recommendations";
import InvestorInformation from "./InvestorInformation/InvestorInformation";
import PlaceHolder from "./PlaceHolder/PlaceHolder";
import Posts from "./Posts/Posts";
import InvestorSubTitle from "./InvestorSubTitle/InvestorSubTitle";
import {ProfileType} from "../../constants/ProfileTypeConstants";
import CompanyInformation from "./CompanyInformation/CompanyInformation";
import CompanySubTitle from "./CompanySubTitle/CompanySubTitle";
import EditProfilePhoto from "./EditableField/ProfilePhoto/EditProfilePhoto";
import EditBanner from "./EditableField/Banner/EditBanner";
import EditInvestorHeader from "./EditableField/Header/EditInvestorHeader";
import EditCompanyHeader from "./EditableField/Header/EditCompanyHeader";
import EditPersonalHeader from "./EditableField/Header/EditPersonalHeader";
import PersonalInformation from "./PersonalInformation/PersonalInformation";
import SubmitButton from "../Forms/SubmitButton/SubmitButton";
import {withRouter} from "react-router-dom";
import {Contexts} from "../Feed/Feed";
import {RecommendationsMatchType} from "../../constants/RecommendationMatchTypeConstants";
import {ProfileAssetsBucketPrefix} from "../../constants/ProfileAssetsBucketPrefix";
import PersonalSubTitle from "./PersonalSubTitle/PersonalSubTitle";
import FollowButton from "../FollowButton/FollowButton";
import SideNav from "../SideNav/SideNav";
import {ArrowLeftIcon} from "../Icons/ArrowLeftIcon";
import ProfilePhotoPreview from "./ProfilePhotoPreview/ProfilePhotoPreview";
import EditFeaturedImages from "./EditableField/CompanyInformation/FeaturedImages/EditFeaturedImages";
import EditFounders from "./EditableField/CompanyInformation/Founders/EditFounders";
import People from "./People/People";
import MessageButton from "../MessageButton/MessageButton";
import PopupContext from "../../context/PopupsProvider";

const Tabs = {
    About: 'About',
    Posts: 'Posts',
    LikedPosts: 'LikedPosts',
    Photos: 'Photos',
    People: 'People'
}

const PageSize = 20;

class ProfilePage extends Component {

    static contextType = AppContext;

    state = {
        isDeleteProfileModalOpen: false,
        isProfileDeleting: false,

        profile: {},
        isLoading: false,
        following: [],
        followers: [],
        myProfileIds: [],
        myFollowing: [],
        isFollowerPopupOpen: false,
        currentTab: Tabs.About,
        isFollowing: false,
        totalFollowers: 0,
        totalFollowing: 0,

        pageNumber: 0,
        feedPosts: [],
        isLoadingPosts: false,
        reachedEndOfPostResults: false,

        likedPostsPageNumber: 0,
        likedFeedPosts: [],
        isLoadingLikedPosts: false,
        reachedEndOfLikedPostResults: false
    }

    componentDidMount() {
        this.initProfile();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps === this.props) {
            return;
        }
        this.initProfile();
    }

    initProfile = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.setState({isLoading: true}, () => {
            const requesterId = !!this.context.currentProfile ? this.context.currentProfile.profileId : '';
            this.context.apiGatewayClient.getProfile(this.props.match.params.profileId, requesterId).then(res => {
                this.setState({
                    profile: res.data.profile,
                    isFollowing: res.data.profile.isFollowing,
                    totalFollowers: res.data.profile.totalFollowers,
                    totalFollowing: res.data.profile.totalFollowing,
                    isLoading: false,
                }, this.initFollowerInfo);
            }).catch(() => {
                this.setState({isLoading: false});
            });
        });
        this.setState({
            isLoadingPosts: true
        }, () => {
            const requesterId = !!this.context.currentProfile ? this.context.currentProfile.profileId : '';
            this.context.apiGatewayClient.getFeedPosts(Contexts.PersonalPostsContext, this.props.match.params.profileId, this.state.pageNumber, PageSize, requesterId).then(result => {
                this.setState({feedPosts: result.data.feedPosts, isLoadingPosts: false}, () => {});
                if (result.data.feedPosts.length < PageSize) {
                    this.setState({reachedEndOfPostResults: true});
                }
            });
        });
        this.setState({
            isLoadingPosts: true
        }, () => {
            const requesterId = !!this.context.currentProfile ? this.context.currentProfile.profileId : '';
            this.context.apiGatewayClient.getFeedPosts(Contexts.LikedPostsContext, this.props.match.params.profileId, this.state.likedPostsPageNumber, PageSize, requesterId).then(result => {
                this.setState({likedFeedPosts: result.data.feedPosts, isLoadingLikedPosts: false}, () => {});
                if (result.data.feedPosts.length < PageSize) {
                    this.setState({reachedEndOfLikedPostResults: true});
                }
            });
        });
    }

    openDeleteProfileModal = () => {
        this.setState({isDeleteProfileModalOpen: true});
    }

    closeDeleteProfileModal = () => {
        this.setState({isDeleteProfileModalOpen: false});
    }

    deleteProfile = () => {
        this.setState({isProfileDeleting: true}, () => {
            this.context.apiGatewayClient.deleteProfile(this.state.profile.profileId).then(() => {
                this.setState({isProfileDeleting: false});
                this.context.apiGatewayClient.getAccount().then(result => {
                    if (!!result.data.profiles && result.data.profiles.length > 0) {
                        if (result.data.profiles.filter(profile => profile.profileType === ProfileType.Personal).length > 0) {
                            this.context.setCurrentProfile(result.data.profiles.find(profile => profile.profileType === ProfileType.Personal));
                        } else {
                            this.context.setCurrentProfile(result.data.profiles[0]);
                        }
                    }
                    this.setState({isSubmitting: false}, this.props.closePopUp);
                    this.props.history.push('/');
                });
            });
        })
    }

    setCurrentTab = (tab) => {
        this.setState({currentTab: tab});
    }

    initFollowerInfo = () => {
        this.context.apiGatewayClient.getAccount().then(result => {
            this.setState({myProfileIds: result.data.profiles.map(profile => profile.profileId)});
        });
        this.context.apiGatewayClient.getFollowers(this.state.profile.profileId).then(result => {
            this.setState({followers: !!result.data.followers ? result.data.followers : []});
        });
        this.context.apiGatewayClient.getFollowing(this.state.profile.profileId).then(result => {
            this.setState({following: !!result.data.following ? result.data.following : []});
        });
        if (!!this.context.currentProfile) {
            this.context.apiGatewayClient.getFollowing(this.context.currentProfile.profileId).then(result => {
                this.setState({myFollowing: !!result.data.following ? result.data.following : []});
            });
        }
    }

    handleFollowClick = (e, popupContext) => {
        if (!this.context.currentProfile) {
            popupContext.openSignInPopUp();
            return;
        }
        if (this.context.currentProfile.profileId === this.state.profile.profileId) {
            return;
        }

        const isFollowing = this.state.myFollowing.filter(profile => profile.profileId === this.state.profile.profileId).length > 0;
        if (isFollowing) {
            this.setState({
                followers: this.state.followers.filter(follower => follower.profileId !== this.context.currentProfile.profileId),
                myFollowing: this.state.myFollowing.filter(following => following.profileId !== this.state.profile.profileId),
                totalFollowers: this.state.totalFollowers - 1,
                isFollowing: false,
            });
            this.context.apiGatewayClient.unFollow(this.state.profile.profileId, this.context.currentProfile.profileId);
        } else {
            this.setState({
                followers: [this.context.currentProfile, ...this.state.followers],
                myFollowing: [this.state.profile, ...this.state.myFollowing],
                totalFollowers: this.state.totalFollowers + 1,
                isFollowing: true,
            });
            this.context.apiGatewayClient.follow(this.state.profile.profileId, this.context.currentProfile.profileId);
        }
    }


    handleFollowersPopupFollowClick = (profile, popupContext) => {
        if (!this.context.currentProfile) {
            popupContext.openSignInPopUp();
            return;
        }

        if (profile.profileId === this.state.profileId) {
            this.handleFollowClick();
            return;
        }

        const isFollowing = this.state.myFollowing.filter(p => p.profileId === profile.profileId).length > 0;
        if (isFollowing) {
            this.setState({
                myFollowing: this.state.myFollowing.filter(following => following.profileId !== profile.profileId),
            });
            this.context.apiGatewayClient.unFollow(profile.profileId, this.context.currentProfile.profileId);
        } else {
            this.setState({
                myFollowing: [profile, ...this.state.myFollowing],
            });
            this.context.apiGatewayClient.follow(profile.profileId, this.context.currentProfile.profileId);
        }
    }

    handleScroll = (e) => {
        if (this.state.currentTab === Tabs.Posts) {
            if (this.state.isLoading || this.state.reachedEndOfPostResults) {
                return;
            }
            if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
                // you're at the bottom of the page
                this.setState({pageNumber : this.state.pageNumber + 1, isLoadingPosts: true}, () => {
                    this.context.apiGatewayClient.getFeedPosts(Contexts.PersonalPostsContext, this.props.match.params.profileId, this.state.pageNumber, PageSize).then(result => {
                        if (!!result.data && !!result.data.feedPosts && result.data.feedPosts.length > 0) {
                            this.setState({feedPosts: [...this.state.feedPosts, ...result.data.feedPosts], isLoadingPosts: false});
                        } else {
                            this.setState({isLoadingPosts: false, reachedEndOfPostResults: true});
                        }
                    });
                });
            }
        } else if (this.state.currentTab === Tabs.LikedPosts) {
            if (this.state.isLoadingLikedPosts || this.state.reachedEndOfLikedPostResults) {
                return;
            }
            if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
                // you're at the bottom of the page
                this.setState({likedPostsPageNumber : this.state.likedPostsPageNumber + 1, isLoadingLikedPosts: true}, () => {
                    this.context.apiGatewayClient.getFeedPosts(Contexts.LikedPostsContext, this.props.match.params.profileId, this.state.likedPostsPageNumber, PageSize).then(result => {
                        if (!!result.data && !!result.data.feedPosts && result.data.feedPosts.length > 0) {
                            this.setState({likedFeedPosts: [...this.state.likedFeedPosts, ...result.data.feedPosts], isLoadingLikedPosts: false});
                        } else {
                            this.setState({isLoadingLikedPosts: false, reachedEndOfLikedPostResults: true});
                        }
                    });
                });
            }
        }
    };

    openFollowersPopup = () => {
        this.setState({isFollowerPopupOpen: true});
    };

    closeFollowersPopup = () => {
        this.setState({isFollowerPopupOpen: false});
    };

    setProfile = (profile) => {
        this.setState({profile: profile});
        this.context.setCurrentProfile(profile, profile.profileType);
    }

    editFeedPost = (feedPost) => {
        const updatedFeedPosts = this.state.feedPosts.map(post => {
            if (post.postId === feedPost.postId) {
                return feedPost;
            } else {
                return post;
            }
        });
        this.setState({feedPosts: updatedFeedPosts});
    };

    deleteFeedPost = (postId) => {
        this.setState({feedPosts: this.state.feedPosts.filter(post => post.postId !== postId)});
    };

    onMessageClick = (e, popupContext) => {
        if (!this.context.currentProfile) {
            popupContext.openSignInPopUp();
            return;
        }
        if (this.context.currentProfile.profileId === this.state.profile.profileId) {
            return;
        }

        this.props.history.push(`/messages/create/${this.state.profile.profileId}`);
    }

    render() {
        const canEdit = !!this.state.myProfileIds && this.state.myProfileIds.includes(this.state.profile.profileId);
        let recommendationsMatchType = RecommendationsMatchType.Both;
        if (this.state.profile.profileType === ProfileType.Investor) {
            recommendationsMatchType = RecommendationsMatchType.Investor;
        } else if (this.state.profile.profileType === ProfileType.Startup) {
            recommendationsMatchType = RecommendationsMatchType.Startup;
        }

        return (
            <div className={sharedClasses.ContentContainer} onScroll={this.handleScroll}>
                <div className={sharedClasses.ContentContainerInner}>
                    <div className={classes.SideNav}>
                        <SideNav />
                    </div>
                    <div className={classes.Profile}>
                        <div>
                            <div className={classes.Back} onClick={() => this.props.history.goBack()}>
                                <ArrowLeftIcon /> Back
                            </div>
                        </div>
                        <div className={classes.ProfileInner}>
                            <div className={classes.Header}>
                                <div className={classes.HeaderBanner}>
                                    {!!this.state.profile.banner &&
                                    <img src={`${ProfileAssetsBucketPrefix}${this.state.profile.banner}`}/>
                                    }
                                    {canEdit &&
                                    <div className={classes.BannerEditIcon}>
                                        <EditBanner profile={this.state.profile}
                                                    setProfile={this.setProfile}/>
                                    </div>
                                    }
                                </div>
                                <div className={classes.ProfilePhoto}>
                                    <ProfilePhotoPreview logo={this.state.profile.logo} name={this.state.profile.name}/>
                                    {canEdit &&
                                    <div className={classes.ProfileEditIcon}>
                                        <EditProfilePhoto profile={this.state.profile}
                                                          setProfile={this.setProfile}/>
                                    </div>
                                    }
                                </div>
                            </div>
                            <div className={classes.HeaderContent}>
                                {!this.state.profile.name &&
                                <PlaceHolder/>
                                }
                                {!!this.state.profile.name &&
                                <Fragment>
                                    <div className={classes.TitleContainer}>
                                        <h2 className={classes.Title}>{this.state.profile.name}</h2>
                                        {(!this.context.currentProfile || this.context.currentProfile.profileId !== this.state.profile.profileId) &&
                                            <PopupContext.Consumer>
                                                {popupContext => (
                                                <Fragment>
                                                    <FollowButton onClick={e => this.handleFollowClick(e, popupContext)}
                                                          isFollowing={this.state.isFollowing}/>
                                                    <MessageButton onClick={e => this.onMessageClick(e, popupContext)}>
                                                        Message
                                                    </MessageButton>
                                                </Fragment>
                                                )}
                                            </PopupContext.Consumer>
                                        }
                                    </div>
                                    {this.state.profile.profileType === ProfileType.Investor &&
                                    <InvestorSubTitle profile={this.state.profile}/>
                                    }
                                    {this.state.profile.profileType === ProfileType.Startup &&
                                    <CompanySubTitle profile={this.state.profile}/>
                                    }
                                    {this.state.profile.profileType === ProfileType.Personal &&
                                    <PersonalSubTitle profile={this.state.profile}/>
                                    }
                                    <div>
                                        <div className={classes.SocialContainer}>
                                            {this.state.profile.linkedIn ?
                                                <SocialIcon link={this.state.profile.linkedIn}
                                                            iconType={'linkedin'}/> : null}
                                            {this.state.profile.twitter ?
                                                <SocialIcon link={this.state.profile.twitter}
                                                            iconType={'Twitter'}/> : null}
                                            {this.state.profile.facebook ?
                                                <SocialIcon link={this.state.profile.facebook}
                                                            iconType={'Facebook'}/> : null}
                                            {this.state.profile.website ?
                                                <a className={classes.Link} target="_blank"
                                                   href={this.state.profile.website}>{this.state.profile.website}</a> : null}
                                            {this.state.profile.profileType !== ProfileType.Personal &&
                                                this.state.profile.email
                                                        ? <span className={classes.Email}>{this.state.profile.email}</span>
                                                        : null
                                            }
                                        </div>
                                    </div>
                                    <div className={classes.Followers} onClick={this.openFollowersPopup}>
                                        <span>
                                            {this.state.totalFollowers} Followers
                                        </span>
                                        <span>
                                            {this.state.totalFollowing} Following
                                        </span>
                                        {this.state.isFollowerPopupOpen &&
                                        <GlobalPopup onOutsideClick={this.closeFollowersPopup}>
                                            <FollowersPopup profileId={this.state.profile.profileId}
                                                            close={this.closeFollowersPopup}
                                                            followers={this.state.followers}
                                                            following={this.state.following}
                                                            myFollowing={this.state.myFollowing}
                                                            handleFollowButtonClicked={this.handleFollowersPopupFollowClick}/>
                                        </GlobalPopup>
                                        }
                                    </div>
                                    <div className={classes.HeaderIconContainer}>
                                        {canEdit && this.state.profile.profileType !== ProfileType.Personal &&
                                        <div onClick={this.openDeleteProfileModal}
                                             className={classes.DeleteIcon}>
                                            <i className="fas fa-ellipsis-v" />
                                        </div>
                                        }
                                        {this.state.isDeleteProfileModalOpen &&
                                        <GlobalPopup onOutsideClick={this.closeDeleteProfileModal}>
                                            <div className={classes.DeleteFeedPostContainer}>
                                                <h3>
                                                    Delete this profile?
                                                </h3>
                                                <div className={classes.DeleteSubmitContainer}>
                                                    <SubmitButton className={classes.EditSubmitCancelButton}
                                                                  isDisabled={this.state.isProfileDeleting}
                                                                  onClick={this.closeDeleteProfileModal}>Cancel</SubmitButton>
                                                    <SubmitButton className={classes.EditSubmitButton}
                                                                  isSubmitting={this.state.isProfileDeleting}
                                                                  onClick={this.deleteProfile}>Delete</SubmitButton>
                                                </div>
                                            </div>
                                        </GlobalPopup>
                                        }
                                        {canEdit &&
                                        <div className={classes.HeaderEditIcon}>
                                            {this.state.profile.profileType === ProfileType.Investor &&
                                            <EditInvestorHeader profile={this.state.profile}
                                                                setProfile={this.setProfile}/>
                                            }
                                            {this.state.profile.profileType === ProfileType.Startup &&
                                            <EditCompanyHeader profile={this.state.profile}
                                                               setProfile={this.setProfile}/>
                                            }
                                            {this.state.profile.profileType === ProfileType.Personal &&
                                            <EditPersonalHeader profile={this.state.profile}
                                                                setProfile={this.setProfile}/>
                                            }
                                        </div>
                                        }
                                    </div>
                                </Fragment>
                                }
                            </div>
                            <div className={classes.Tabs}>
                                <div className={this.state.currentTab === Tabs.About ? [classes.Tab, classes.TabActive].join(' ') : classes.Tab}
                                     onClick={() => this.setCurrentTab(Tabs.About)}>
                                    Overview
                                </div>
                                <div className={this.state.currentTab === Tabs.Posts ? [classes.Tab, classes.TabActive].join(' ') : classes.Tab}
                                     onClick={() => this.setCurrentTab(Tabs.Posts)}>
                                    News
                                </div>
                                {this.state.profile.profileType === ProfileType.Startup &&
                                <div className={this.state.currentTab === Tabs.Photos ? [classes.Tab, classes.TabActive].join(' ') : classes.Tab}
                                     onClick={() => this.setCurrentTab(Tabs.Photos)}>
                                    Photos
                                </div>
                                }
                                {this.state.profile.profileType === ProfileType.Startup &&
                                <div className={this.state.currentTab === Tabs.People ? [classes.Tab, classes.TabActive].join(' ') : classes.Tab}
                                     onClick={() => this.setCurrentTab(Tabs.People)}>
                                    People
                                </div>
                                }
                                {!!this.context.currentProfile && this.context.currentProfile.profileId === this.state.profile.profileId &&
                                <div className={this.state.currentTab === Tabs.LikedPosts ? [classes.Tab, classes.TabActive].join(' ') : classes.Tab}
                                     onClick={() => this.setCurrentTab(Tabs.LikedPosts)}>
                                    Liked
                                </div>
                                }
                            </div>
                            <div className={classes.Content}>
                                {this.state.currentTab === Tabs.About &&
                                <div>
                                    {this.state.isLoading && <PlaceHolders />}
                                    {!this.state.isLoading &&
                                    <Fragment>
                                        {this.state.profile.profileType === ProfileType.Investor &&
                                        <InvestorInformation profile={this.state.profile}
                                                             canEdit={canEdit}
                                                             setProfile={this.setProfile}/>
                                        }
                                        {this.state.profile.profileType === ProfileType.Startup &&
                                        <CompanyInformation profile={this.state.profile}
                                                            canEdit={canEdit}
                                                            setProfile={this.setProfile}/>
                                        }
                                        {this.state.profile.profileType === ProfileType.Personal &&
                                        <PersonalInformation profile={this.state.profile}
                                                             canEdit={canEdit}
                                                             setProfile={this.setProfile}/>
                                        }
                                    </Fragment>
                                    }
                                </div>
                                }
                                {this.state.currentTab === Tabs.Posts &&
                                <Posts profile={this.state.profile}
                                       isLoading={this.state.isLoadingPosts}
                                       feedPosts={this.state.feedPosts}
                                       editFeedPost={this.editFeedPost}
                                       deleteFeedPost={this.deleteFeedPost}
                                       pageNumber={this.state.pageNumber}
                                       reachedEndOfResults={this.state.reachedEndOfPostResults}/>
                                }
                                {this.state.currentTab === Tabs.LikedPosts && !!this.context.currentProfile && this.context.currentProfile.profileId === this.state.profile.profileId &&
                                <Posts profile={this.state.profile}
                                       isLoading={this.state.isLoadingLikedPosts}
                                       feedPosts={this.state.likedFeedPosts}
                                       editFeedPost={this.editFeedPost}
                                       deleteFeedPost={this.deleteFeedPost}
                                       pageNumber={this.state.likedPostsPageNumber}
                                       reachedEndOfResults={this.state.reachedEndOfLikedPostResults}/>
                                }
                                {this.state.currentTab === Tabs.Photos &&
                                <div className={sharedClasses.ImageContainer}>
                                    {canEdit &&
                                    <div className={sharedClasses.EditIconContainer}>
                                        <EditFeaturedImages profile={this.state.profile}
                                                            setProfile={this.setProfile}/>
                                    </div>
                                    }
                                    {!!this.state.profile.featuredImages &&
                                    this.state.profile.featuredImages.map(image => <img src={`${ProfileAssetsBucketPrefix}${image}`}/>)}
                                </div>
                                }
                                {this.state.currentTab === Tabs.People &&
                                <div className={sharedClasses.PeopleContainer}>
                                    {canEdit &&
                                    <div className={sharedClasses.EditIconContainer}>
                                        <EditFounders profile={this.state.profile}
                                                      setProfile={this.setProfile}/>
                                    </div>
                                    }
                                    <People founders={this.state.profile.founders}/>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={sharedClasses.ClimateAds}>
                        {!!recommendationsMatchType &&
                        <Recommendations profileId={this.props.match.params.profileId}
                                         toMatchWith={recommendationsMatchType}
                                         name={this.state.profile.name}/>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const PlaceHolders = () => {
    return (
        <Fragment>
            <PlaceHolder/>
            <PlaceHolder/>
            <PlaceHolder/>
        </Fragment>
    );
}

export default withRouter(ProfilePage);
