import React, {Component, Fragment} from "react";
import classes from "./QuestionnaireBar.module.css";
import {PeopleIcon} from "../../Icons/PeopleIcon";
import {ExploreIcon} from "../../Icons/ExploreIcon";
import {LightBulbIcon} from "../../Icons/LightBulbIcon";
import {FocusAreaFilterOptions} from "../../../constants/filteroptions/FocusAreaFilterOptions";
import {TechnologyFilterOptions} from "../../../constants/filteroptions/TechnologyFilterOptions";
import {withRouter} from "react-router-dom";
import {SearchIcon} from "../../Icons/SearchIcon";


const SelectedQuestion = {
    InvestorType: 'InvestorType',
    FocusAreas: 'FocusAreas',
    Technologies: 'Technologies',
}

const ProfileTypes = {
    Investor: 'Investors',
    Startup: 'Startups'
}

class QuestionnaireBar extends Component {

    state = {
        selectedQuestion: null,
        profileType: ProfileTypes.Investor,
        focusArea: null,
        technology: null
    }

    toggleSelectedQuestion = (selectedQuestion) => {
        if (this.state.selectedQuestion === selectedQuestion) {
            this.setState({selectedQuestion: null});
        } else {
            this.setState({selectedQuestion: selectedQuestion});
        }
    }

    clearSelectedQuestion = () => {
        this.setState({selectedQuestion: null});
    }

    setType = (type) => {
        this.setState({profileType: type, selectedQuestion: SelectedQuestion.FocusAreas});
    }

    setFocusArea = (focusArea) => {
        this.setState({focusArea: focusArea, selectedQuestion: SelectedQuestion.Technologies});
    }

    setTechnology = (technology) => {
        this.setState({technology: technology, selectedQuestion: null});
    }

    executeSearch = () => {
        const params = {};
        if (!!this.state.focusArea) {
            params['focusAreas'] = this.state.focusArea.id;
        }
        if (!!this.state.technology) {
            params['technologies'] = this.state.technology.id;
        }

        this.props.history.push({
            pathname: this.getRedirectPath(),
            search: "?" + new URLSearchParams(params).toString()
        });
    }

    getRedirectPath = () => {
        switch (this.state.profileType) {
            case ProfileTypes.Investor:
                return '/explore/investors';
            case ProfileTypes.Startup:
                return '/explore/companies';
            default:
                return '/explore/all';
        }
    }

    render() {
        return(
            <div className={classes.Container}>
                <div className={classes.SearchBar}>
                    <div className={this.state.selectedQuestion === SelectedQuestion.InvestorType ? [classes.Question, classes.QuestionActive].join(' ') : classes.Question}
                         onClick={() => this.toggleSelectedQuestion(SelectedQuestion.InvestorType)}>
                        <div className={classes.QuestionBubble}>
                            <div>
                                <PeopleIcon />
                            </div>
                            <div>
                                <h3><span className={classes.SelectText}>Select</span> type</h3>
                                <SlicedOption content={this.state.profileType} />
                            </div>
                        </div>
                    </div>
                    <div className={!this.state.selectedQuestion || this.state.selectedQuestion === SelectedQuestion.Technologies ? classes.QuestionDivider : [classes.QuestionDivider, classes.QuestionDividerHidden].join(' ')}/>
                    <div className={this.state.selectedQuestion === SelectedQuestion.FocusAreas ? [classes.Question, classes.QuestionActive].join(' ') : classes.Question}
                         onClick={() => this.toggleSelectedQuestion(SelectedQuestion.FocusAreas)}>
                        <div className={classes.QuestionBubble}>
                            <div>
                                <ExploreIcon />
                            </div>
                            <div>
                                <h3><span className={classes.SelectText}>Select</span> focus areas</h3>
                                <SlicedOption content={!!this.state.focusArea ? this.state.focusArea.displayName : 'All'} />
                            </div>
                        </div>
                    </div>
                    <div className={!this.state.selectedQuestion || this.state.selectedQuestion === SelectedQuestion.InvestorType ? classes.QuestionDivider : [classes.QuestionDivider, classes.QuestionDividerHidden].join(' ')}/>
                    <div className={this.state.selectedQuestion === SelectedQuestion.Technologies ? [classes.Question, classes.QuestionActive].join(' ') : classes.Question}
                         onClick={() => this.toggleSelectedQuestion(SelectedQuestion.Technologies)}>
                        <div className={classes.QuestionBubble}>
                            <div>
                                <LightBulbIcon />
                            </div>
                            <div>
                                <h3><span className={classes.SelectText}>Select</span> technologies</h3>
                                <SlicedOption content={!!this.state.technology ? this.state.technology.displayName : 'All'} />
                            </div>
                        </div>
                    </div>
                    <div className={!this.state.selectedQuestion || this.state.selectedQuestion !== SelectedQuestion.Technologies ? classes.QuestionDivider : [classes.QuestionDivider, classes.QuestionDividerHidden].join(' ')}/>
                    <div className={classes.QuestionSearch} onClick={this.executeSearch}>
                        <span className={classes.ExploreText}>Explore</span>
                        <span className={classes.ExploreIcon}><SearchIcon /></span>
                    </div>
                </div>
                {this.state.selectedQuestion === SelectedQuestion.InvestorType &&
                <div className={[classes.QuestionOptions, classes.LeftOptions].join(' ')}>
                    <div className={[classes.QuestionOption, classes.QuestionOptionFull].join(' ')} onClick={() => this.setType(ProfileTypes.Startup)}>
                        {ProfileTypes.Startup}
                    </div>
                    <div className={[classes.QuestionOption, classes.QuestionOptionFull].join(' ')} onClick={() => this.setType(ProfileTypes.Investor)}>
                        {ProfileTypes.Investor}
                    </div>
                </div>
                }
                {this.state.selectedQuestion === SelectedQuestion.FocusAreas &&
                <div className={[classes.QuestionOptions, classes.MiddleOptions].join(' ')}>
                    <div className={classes.QuestionOption} onClick={() => this.setTechnology(null)}>
                        All
                    </div>
                    {FocusAreaFilterOptions.map(filter => (
                        <div className={classes.QuestionOption} onClick={() => this.setFocusArea(filter)}>
                            {filter.displayName}
                        </div>
                    ))}
                </div>
                }
                {this.state.selectedQuestion === SelectedQuestion.Technologies &&
                <div className={[classes.QuestionOptions, classes.RightOptions].join(' ')} close={this.clearSelectedQuestion}>
                    <div className={classes.QuestionOption} onClick={() => this.setTechnology(null)}>
                        All
                    </div>
                    {TechnologyFilterOptions.map(filter => (
                        <div className={classes.QuestionOption} onClick={() => this.setTechnology(filter)}>
                            {filter.displayName}
                        </div>
                    ))}
                </div>
                }
            </div>
        )
    }
}

const SlicedOption = (props) => {
    const sliced = !!props.content && props.content.length > 9
        ? props.content.slice(0,9) + '...'
        : props.content;

    return (
        <Fragment>
            <div className={classes.ContentNonMobile}>{props.content}</div>
            <div className={classes.ContentMobile}>{sliced}</div>
        </Fragment>
    );
}

export default withRouter(QuestionnaireBar);
