import React, {Component, Fragment} from 'react';
import FeedPost from "../../Feed/FeedPost/FeedPost";
import {withRouter} from "react-router-dom";
import AppContext from "../../../context/AppProvider";
import sharedClasses from "../../../styles/Styles.module.css";
import Recommendations from "../../Recommendations/Recommendations";
import {RecommendationsMatchType} from "../../../constants/RecommendationMatchTypeConstants";
import classes from './FeedPostPage.module.css';
import {ArrowLeftIcon} from "../../Icons/ArrowLeftIcon";
import FeedPostComment from "../FeedPostComment/FeedPostComment";
import PlaceHolder from "./PlaceHolder/PlaceHolder";
import Spinner from "../../Spinner/spinner";
import SideNav from "../../SideNav/SideNav";


const PageSize = 20;

class FeedPostPage extends Component {

    static contextType = AppContext;

    state = {
        isLoading: false,
        feedPost: null,

        pageNumber: 0,
        comments: [],
        reachedEndOfResults: false,
    }

    componentDidMount() {
        this.initPost();
    }

    initPost = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.setState({isLoading: true}, () => {
            const requesterId = !!this.context.currentProfile ? this.context.currentProfile.profileId : '';
            this.context.apiGatewayClient.getFeedPost(this.props.match.params.profileId, this.props.match.params.postId, requesterId).then(result => {
                this.setState({feedPost: result.data.feedPost});
            }).catch(() => {
                this.setState({isLoading: false});
            });
            this.setState({isLoading: true}, () => {
                this.context.apiGatewayClient.getComments(this.props.match.params.postId, this.state.pageNumber, PageSize, requesterId).then(result => {
                    this.setState({comments: result.data.comments, isLoading: false});
                });
            });
        });
    }

    handleScroll = (e) => {
        if (this.state.isLoading || this.state.reachedEndOfResults) {
            return;
        }
        if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
            // you're at the bottom of the component
            const requesterId = !!this.context.currentProfile ? this.context.currentProfile.profileId : '';
            this.setState({pageNumber : this.state.pageNumber + 1, isLoading: true}, () => {
                this.context.apiGatewayClient.getComments(this.props.match.params.postId, this.state.pageNumber, PageSize, requesterId).then(result => {
                    if (!!result.data && !!result.data.comments && result.data.comments.length > 0) {
                        this.setState({comments: [...this.state.comments, ...result.data.comments], isLoading: false});
                    } else {
                        this.setState({isLoading: false, reachedEndOfResults: true});
                    }
                });
            });
        }
    }

    editFeedPost = (feedPost) => {
        this.setState({feedPost: feedPost});
    };

    onCommentSubmit = (comment) => {
        this.setState({
            comments: [comment, ...this.state.comments],
            feedPost: {...this.state.feedPost, totalComments: this.state.feedPost.totalComments + 1}
        });
    }

    onEditComment = (updatedComment) => {
        this.setState({comments: this.state.comments.map(comment =>
            comment.commentId === updatedComment.commentId ? updatedComment : comment
        )});
    }

    onCommentDelete = (commentId) => {
        this.setState({
            comments: this.state.comments.filter(comment => comment.commentId !== commentId),
            feedPost: {...this.state.feedPost, totalComments: this.state.feedPost.totalComments - 1}
        });
    }

    render() {
        return (
            <div className={sharedClasses.ContentContainer} onScroll={this.handleScroll} ref={this.state.ref}>
                <div className={sharedClasses.ContentContainerInner}>
                    <div className={classes.SideNav}>
                        <SideNav />
                    </div>
                    <div className={classes.FeedPost}>
                        <div>
                            <div className={classes.Back} onClick={() => this.props.history.goBack()}>
                                <ArrowLeftIcon /> Back
                            </div>
                        </div>
                        <div className={classes.FeedPostPrimary}>
                            {!!this.state.feedPost &&
                            <FeedPost feedPost={this.state.feedPost}
                                      editFeedPost={this.editFeedPost}
                                      onCommentSubmit={this.onCommentSubmit}/>
                            }
                            <div className={classes.CommentsHeader}>
                                <h3>Comments</h3>
                            </div>
                            {this.state.comments.map(comment =>
                                <FeedPostComment comment={comment}
                                                 feedPost={this.state.feedPost}
                                                 onEditComment={this.onEditComment}
                                                 onCommentDelete={this.onCommentDelete}/>
                            )}
                            {this.state.isLoading &&
                            <PlaceHolders pageNumber={this.state.pageNumber}/>
                            }
                        </div>
                        {this.state.reachedEndOfResults && this.state.comments && this.state.comments.length > 0 &&
                        <div className={classes.EndOfResults}>
                            End of results.
                        </div>
                        }
                    </div>
                    <div className={sharedClasses.ClimateAds}>
                        <Recommendations profileId={this.context.profileId}
                                         toMatchWith={RecommendationsMatchType.Both}/>
                    </div>
                </div>
            </div>
        );
    }
}

const PlaceHolders = ({pageNumber}) => {
    return (
        <Fragment>
            {pageNumber === 0 &&
            <Fragment>
                <PlaceHolder/>
                <PlaceHolder/>
                <PlaceHolder/>
                <PlaceHolder/>
                <PlaceHolder/>
                <PlaceHolder/>
            </Fragment>
            }
            {pageNumber > 0 &&
            <div className={classes.SpinnerContainer}>
                <Spinner/>
            </div>
            }
        </Fragment>
    );
}

export default withRouter(FeedPostPage);
