import React from 'react';
import classes from "./Cards.module.css";


const CardOption = (props) => {
    const containerClasses = [classes.CardContainer];
    if (props.isSelected) {
        containerClasses.push(classes.CardContainerSelected);
    }

    return (
        <button className={containerClasses.join(' ')} onClick={props.onClick}>
            <img src={props.option.icon}/>
            <div>
                {props.option.displayName}
            </div>
            {props.isSelected &&
            <div className={classes.CheckIcon}>
                <i className="fas fa-check"/>
            </div>
            }
        </button>
    )
};

export default CardOption;
