export const InvestmentRangeFilterOptions = [
    {
        id: 'LEVEL_1',
        displayName: '$0 - 25k'
    },
    {
        id: 'LEVEL_2',
        displayName: '$25 - 50k'
    },
    {
        id: 'LEVEL_3',
        displayName: '$50 - 100k'
    },
    {
        id: 'LEVEL_4',
        displayName: '$100 - 500k'
    },
    {
        id: 'LEVEL_5',
        displayName: '$500k+'
    }
];
