import React, {Component} from 'react';
import classes from '../SignInPopUp.module.css';
import sharedClasses from '../../../../styles/FormStyles.module.css';
import TextInput from "../../../Forms/Input/TextInput";
import SubmitButton from "../../../Forms/SubmitButton/SubmitButton";
import AppContext from '../../../../context/AppProvider';
import {isValidPassword} from "../../Auth.util";
import {SUBMISSION_ERROR_CODE} from "../../Auth.const";
import PopupContext from "../../../../context/PopupsProvider";


const SubmissionError = {
    InvalidConfirmationCode: 'Too many attempts. Please try again later.',
    LimitExceededException: 'Incorrect confirmation code.',
    UnexpectedException: 'An unexpected exception occurred. Please try again'
}


class ResetPassword extends Component {

    static contextType = AppContext;

    state = {
        confirmationCode: '',
        password: '',
        confirmPassword: '',
        isSubmitting: false,
        validationErrors: [],
        submissionError: null
    }

    handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            this.onSubmit();
        }
    };

    isValidConfirmPassword = (confirmPassword, password) => {
        return confirmPassword === password;
    }

    onCodeInputChanged = (event) => {
        this.setState({confirmationCode: event.target.value});
    }

    onPasswordInputChange = (event) => {
        if (isValidPassword(event.target.value)) {
            const errors = this.state.errors.filter(err => err !== 'password');
            this.setState({password: event.target.value, errors: errors});
        } else {
            this.setState({password: event.target.value});
        }
    };

    onConfirmPasswordInputChanged = (event) => {
        if (this.isValidConfirmPassword(event.target.value, this.state.password)) {
            const errors = this.state.errors.filter(err => err !== 'confirmPassword');
            this.setState({confirmPassword: event.target.value, errors: errors});
        } else {
            this.setState({confirmPassword: event.target.value});
        }
    }

    hasError = (error) => {
        return this.state.errors.filter(err => err === error).length > 0;
    };
    
    onSubmit = () => {
        const confirmationCode = this.state.confirmationCode;
        const confirmPassword = this.state.confirmPassword;
        const password = this.state.password;
        const errors = [];
        if (!isValidPassword(password)) {
            errors.push("password");
        }

        if (!this.isValidConfirmPassword(confirmPassword, password)) {
            errors.push('confirmPassword')
        }

        if (errors.length > 0) {
            this.setState({errors: errors});
        }
        else {
            this.setState({isSubmitting: true})
            this.context.apiGatewayClient.confirmPasswordReset(this.state.email, confirmationCode, password).then(() => {
                this.setState({isSubmitting: false}, this.props.openSignIn)
            }).catch(err => {
                if (err.response && err.response.data.errorCode === SUBMISSION_ERROR_CODE.INVALID_CONFIRMATION_CODE) {
                    this.setState({isSubmitting: false, submissionError: SubmissionError.InvalidConfirmationCode});
                }
                else if (err.response && err.response.data.errorCode === SUBMISSION_ERROR_CODE.LIMIT_EXCEEDED_EXCEPTION) {
                    this.setState({isSubmitting: false, submissionError: SubmissionError.LimitExceededException});
                } else {
                    this.setState({isSubmitting: false, submissionError: SubmissionError.UnexpectedException});
                }
            });
        }
    }

    render() {
        return (
            <PopupContext.Consumer>
                {popupContext => (
                    <div className={classes.ResetPasswordPopUp}>
                        <div className={sharedClasses.PopupInner}>
                            <div className={sharedClasses.Header}>
                                <button className={sharedClasses.Exit} onClick={this.props.closeResetPassword}>
                                    <i className="fas fa-arrow-left"/>
                                </button>
                                <h2>
                                    Reset password
                                </h2>
                            </div>
                            <div className={sharedClasses.FormContainer}>
                                <div className={sharedClasses.FormInner}>
                                    <div className={sharedClasses.FormContainer}>
                                        <div className={sharedClasses.FormInner}>
                                            <div>Please enter the code that was sent to your email and provide a new password.</div>
                                            <TextInput
                                                label="Confirmation code"
                                                icon="fas fa-user-secret"
                                                type="text"
                                                value={this.state.confirmationCode}
                                                onChange={this.onCodeInputChanged}
                                                onKeyDown={this.handleKeyDown}
                                                isDisabled={this.state.isSubmitting}/>
                                            <TextInput
                                                error={this.hasError('password') ? 'New password must contain at least 8 characters' : null}
                                                label="New Password"
                                                icon="fas fa-lock"
                                                type="password"
                                                value={this.state.password}
                                                onChange={this.onPasswordInputChange}
                                                onKeyDown={this.handleKeyDown}
                                                isDisabled={this.state.isSubmitting}/>
                                            <TextInput error={this.hasError('confirmPassword') ? 'Passwords must match' : null}
                                                       label="Confirm Password"
                                                       icon="fas fa-lock"
                                                       type="password"
                                                       value={this.state.confirmPassword}
                                                       onChange={this.onConfirmPasswordInputChanged}
                                                       onKeyDown={this.handleKeyDown}
                                                       isDisabled={this.state.isSubmitting}/>
                                            <div className={sharedClasses.SubmitButtonWrapper}>
                                                <SubmitButton isSubmitting={this.state.isSubmitting}
                                                              onClick={this.onSubmit}
                                                              isFullWidth={true}
                                                              disabled={this.state.isSubmitting}>
                                                    {this.state.isSubmitting ? 'Resetting password...' : 'Reset Password'}
                                                </SubmitButton>
                                            </div>
                                        </div>
                                    </div>
                                    {!!this.state.submissionError &&
                                    <div className={sharedClasses.Error}>
                                        <i className="fas fa-exclamation-circle"/> {this.state.submissionError}
                                    </div>
                                    }
                                    <div className={sharedClasses.SwitchPopup}>
                                        <div className={sharedClasses.SwitchPopupCenter}>
                                            Don't have an account? <span className={sharedClasses.LabelUnderline} onClick={popupContext.openSignUp}>Sign up</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </PopupContext.Consumer>
        )
    }
}

export default ResetPassword;
