import React, {Component} from 'react';
import classes from './TextInput.module.css';

class TextInput extends Component {

    state = {
        isFocused: false,
        ref: React.createRef()
    };

    onFocusIn = () => {
        this.setState({isFocused: true});
    };

    onFocusOut = () => {
        this.setState({isFocused: false});
    };

    render() {
        const labelClass = this.state.isFocused || this.props.value
            ? classes.LabelFocused
            : classes.LabelUnfocused;

        const containerClasses = [classes.Container];
        if (this.state.isFocused && !this.props.isDisabled && !this.props.error) {
            containerClasses.push(classes.ContainerFocused);
        }
        if (!!this.props.error) {
            containerClasses.push(classes.ContainerError);
        }

        return (
            <div className={classes.ContainerOuter}>
                <div className={containerClasses.join(' ')}>
                    <div className={labelClass} onClick={() => this.state.ref.current.focus()}>{this.props.label}</div>
                    <input ref={this.state.ref}
                           type={this.props.type}
                           value={this.props.value}
                           onChange={this.props.onChange}
                           onFocus={this.onFocusIn}
                           onBlur={this.onFocusOut}
                           onKeyDown={this.props.onKeyDown}
                           disabled={this.props.isDisabled}/>
                    <div className={classes.Icon}>
                        <i className={this.props.icon}/>
                    </div>
                </div>
                {!!this.props.error &&
                <div className={classes.Error}>
                    <i className="fas fa-exclamation-circle"/> {this.props.error}
                </div>
                }
            </div>
        );
    }
}

export default TextInput;
