import React, {Component, Fragment} from 'react';
import GlobalPopup from "../../Popup/GlobalPopup/GlobalPopup";
import AppContext from '../../../context/AppProvider';
import ResetPassword from './ResetPassword/ResetPassword';
import {withRouter} from 'react-router-dom';
import SignIn from "./SignIn/SignIn";
import ThirdPartySignIn from "./ThirdPartySignIn/ThirdPartySignIn";
import ConfirmResetPassword from "./ConfirmResetPassword/ConfirmResetPassword";
import PopupContext from "../../../context/PopupsProvider";


const Context = {
    ResetPassword: 'ResetPassword',
    ConfirmResetPassword: 'ConfirmPassword',
    SignIn: 'SignIn',
    ThirdPartySignIn: 'ThirdPartySignIn'
}

class SignInPopUp extends Component {

    static contextType = AppContext;

    state = {
        context: Context.SignIn,

        accountIdentityProvider: '',
        email: ''
    };

    openResetPassword = () => {
        this.setState({context: Context.ResetPassword});
    };

    openConfirmResetPassword = () => {
        this.setState({context: Context.ConfirmResetPassword});
    }

    openSignIn = () => {
        this.setState({context: Context.SignIn});
    };

    openSignThirdPartySignIn = (accountIdentityProvider, email) => {
        this.setState({context: Context.ThirdPartySignIn, accountIdentityProvider: accountIdentityProvider, email: email});
    };

    render() {
        return (
            <PopupContext.Consumer>
                {popupContext => (
                    <GlobalPopup onOutsideClick={popupContext.closeSignInPopUp}>
                        {this.state.context === Context.ResetPassword &&
                        <ResetPassword openSignIn={this.openSignIn}
                                       openConfirmResetPassword={this.openConfirmResetPassword}/>
                        }
                        {this.state.context === Context.SignIn &&
                        <SignIn openSignUp={this.props.openSignUp}
                                openResetPassword={this.openResetPassword}/>
                        }
                        {this.state.context === Context.ConfirmResetPassword &&
                        <ConfirmResetPassword openSignIn={this.openSignIn}/>
                        }
                        {/*{this.state.context === Context.ThirdPartySignIn &&*/}
                        {/*<ThirdPartySignIn openSignIn={this.openSignIn}*/}
                        {/*                  accountIdentityProvider={this.state.accountIdentityProvider}*/}
                        {/*                  email={this.state.email}/>*/}
                        {/*}*/}
                    </GlobalPopup>
                )}
            </PopupContext.Consumer>
        );
    }
}

export default withRouter(SignInPopUp);
