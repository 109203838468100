import React, {Component} from "react";
import classes from "./SearchBar.module.css";
import ProfilePhotoWithDefault from "../../ProfilePage/ProfilePhotoWithDefault/ProfilePhotoWithDefault";
import {withRouter} from "react-router-dom";
import {SearchContext} from "../../../constants/SearchContexts";
import {SearchIcon} from "../../Icons/SearchIcon";
import {CloseIcon} from "../../Icons/CloseIcon";

class SearchBar extends Component {

    state = {
        isFocused: false,
        ref: React.createRef()
    };

    onFocusIn = () => {
        this.setState({isFocused: true});
    };

    onFocusOut = () => {
        this.setState({isFocused: false});
    };

    handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            this.props.onSubmit();
            this.state.ref.current.blur();
        }
    };

    onClear = (e) => {
        e.stopPropagation();
        this.props.onChange('');
        this.onFocusIn();
        this.state.ref.current.focus();
    }

    navigateToProfile = (profileId) => {
        this.props.history.push(`/profile/${profileId}`);
    }

    render() {
        const suggestions = [];
        Object.keys(this.props.suggestions).map(key => {
            if (this.props.suggestions[key].length > 0) {
                suggestions.push(
                    <div>
                        <div className={classes.SuggestionHeader}>
                            {key}
                        </div>
                        {this.props.suggestions[key].map(suggestion => (
                            <div className={classes.Suggestion} onMouseDown={() => this.navigateToProfile(suggestion.profileId)}>
                                <div className={classes.SuggestionLogo}>
                                    <ProfilePhotoWithDefault logo={suggestion.logo} name={suggestion.name}/>
                                </div>
                                <div>
                                    <div>
                                        {highlightSubText(suggestion.name, this.props.value.trim())}
                                    </div>
                                    <div className={classes.SuggestionDescription}>
                                        {!!suggestion.description && suggestion.description.length > 60 ? `${suggestion.description.slice(0, 60)}...` : suggestion.description}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )
            }
        });

        return (
            <div className={classes.SearchInputContainer}>
                <div className={this.state.isFocused ? [classes.Container, classes.ContainerFocused].join(' ') : classes.Container}>
                    <div className={classes.SearchIcon}>
                        <SearchIcon />
                    </div>
                    <input placeholder="Search"
                           ref={this.state.ref}
                           onChange={(e) => this.props.onChange(e.target.value)}
                           onKeyDown={this.handleKeyDown}
                           onFocus={this.onFocusIn}
                           onBlur={this.onFocusOut}
                           value={this.props.value}/>
                    {!!this.props.value && this.props.value.length > 0 &&
                    <div className={classes.ClearInputIcon} onClick={this.onClear}>
                        <CloseIcon/>
                    </div>
                    }
                </div>

                {this.state.isFocused &&
                <div className={classes.Suggestions}>
                    <div className={classes.BrowseOption} onMouseDown={() => this.props.setSearchContext(SearchContext.Investors)}>
                        {!!this.props.value && this.props.value.length > 0
                            ? <div><SearchIcon /> Search <b>{this.props.value}</b> in investors</div>
                            : <div><SearchIcon /> Browse all investors</div>}
                    </div>
                    <div className={classes.BrowseOption} onMouseDown={() => this.props.setSearchContext(SearchContext.Startups)}>
                        {!!this.props.value && this.props.value.length > 0
                            ? <div><SearchIcon /> Search <b>{this.props.value}</b> in startups</div>
                            : <div><SearchIcon /> Browse all startups</div>}
                    </div>
                    <div className={classes.BrowseOption} onMouseDown={() => this.props.setSearchContext(SearchContext.Personal)}>
                        {!!this.props.value && this.props.value.length > 0
                            ? <div><SearchIcon /> Search <b>{this.props.value}</b> in people</div>
                            : <div><SearchIcon /> Browse all people</div>}
                    </div>
                    {!!this.props.value && this.props.value.length &&
                    <div className={classes.BrowseOption}
                         onMouseDown={() => this.props.history.push(`/feed/hashtag/${this.props.value}`)}>
                        <div><SearchIcon/> Search posts for <b>#{this.props.value}</b></div>
                    </div>
                    }
                    {suggestions}
                </div>
                }
            </div>
        );
    }
}

const highlightSubText = (text, subText) => {
    const idx = text.toLowerCase().indexOf(subText);
    if (idx < 0) {
        return text;
    } else {
        return <span>
            {text.slice(0, idx)}<b>{text.slice(idx, idx + subText.length)}</b>{text.slice(idx + subText.length, text.length)}
        </span>
    }
}

export default withRouter(SearchBar);
