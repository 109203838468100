import React, {Component} from 'react';

import classes from './FeaturedEntity.module.css';
import ProfilePhotoWithDefault
    from "../../../../containers/ProfilePage/ProfilePhotoWithDefault/ProfilePhotoWithDefault";
import {withRouter} from "react-router-dom";

class FeaturedEntity extends Component {
    render() {
        return (
            <a href={this.props.entity.link} className={classes.FeaturedEntityContainer}>
                <div className={classes.Preview}>
                    <img src={this.props.entity.image} />
                </div>
                <div className={classes.Details}>
                    <div className={classes.Logo}>
                        <ProfilePhotoWithDefault logo={this.props.entity.logo}/>
                    </div>
                    <div className={classes.Right}>
                        <div className={classes.Title}>
                            {this.props.entity.name}
                        </div>
                        <div className={classes.Description}>
                            {this.props.entity.location}
                        </div>
                    </div>
                </div>
            </a>
        );
    }
}


export default withRouter(FeaturedEntity);
