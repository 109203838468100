import classes from "./NavigationButton.module.css";
import React from "react";

const NavigationButton = (props) => {
    return (
        <div className={props.primary ? [classes.NavigationButton, classes.NavigationButtonPrimary].join(' ') : classes.NavigationButton} onClick={props.onClick}>
            {props.text}
        </div>
    );
};

export default NavigationButton;
