import React, {Component, createRef} from "react";
import classes from "./EditInvestorDetails.module.css";
import SubmitButton from "../../../../Forms/SubmitButton/SubmitButton";
import GlobalPopup from "../../../../Popup/GlobalPopup/GlobalPopup";
import AppContext from "../../../../../context/AppProvider";
import _ from "lodash";
import InputLabel from "../../../../Forms/Label/InputLabel";
import stepClasses from "../../../Styles/Steps.module.css";
import sharedClasses from "../../styles/EditableFieldStyles.module.css";
import MultiSelectCards from "../../../../Forms/Cards/MultiSelectCards";
import {FocusAreaFilterOptions} from "../../../../../constants/filteroptions/FocusAreaFilterOptions";
import {TechnologyFilterOptions} from "../../../../../constants/filteroptions/TechnologyFilterOptions";
import {FundingStageFilterOptions} from "../../../../../constants/filteroptions/FundingStageFilterOptions";
import {BusinessStageFilterOptions} from "../../../../../constants/filteroptions/BusinessStageFilterOptions";
import {InvestmentRangeFilterOptions} from "../../../../../constants/filteroptions/InvestmentRangeFilterOptions";
import {CloseIcon} from "../../../../Icons/CloseIcon";

class EditInvestorDetails extends Component {

    static contextType = AppContext;

    state = {
        isEditing: false,
        isSubmitting: false,
        wrapperRef: createRef(),
        focusAreas: !!this.props.profile.focusAreas ? this.props.profile.focusAreas : [],
        fundingStages: !!this.props.profile.fundingStages ? this.props.profile.fundingStages : [],
        businessStages: !!this.props.profile.businessStages ? this.props.profile.businessStages : [],
        investmentRanges: !!this.props.profile.investmentRanges ? this.props.profile.investmentRanges : [],
        technologies: !!this.props.profile.technologies ? this.props.profile.technologies : [],
        pastInvestments: !!this.props.profile.pastInvestments ? this.props.profile.pastInvestments: [],
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick);
        this.setState({
            businessStage: this.props.profile.businessStage,
        });
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick);
    }

    handleClick = (event) => {
        if (!!this.state.wrapperRef && !!this.state.wrapperRef.current && !this.state.wrapperRef.current.contains(event.target)) {
            this.closeEditModal();
        }
    }

    openEditModal = () => {
        this.setState({isEditing: true});
    }

    closeEditModal = () => {
        this.setState({isEditing: false, isSubmitting: false, newValue: null});
    }

    selectFocusAreas = (focusAreas) => {
        this.setState({focusAreas: focusAreas});
    };

    selectFundingStages = (fundingStages) => {
        this.setState({fundingStages: fundingStages});
    };

    selectBusinessStages = (businessStages) => {
        this.setState({businessStages: businessStages});
    };

    selectInvestmentRanges = (investmentRanges) => {
        this.setState({investmentRanges: investmentRanges});
    };

    selectTechnologies = (technologies) => {
        this.setState({technologies: technologies});
    };

    selectPastInvestments = (pastInvestments) => {
        this.setState({pastInvestments: pastInvestments});
    };

    handleSave = async () => {
        this.setState({isSubmitting: true}, async () => {
            const updatedProfile = _.cloneDeep(this.props.profile);
            updatedProfile.focusAreas = this.state.focusAreas;
            updatedProfile.fundingStages = this.state.fundingStages;
            updatedProfile.businessStages = this.state.businessStages;
            updatedProfile.investmentRanges = this.state.investmentRanges;
            updatedProfile.technologies = this.state.technologies;
            updatedProfile.pastInvestments = this.state.pastInvestments;
            await this.context.apiGatewayClient.updateProfile(updatedProfile);
            this.setState({
                isSubmitting: false,
                isEditing: false,
            });
            this.props.setProfile(updatedProfile);
        })
    }

    render() {
        return (
            <div>
                <div className={classes.EditIcon} onClick={this.openEditModal}>
                    Edit details
                </div>
                {this.state.isEditing &&
                <GlobalPopup onOutsideClick={this.closeEditModal}>
                    <div className={sharedClasses.PopupContainer} ref={this.state.wrapperRef}>
                        <div className={classes.Title}>
                            <h3>Edit focus areas</h3>
                        </div>
                        <div className={sharedClasses.ProfileEditPopup}>
                            <InputLabel required={true} className={stepClasses.InputLabel}>Select your focus areas</InputLabel>
                            <MultiSelectCards options={FocusAreaFilterOptions}
                                              setSelected={(selectedOptions) => this.selectFocusAreas(selectedOptions.map(option => option.id))}
                                              currentlySelected={FocusAreaFilterOptions.filter(option => this.state.focusAreas.find(focusArea => focusArea === option.id))}/>
                            <InputLabel required={true} className={stepClasses.InputLabel}>Funding Stages</InputLabel>
                            <MultiSelectCards options={FundingStageFilterOptions}
                                              setSelected={(selectedOptions) => this.selectFundingStages(selectedOptions.map(option => option.id))}
                                              currentlySelected={FundingStageFilterOptions.filter(option => this.state.fundingStages.find(fundingStage => fundingStage === option.id))}/>
                            <InputLabel required={true} className={stepClasses.InputLabel}>Business Stage</InputLabel>
                            <MultiSelectCards options={BusinessStageFilterOptions}
                                              setSelected={(selectedOptions) => this.selectBusinessStages(selectedOptions.map(option => option.id))}
                                              currentlySelected={BusinessStageFilterOptions.filter(option => this.state.businessStages.find(businessStage => businessStage === option.id))}/>
                            <InputLabel required={true} className={stepClasses.InputLabel}>Investment Range</InputLabel>
                            <MultiSelectCards options={InvestmentRangeFilterOptions}
                                              setSelected={(selectedOptions) => this.selectInvestmentRanges(selectedOptions.map(option => option.id))}
                                              currentlySelected={InvestmentRangeFilterOptions.filter(option => this.state.investmentRanges.find(investmentRange => investmentRange === option.id))}/>
                            <InputLabel required={true} className={stepClasses.InputLabel}>Select technologies</InputLabel>
                            <MultiSelectCards options={TechnologyFilterOptions}
                                              setSelected={(selectedOptions) => this.selectTechnologies(selectedOptions.map(option => option.id))}
                                              currentlySelected={TechnologyFilterOptions.filter(option => this.state.technologies.find(technology => technology === option.id))}/>
                            {/*<InputLabel required={false} className={stepClasses.InputLabel}>Select from a company*/}
                            {/*    already on ClimateHub or add your own</InputLabel>*/}
                            {/*<SearchBar onSubmit={this.onSubmit}*/}
                            {/*           onChange={this.onInputChange}*/}
                            {/*           suggestions={this.state.suggestions}*/}
                            {/*           onSuggestionSelect={this.onSuggestionSelect}*/}
                            {/*           value={this.state.input}/>*/}
                            {/*<div className={classes.PastInvestments}>*/}
                            {/*    <PastInvestments pastInvestments={this.state.pastInvestments}*/}
                            {/*                     removePastInvestment={this.removePastInvestment}/>*/}
                            {/*</div>*/}

                        </div>
                        <div className={sharedClasses.SubmitContainer}>
                            <SubmitButton className={classes.CancelOption} onClick={this.closeEditModal}>
                                Cancel
                            </SubmitButton>
                            <SubmitButton className={classes.SaveOption}
                                          onClick={this.handleSave}
                                          isSubmitting={this.state.isSubmitting}>
                                Save
                            </SubmitButton>
                        </div>
                    </div>
                </GlobalPopup>
                }
            </div>
        );
    }
}

const PastInvestments = (props) => {
    return !!props.pastInvestments ? props.pastInvestments.map(pastInvestment => {
        return (
            <div className={classes.InvestmentRange}>
                {pastInvestment}
                <div className={classes.RemoveInvestmentRange} onClick={() => props.removePastInvestment(pastInvestment)}>
                    <CloseIcon />
                </div>
            </div>
        );
    }) : null;
};

export default EditInvestorDetails;
