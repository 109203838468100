import React, {Component, createRef} from "react";
import classes from "./EditProfilePhoto.module.css";
import Image from "../../../../model/Image";
import SubmitButton from "../../../Forms/SubmitButton/SubmitButton";
import GlobalPopup from "../../../Popup/GlobalPopup/GlobalPopup";
import AppContext from "../../../../context/AppProvider";
import _ from "lodash";
import ProfilePhotoWithDefault from "../../ProfilePhotoWithDefault/ProfilePhotoWithDefault";
import sharedClasses from "../styles/EditableFieldStyles.module.css";

class EditProfilePhoto extends Component {

    static contextType = AppContext;

    state = {
        isEditing: false,
        isSubmitting: false,
        wrapperRef: createRef(),
        inputRef: createRef(),
        newValue: null
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick);
    }

    handleClick = (event) => {
        if (!!this.state.wrapperRef && !!this.state.wrapperRef.current && !this.state.wrapperRef.current.contains(event.target)) {
            this.closeEditModal();
        }
    }

    openEditModal = () => {
        this.setState({isEditing: true});
    }

    closeEditModal = () => {
        this.setState({isEditing: false, isSubmitting: false, newValue: null});
    }

    handleInputClick = () => {
        this.state.inputRef.current.click();
    };

    handleUploadedImage = (e) => {
        const [file] = e.target.files;
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                this.setState({newValue: new Image(file, event.target.result)});
            };
            reader.readAsDataURL(file);
        }
    };

    handleSave = async () => {
        if (!!this.state.newValue) {
            this.setState({isSubmitting: true}, async () => {
                const updatedProfile = _.cloneDeep(this.props.profile);
                const re = /(?:\.([^.]+))?$/;
                const ext = re.exec(this.state.newValue.file.name)[1]
                const imageUploadURLResponse = await this.context.apiGatewayClient.getImageUploadUrl(ext);
                updatedProfile.logo = imageUploadURLResponse.data.fileName;
                await this.context.s3Client.uploadImage(this.state.newValue.file, imageUploadURLResponse.data.uploadUrl);
                await this.context.apiGatewayClient.updateProfile(updatedProfile);
                this.setState({isSubmitting: false, isEditing: false, newValue: null});
                this.props.setProfile(updatedProfile);
            })
        }
    }

    render() {
        let imgSrc = null;
        let isUploadedLocally = false;
        if (!!this.state.newValue) {
            imgSrc = this.state.newValue.src;
            isUploadedLocally = true;
        } else if (!!this.props.profile.logo) {
            imgSrc = this.props.profile.logo;
        }

        return (
            <div>
                <div className={classes.EditIcon} onClick={this.openEditModal}>
                    <i className="fas fa-camera" />
                </div>
                {this.state.isEditing &&
                <GlobalPopup onOutsideClick={this.closeEditModal}>
                    <div className={sharedClasses.PopupContainer} ref={this.state.wrapperRef}>
                        <div className={classes.Title}>
                            <h3>Profile photo</h3>
                        </div>
                        <div className={sharedClasses.ProfileEditPopup}>
                            <div className={classes.ImageContainer}>
                                <div className={classes.ImageContainerInner}>
                                    <ProfilePhotoWithDefault logo={imgSrc} name={this.props.profile.name}
                                                             isUploadedLocally={isUploadedLocally}/>
                                </div>
                            </div>
                            <input className={sharedClasses.InvisibleInput} ref={this.state.inputRef} type="file"
                                   accept={['.png', '.jpg']} multiple={false} onChange={this.handleUploadedImage}/>
                            <div className={classes.ChangeButtonContainer}>
                                <SubmitButton onClick={this.handleInputClick} className={classes.ChangeButton}>
                                    <i className="fas fa-camera"/> Change
                                </SubmitButton>
                            </div>
                        </div>
                        <div className={sharedClasses.SubmitContainer}>
                            <SubmitButton className={classes.CancelOption} onClick={this.closeEditModal}>
                                Cancel
                            </SubmitButton>
                            <SubmitButton className={classes.SaveOption}
                                          isDisabled={!this.state.newValue}
                                          onClick={this.handleSave}
                                          isSubmitting={this.state.isSubmitting}>
                                Save
                            </SubmitButton>
                        </div>
                    </div>
                </GlobalPopup>
                }
            </div>
        );
    }
}

export default EditProfilePhoto;
