import React, {Component} from "react";
import classes from './ConversationsPopup.module.css';
import Conversations from "./Conversations/Conversations";
import {AngleUpIcon} from "../Icons/AngleUpIcon";
import {AngleDownIcon} from "../Icons/AngleDownIcon";

class ConversationsPopup extends Component {

    state = {
        isOpen: false,
    }

    toggle = () => {
        this.setState({isOpen: !this.state.isOpen});
    }

    onCreateButtonClick = () => {
        this.setState({isOpen: true});
    }

    render() {
        return (
            <div className={classes.Container}>
                <div className={this.state.isOpen ? classes.Open : classes.Closed}>
                    <Conversations onHeaderClick={this.toggle}
                                   onCreateButtonClick={this.onCreateButtonClick}
                                   toggleIcon={this.state.isOpen ? <AngleDownIcon /> : <AngleUpIcon />}
                                   toggle={this.toggle}/>
                </div>
            </div>
        );
    }
}

export default ConversationsPopup;
