import React, {Component} from 'react';
import classes from '../SignInPopUp.module.css';
import sharedClasses from '../../../../styles/FormStyles.module.css';
import TextInput from "../../../Forms/Input/TextInput";
import SubmitButton from "../../../Forms/SubmitButton/SubmitButton";
import AppContext from '../../../../context/AppProvider';
import {isValidEmail} from "../../Auth.util";
import {SUBMISSION_ERROR_CODE} from "../../Auth.const";
import PopupContext from "../../../../context/PopupsProvider";

const SubmissionError = {
    UserNotFound: 'Sorry, we could not find an account with this email.',
    LimitExceededException: 'Incorrect confirmation code.',
    UnexpectedException: 'An unexpected exception occurred. Please try again',
    NotAuthorized: 'The password for this account cannot be reset.',
}

class ResetPassword extends Component {

    static contextType = AppContext; 

    state = {
        email: '',
        isSubmitting: false,
        validationErrors: [],
        submissionErrorMessage: null
    }

    handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            this.onSubmit();
        }
    };

    onEmailInputChange = (event) => {
        if (isValidEmail(event.target.value)) {
            const errors = this.state.validationErrors.filter(err => err !== 'email');
            this.setState({email: event.target.value, validationErrors: errors});
        } else {
            this.setState({email: event.target.value});
        }
    };

    hasError = (error) => {
        return this.state.validationErrors.filter(err => err === error).length > 0;
    };

    onSubmit = () => {
        const email = this.state.email;
        const errors = [];
        if (!isValidEmail(email)) {
            errors.push('email');
        }
        if (errors.length > 0) {
            this.setState({validationErrors: errors});
        } 
        else {
            this.setState({isSubmitting: true});
            this.context.apiGatewayClient.forgotPassword(this.state.email)
                .then(this.props.openConfirmResetPassword)
                .catch((err) => {
                    console.log(err.response);
                    if (err.response && err.response.data.errors.code === "UserNotFoundException") {
                        this.setState({isSubmitting: false, submissionErrorMessage: "Sorry, we could not find an account with this email."})
                    }
                    else if (err.response && err.response.data.errors.code === "LimitExceededException") {
                        this.setState({isSubmitting: false, submissionErrorMessage: "Attempt limit exceeded. Please try again later."})
                    }
                    else {
                        this.setState({isSubmitting: false, submissionErrorMessage: "Looks like there was an issue with your submission."})
                    }
                })
        }
    }

    render() {
        return (
            <PopupContext.Consumer>
                {popupContext => (
                    <div className={classes.SignInPopUp}>
                        <div className={sharedClasses.PopupInner}>
                            <div className={sharedClasses.Header}>
                                <button className={sharedClasses.Exit} onClick={this.props.openSignIn}>
                                    <i className="fas fa-arrow-left"/>
                                </button>
                                <h2>
                                    Reset password
                                </h2>
                            </div>
                            <div className={sharedClasses.FormContainer}>
                                <div className={sharedClasses.FormInner}>
                                    <div className={sharedClasses.DescriptiveLabel}>
                                        Please enter your email so that we can verify your identity.
                                    </div>
                                    <TextInput error={this.hasError("email") ? 'You must specify a valid email' : null}
                                               label="Email"
                                               icon="fas fa-envelope"
                                               type="text"
                                               value={this.state.email}
                                               onChange={this.onEmailInputChange}
                                               onKeyDown={this.handleKeyDown}
                                               isDisabled={this.state.isSubmitting}/>
                                    <SubmitButton isSubmitting={this.state.isSubmitting}
                                                  onClick={this.onSubmit}
                                                  isFullWidth={true}
                                                  handleKeyDown
                                                  disabled={this.state.isSubmitting}>
                                        {this.state.isSubmitting ? 'Sending...' : 'Send email'}
                                    </SubmitButton>
                                    {!!this.state.submissionErrorMessage &&
                                    <div className={sharedClasses.Error}>
                                        <i className="fas fa-exclamation-circle"/> {this.state.submissionErrorMessage}
                                    </div>
                                    }
                                    <div className={sharedClasses.SwitchPopup}>
                                        <div className={sharedClasses.SwitchPopupCenter}>
                                            Don't have an account? <span className={sharedClasses.LabelUnderline} onClick={popupContext.openSignUpPopUp}>Sign up</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </PopupContext.Consumer>
        )
    }
}

export default ResetPassword;
