import React, {Component} from 'react';
import classes from './DatePicker.module.css';
import DatePickerReact from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const convertEpochToDate = (epoch) => {
    if (!epoch) {
        return null;
    }
    const d = new Date(0);
    d.setUTCSeconds(epoch / 1000);
    return d;
};

const convertDateToEpoch = (date) => {
    return date.getTime();
};

class DatePicker extends Component {
    state = {
        isFocused: false,
        ref: React.createRef(),
    };

    onFocusIn = () => {
        this.setState({isFocused: true});
    };

    onBlur = () => {
        this.setState({isFocused: false});
    };

    render() {
        const containerClasses = [classes.Container];
        if (this.state.isFocused && !this.props.isDisabled) {
            containerClasses.push(classes.ContainerFocused);
        }
        return (
            <div className={containerClasses.join(' ')} onClick={this.onFocusIn}>
                <DatePickerReact
                    selected={convertEpochToDate(this.props.value)} 
                    ref={this.state.ref}
                    onChange={date => this.props.onChange(convertDateToEpoch(date))}
                    onFocus={this.onFocusIn}
                    onBlur={this.onBlur}
                    maxDate={new Date()}
                    showYearDropdown={true}/>
            </div>
        );
    }
}

export default DatePicker;