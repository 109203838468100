import EnergyIcon from "../../assets/FocusAreas/tower.svg";
import MobilityAndTransportation from "../../assets/FocusAreas/worldwide.svg";
import FoodAgricultureAndLandUse from "../../assets/FocusAreas/agriculture.svg";
import HeavyIndustryIcon from "../../assets/FocusAreas/power-plant.svg";
import BuiltEnvironmentIcon from "../../assets/FocusAreas/hook.svg";
import GHGCaptureAndStorageIcon from "../../assets/FocusAreas/carbon-dioxide-2.svg";
import ClimateAndEarthDataGeneration from "../../assets/FocusAreas/dashboard-4.svg";


export const FocusAreaFilterOptions = [
    {
        id: 'ENERGY',
        displayName: 'Energy',
        icon: EnergyIcon
    },
    {
        id: 'MOBILITY_AND_TRANSPORT',
        displayName: 'Mobility & Transport',
        icon: MobilityAndTransportation
    },
    {
        id: 'FOOD_AGRICULTURE_AND_LAND_USE',
        displayName: 'Food Agriculture & Land Use',
        icon: FoodAgricultureAndLandUse
    },
    {
        id: 'HEAVY_INDUSTRY',
        displayName: 'Heavy Industry',
        icon: HeavyIndustryIcon
    },
    {
        id: 'BUILT_ENVIRONMENT',
        displayName: 'Built Environment',
        icon: BuiltEnvironmentIcon
    },
    {
        id: 'GHG_CAPTURE_AND_STORAGE',
        displayName: 'GHG Capture & Storage',
        icon: GHGCaptureAndStorageIcon
    },
    {
        id: 'CLIMATE_AND_EARTH_DATA_GENERATION',
        displayName: 'Climate & Earth Data Generation',
        icon: ClimateAndEarthDataGeneration
    }
];
