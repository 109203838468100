import React, {Component} from "react";
import sharedClasses from "../Styles/SharedStyles.module.css";

class PersonalSubTitle extends Component {
    render() {
        let type = 'Personal';
        let location = this.props.profile.location;
        if (!!this.props.charLimit && location && location.length > this.props.charLimit) {
            location = this.props.profile.location.slice(0, this.props.charLimit - type.length) + '...';
        }

        return (
            <div className={sharedClasses.SubTitle}>
                <div>
                    {type}
                </div>
                {!!type && !!location &&
                <div>
                    <i className="fas fa-circle"/>
                </div>
                }
                <div>
                    {location}
                </div>
            </div>
        );
    }
}

export default PersonalSubTitle;
