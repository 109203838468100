import React, {Component} from 'react';
import classes from './SubmitButton.module.css';
import Spinner from "../../Spinner/spinner";

class SubmitButton extends Component {

    handleClick = (e) => {
        e.stopPropagation();
        if (!this.props.isSubmitting && !this.props.isDisabled) {
            this.props.onClick();
        }
    };

    render() {
        const containerClasses = [classes.Container];
        if (this.props.isSubmitting) {
            containerClasses.push(classes.ContainerSubmitting);
        }
        if (this.props.isDisabled) {
            containerClasses.push(classes.ContainerSubmitting);
        }
        if (this.props.isFullWidth) {
            containerClasses.push(classes.FullWidth);
        }
        if (this.props.className) {
            containerClasses.push(this.props.className);
        }
        return (
            <div className={containerClasses.join(' ')} onClick={this.handleClick}>
                <div className={classes.Label}>
                    {this.props.children}
                </div>
                {this.props.isSubmitting &&
                <div className={classes.SpinnerContainer}>
                    <Spinner/>
                </div>
                }
            </div>
        );
    }
}

export default SubmitButton;
