import apigClientFactory from 'aws-api-gateway-client';
import * as CognitoIdentity from 'aws-sdk/clients/cognitoidentity';
import {getApiGatewayUrl, getIdentityPoolId} from "./config";
import {SearchContext} from "../../constants/SearchContexts";

const POST = 'POST';
const PUT = 'PUT';
const DELETE = 'DELETE';
const GET = 'GET';

const IDENTITY_POOL_ID = getIdentityPoolId();
const API_GATEWAY_URL = getApiGatewayUrl();

const AWS_REGION = 'us-west-2';


class ApiGatewayClient {

    #authToken = null;
    #cognitoIdentity = new CognitoIdentity({region: AWS_REGION});
    #client = null;

    constructor(authToken) {
        this.#authToken = authToken;
    }

    setAuthToken = (authToken) => {
        this.#authToken = authToken;
    };

    validateAuthToken = async (authToken) => {
        const client = await this.#getClient();
        return await client.invokeApi({}, `/validateToken`, GET, {headers: {
            token: authToken
        }});
    };

    createProfile = async (profile) => {
        const client = await this.#getClient();
        return await client.invokeApi({}, `/profile/create`, POST, {headers: {
            token: this.#authToken
        }}, {
            profile: profile
        });
    };

    getAccount = async () => {
        const client = await this.#getClient();
        return await client.invokeApi({}, `/account`, GET, {headers: {
            token: this.#authToken
        }});
    };

    getProfile = async (profileId, requesterId) => {
        const client = await this.#getClient();
        return await client.invokeApi({}, `/profile/get`, POST, {headers: {
            token: this.#authToken
        }}, {
            profileId: profileId,
            requesterId: requesterId
        });
    };

    follow = async (profileId, followedByProfileId) => {
        const client = await this.#getClient();
        return await client.invokeApi({}, `/follow`, POST, {headers: {
            token: this.#authToken
        }}, {
            profileId: profileId,
            followedByProfileId: followedByProfileId
        });
    }

    unFollow = async (profileId, followedByProfileId) => {
        const client = await this.#getClient();
        return await client.invokeApi({}, `/unfollow`, POST, {headers: {
            token: this.#authToken
        }}, {
            profileId: profileId,
            followedByProfileId: followedByProfileId
        });
    }

    getFollowers = async (profileId) => {
        const client = await this.#getClient();
        return await client.invokeApi({}, `/profile/${profileId}/followers`, GET, {headers: {
            token: this.#authToken
        }});
    };

    getFollowing = async (profileId) => {
        const client = await this.#getClient();
        return await client.invokeApi({}, `/profile/${profileId}/following`, GET, {headers: {
            token: this.#authToken
        }});
    };

    getImageUploadUrl = async (fileExtension) => {
        const client = await this.#getClient();
        return await client.invokeApi({}, `/profileImageUploadURL`, POST, {headers: {
            token: this.#authToken
        }}, {
            fileExtension: fileExtension
        });
    };

    updateProfile = async (profile) => {
        const client = await this.#getClient();
        return await client.invokeApi({}, `/profile/update`, POST, {headers: {
            token: this.#authToken
        }}, {
            profile: profile
        });
    }

    deleteProfile = async (profileId) => {
        const client = await this.#getClient();
        return await client.invokeApi({}, `/profile/delete`, POST, {headers: {
            token: this.#authToken
        }}, {
            profileId: profileId
        });
    };

    query = async (context, fullTextQuery, filters, pageNumber, pageSize) => {
        const client = await this.#getClient();
        const request = {
            fullTextQuery: fullTextQuery,
            filters: filters,
            pageNumber: pageNumber,
            pageSize: pageSize,
            index: this.#getSearchIndex(context)
        };
        return await client.invokeApi({}, '/query', POST, {headers: {
            token: this.#authToken
        }}, request);
    };

    getSuggestions = async (context, prefix) => {
        const client = await this.#getClient();
        const request = {
            prefix: prefix,
            index: this.#getSearchIndex(context)
        };
        return await client.invokeApi({}, '/suggest', POST, {headers: {
            token: this.#authToken
        }}, request);
    }

    signUp = async (email, password, name) => {
        const client = await this.#getClient();
        const request = {
            email: email,
            password: password,
            name: name,
        };
        return await client.invokeApi({}, '/account', POST, {headers: {
            token: this.#authToken
        }}, request);
    };

    signIn = async (email, password) => {
        const client = await this.#getClient();
        const request = {
            email: email,
            password: password
        };
        return await client.invokeApi({}, '/login', POST, {headers: {
            token: this.#authToken
        }}, request);
    };

    confirmSignUp = async (code, username, clientId) => {
        const client = await this.#getClient();
        const request = {
            username: username,
            code: code,
            clientId: clientId,
        };
        return await client.invokeApi({}, '/confirmAccount', POST, {headers: {
            token: this.#authToken
        }}, request);
    };

    resendConfirmationCode = async (email) => {
        const client = await this.#getClient();
        const request = {
            username: email,
        };
        return await client.invokeApi({}, '/confirmAccount', POST, {headers: {
            token: this.#authToken
        }}, request);
    };

    confirmResetPassword = async (email, confirmationCode, newPassword) => {
        const client = await this.#getClient();
        const request = {
            email: email,
            confirmationCode: confirmationCode,
            newPassword: newPassword,
        };
        return await client.invokeApi({}, '/resetPassword', POST, {headers: {
            token: this.#authToken
        }}, request);
    };

    forgotPassword = async (email) => {
        const client = await this.#getClient();
        const request = {
            email: email,
        };
        return await client.invokeApi({}, '/resetPassword', POST, {headers: {
            token: this.#authToken
        }}, request);
    };

    getFeedMediaUploadUrl = async (fileExtension) => {
        const client = await this.#getClient();
        return await client.invokeApi({}, `/feedMediaUploadUrl`, POST, {headers: {
                token: this.#authToken
            }}, {
            fileExtension: fileExtension
        });
    };

    getFeedPost = async (profileId, postId, requesterId) => {
        const client = await this.#getClient();
        const request = {
            profileId: profileId,
            postId: postId,
            requesterId: requesterId
        };
        return await client.invokeApi({}, '/feedPost/get', POST, {headers: {
            token: this.#authToken
        }}, request);
    }

    getFeedPosts = async (context, subContext, pageNumber, pageSize, requesterId) => {
        const client = await this.#getClient();
        const request = {
            context: context,
            subContext: subContext,
            pageNumber: pageNumber,
            pageSize: pageSize,
            requesterId: requesterId
        };
        return await client.invokeApi({}, '/feedPosts/get', POST, {headers: {
                token: this.#authToken
        }}, request);
    };

    getComments = async (postId, pageNumber, pageSize, requesterId) => {
        const client = await this.#getClient();
        const request = {
            postId: postId,
            pageNumber: pageNumber,
            pageSize: pageSize,
            requesterId: requesterId
        };
        return await client.invokeApi({}, `/feedPost/${postId}/comments/get`, POST, {headers: {
            token: this.#authToken
        }}, request);
    };

    createFeedPost = async (feedPost) => {
        const client = await this.#getClient();
        return await client.invokeApi({}, "/feedPost", POST, {headers: {
            token: this.#authToken
        }}, feedPost);
    };

    editFeedPost = async (feedPost) => {
        const client = await this.#getClient();
        return await client.invokeApi({}, "/feedPost", PUT, {headers: {
            token: this.#authToken
        }}, feedPost);
    };

    deleteFeedPost = async (feedPost) => {
        const client = await this.#getClient();
        const request = {
            profileId: feedPost.profileId,
            feedPostId: feedPost.feedPostId,
        };
        return await client.invokeApi({}, "/feedPost", DELETE, {headers: {
            token: this.#authToken
        }}, request);
    };

    createFeedPostComment = async (postId, comment) => {
        const client = await this.#getClient();
        return await client.invokeApi({}, `/feedPost/${postId}/comment`, POST, {headers: {
            token: this.#authToken
        }}, comment);
    };

    editFeedPostComment = async (postId, comment) => {
        const client = await this.#getClient();
        return await client.invokeApi({}, `/feedPost/${postId}/comment`, PUT, {headers: {
            token: this.#authToken
        }}, comment);
    };

    deleteFeedPostComment = async (feedPostId, comment) => {
        const client = await this.#getClient();
        const request = {
            profileId: comment.profileId,
            commentId: comment.commentId,
        };
        return await client.invokeApi({}, `/feedPost/${feedPostId}/comment`, DELETE, {headers: {
                token: this.#authToken
            }}, request);
    };

    setFeedPostLikedStatus = async (profileId, feedPostId, isLiked, likedByProfileId) => {
        const client = await this.#getClient();
        const request = {
            profileId: profileId,
            isLiked: isLiked,
            likedByProfileId: likedByProfileId
        };
        return await client.invokeApi({}, `/feedPost/${feedPostId}/likedStatus`, POST, {headers: {
            token: this.#authToken
        }}, request);
    };

    contactUs = async (replyToEmail, content) => {
        const client = await this.#getClient();
        const request = {
            replyToEmail: replyToEmail,
            content: content
        };
        return await client.invokeApi({}, `/help/contact`, POST, {headers: {
            token: this.#authToken
        }}, request);
    };

    getRecommendations = async (profileId, toMatchWith, numRequested, requesterId) => {
        const client = await this.#getClient();
        const request = {
            profileId: profileId,
            toMatchWith: toMatchWith,
            numRequested: numRequested,
            requesterId: requesterId
        }
        return await client.invokeApi({}, `/recommendations/get`, POST, {headers: {
            token: this.#authToken
        }}, request);
    }

    getWeblinkPreview = async(previewUrl) => {
        const client = await this.#getClient();
        const request = {
            previewUrl: previewUrl
        };
        return await client.invokeApi({}, `/webLinkPreview/get`, POST, {headers: {
            token: this.#authToken
        }}, request);
    }

    getWeblinkPreviews = async(content) => {
        const client = await this.#getClient();
        const request = {
            content: content
        };
        return await client.invokeApi({}, `/webLinkPreviews/get`, POST, {headers: {
            token: this.#authToken
        }}, request);
    }

    #getClient = async () => {
        if (this.#client) {
            return this.#client;
        } else {
            const apigClient = await this.#createApiGatewayClient();
            if (!this.#client) {
                this.#client = apigClient;
            }
            return this.#client;
        }
    };

    #createApiGatewayClient = () =>  {
        return new Promise((resolve, reject) => {
            this.#doAuthentication(resolve, reject);
        });
    };

    #doAuthentication = (resolve, reject) => {
        const params = {
            IdentityPoolId: IDENTITY_POOL_ID
        };
        this.#cognitoIdentity.getId(params, (err, data) => {
            if (err) {
                console.log(err);
                reject();
            } else {
                this.#getCredentialsForIdentity(data.IdentityId, resolve, reject);
            }
        });
    };

    #getCredentialsForIdentity = (identityId, resolve, reject) => {
        const getCredentialsParams = {
            IdentityId: identityId
        };
        let accessKey;
        let secretKey;
        let sessionToken;
        this.#cognitoIdentity.getCredentialsForIdentity(getCredentialsParams, (err, data) => {
            if (err) {
                console.log(err);
                reject();
            } else {
                let credentials = data.Credentials;

                accessKey = credentials.AccessKeyId;
                secretKey = credentials.SecretKey;
                sessionToken = credentials.SessionToken;

                const CLIENT_CONFIG = {
                    invokeUrl: API_GATEWAY_URL,
                    region: AWS_REGION,
                    accessKey,
                    secretKey,
                    sessionToken
                };

                const client = apigClientFactory.newClient(CLIENT_CONFIG);
                resolve(client);
            }
        });
    };

    #getSearchIndex = (context) => {
        switch (context) {
            case SearchContext.Investors:
                return "investor";
            case SearchContext.Startups:
                return "company";
            case SearchContext.Personal:
                return "personal";
            default:
                return null;
        }
    }
}

export default ApiGatewayClient;
