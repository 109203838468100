import React, {Component} from 'react';
import {AppProvider} from './context/AppProvider';
import AppInner from "./AppInner";
import PopupContext, {PopupProvider} from "./context/PopupsProvider";

class App extends Component {

    render () {
        return (
            <AppProvider>
                <PopupProvider>
                    <AppInner />
                </PopupProvider>
            </AppProvider>
        )
    }
}

export default App;
