const PROD = 'prod';
const BETA = 'beta';

export const getApiGatewayUrl = () => {
    switch (process.env.REACT_APP_DOMAIN) {
        case PROD:
            return 'https://9wb38g5mye.execute-api.us-west-2.amazonaws.com/prod';
        case BETA:
        default:
            return 'https://gw4wuq7w87.execute-api.us-west-2.amazonaws.com/prod';
    }
};

export const getIdentityPoolId = () => {
    switch (process.env.REACT_APP_DOMAIN) {
        case PROD:
            return 'us-west-2:cd7ba62e-61fe-445d-8177-b31fbda87e05';
        case BETA:
        default:
            return 'us-west-2:f53b662d-60a0-4b0a-8102-4a6a171f7369';
    }
};
