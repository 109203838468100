import React, {Component, createRef} from "react";
import classes from "./PersonalProfileOnboarding.module.css";
import SubmitButton from "../Forms/SubmitButton/SubmitButton";
import sharedClasses from "../CreateProfile/CreateProfile.module.css";
import ProfilePhotoWithDefault from "../ProfilePage/ProfilePhotoWithDefault/ProfilePhotoWithDefault";
import Image from "../../model/Image";
import InputLabel from "../Forms/Label/InputLabel";
import stepClasses from "../ProfilePage/Styles/Steps.module.css";
import BasicTextInput from "../Forms/Input/BasicTextInput";
import TextArea from "../Forms/Input/TextArea";
import GlobalPopup from "../Popup/GlobalPopup/GlobalPopup";
import AppContext from "../../context/AppProvider";
import {ProfileType} from "../../constants/ProfileTypeConstants";
import {CloseIcon} from "../Icons/CloseIcon";
import PopupContext from "../../context/PopupsProvider";

class PersonalProfileOnboarding extends Component {

    static contextType = AppContext;

    state = {
        wrapperRef: createRef(),
        inputRef: createRef(),

        logo: null,
        description: '',
        location: '',
        isSubmitting: false
    }

    componentDidMount() {
        this.setState({
            description: this.context.currentProfile.description,
            location: this.context.currentProfile.location
        });
    }

    handleInputClick = () => {
        this.state.inputRef.current.click();
    };

    handleUploadedImage = (e) => {
        const [file] = e.target.files;
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                this.setState({logo: new Image(file, event.target.result)});
            };
            reader.readAsDataURL(file);
        }
    };

    setDescription = (description) => {
        this.setState({description: description});
    }

    setLocation = (location) => {
        this.setState({location: location});
    }

    onSubmit = async (e, popupContext) => {
        this.setState({isSubmitting: true}, async () => {
            let logo = this.context.currentProfile.logo;
            if (this.state.logo) {
                const re = /(?:\.([^.]+))?$/;
                const ext = re.exec(this.state.logo.file.name)[1]
                const imageUploadURLResponse = await this.context.apiGatewayClient.getImageUploadUrl(ext);
                logo = imageUploadURLResponse.data.fileName;
                await this.context.s3Client.uploadImage(this.state.logo.file, imageUploadURLResponse.data.uploadUrl);
            }

            const result = await this.context.apiGatewayClient.updateProfile({
                ...this.context.currentProfile,
                logo: logo,
                description: this.state.description,
                location: this.state.location,
                profileType: ProfileType.Personal
            });

            this.setState({isSubmitting: false});
            this.context.setCurrentProfile(result.data.profile);
            popupContext.openCreateProfilePopUp('Would you like to create a page?');
        });
    }

    render() {
        let imgSrc = null;
        let isUploadedLocally = false;
        if (!!this.state.logo) {
            imgSrc = this.state.logo.src;
            isUploadedLocally = true;
        } else if (!!this.context.currentProfile.logo) {
            imgSrc = this.context.currentProfile.logo;
        }

        return (
            <PopupContext.Consumer>
                {popupContext => (
                    <GlobalPopup onOusideClick={popupContext.closePersonalProfileOnBoardingPopup}>
                        <div className={classes.Container}>
                            <button className={classes.Exit} onClick={popupContext.closePersonalProfileOnBoardingPopup}>
                                <CloseIcon />
                            </button>
                            <div className={sharedClasses.CreateProfileHeader}>Let's finish creating your profile</div>
                            <div className={sharedClasses.CreateProfileDescription}>Make a great first impression by adding a high quality photo and some details about yourself.</div>
                            <div className={classes.Content}>
                                <div className={classes.BasicDetailsLeft}>
                                    <div className={classes.ImageContainer}>
                                        <div className={classes.ImageContainerInner}>
                                            <ProfilePhotoWithDefault logo={imgSrc} isUploadedLocally={isUploadedLocally}/>
                                            <div onClick={this.handleInputClick}  className={classes.ChangeButton}>
                                                <i className="fas fa-camera" />
                                            </div>
                                        </div>
                                    </div>
                                    <input className={sharedClasses.InvisibleInput} ref={this.state.inputRef} type="file"
                                           accept={['.png', '.jpg']} multiple={false} onChange={this.handleUploadedImage}/>
                                </div>
                                <div className={classes.BasicDetailsRight}>
                                    <InputLabel required={false}
                                                className={stepClasses.InputLabel}>Biography</InputLabel>
                                    <TextArea value={this.state.description} onChange={this.setDescription}
                                              placeholder={'Let others know about your experience.'}/>

                                    <InputLabel required={false} className={stepClasses.InputLabel}>Location</InputLabel>
                                    <BasicTextInput value={this.state.location} onChange={this.setLocation}
                                                    placeholder={'Location'}/>
                                </div>
                            </div>
                            <div className={sharedClasses.NavigationSingleButton}>
                                <SubmitButton onClick={e => this.onSubmit(e, popupContext)}
                                              className={sharedClasses.NextButton}
                                              isSubmitting={this.state.isSubmitting}>Finish</SubmitButton>
                            </div>
                        </div>
                    </GlobalPopup>
                )}
            </PopupContext.Consumer>
        )
    }
}

export default PersonalProfileOnboarding;
