import React, {Component, createRef, Fragment} from 'react';
import {NavLink, withRouter} from "react-router-dom";
import classes from "./NavigationBar.module.css";
import AccountDropdown from "./AccountDropdown/AccountDropdown";
import NavigationButton from "./NavigationButton/NavigationButton";
import AppContext from "../../context/AppProvider";
import ProfilePhotoWithDefault from "../ProfilePage/ProfilePhotoWithDefault/ProfilePhotoWithDefault";
import SearchBar from "./SearchBar/SearchBar";
import {InvestorSearchFilters} from "../../constants/InvestorSearchFilters";
import {CompanySearchFilters} from "../../constants/CompanySearchFilters";
import {SearchContext} from "../../constants/SearchContexts";
import _ from 'lodash';
import queryString from 'query-string';
import Logo from '../../assets/Logo.png';
import NavigationSlide from "./NavigationSlide/NavigationSlide";
import {HamburgerIcon} from "../Icons/HamburgerIcon";
import PopupContext from "../../context/PopupsProvider";

class NavigationBar extends Component {

    static contextType = AppContext;

    state = {
        searchRef: createRef(),
        filtersRef: createRef(),
        filterCountRef: createRef(),
        accountDropdownRef: createRef(),
        searchContext: SearchContext.All,
        fullTextQuery: '',
        selectedFilters: [],
        suggestions: [SearchContext.Investors, SearchContext.Startups, SearchContext.Personal].reduce((map, searchContext) => {
            map[searchContext] = [];
            return map;
        }, {}),

        isLoading: false,
        userSettingsIsDropped: false,
        isNavigationSlideOpen: false,
    };

    componentDidMount() {
        this.initSearchContext();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps === this.props) {
            return;
        }
        this.initSearchContext();
    }

    getSearchFilters = (searchContext) => {
        switch (searchContext) {
            case SearchContext.Investors:
                return InvestorSearchFilters;
            case SearchContext.Startups:
                return CompanySearchFilters;
            default:
                return [];
        }
    }

    getRedirectPath = (searchContext) => {
        switch (searchContext) {
            case SearchContext.Investors:
                return '/explore/investors';
            case SearchContext.Startups:
                return '/explore/companies';
            case SearchContext.Personal:
                return '/explore/people';
            default:
                return '/explore/all';
        }
    }

    initSearchContext = () => {
        if (!this.props.location.pathname.includes('explore')) {
            return;
        }
        let searchContext;
        if (this.props.location.pathname.includes('investors')) {
            searchContext = SearchContext.Investors;
        } else if (this.props.location.pathname.includes('companies')) {
            searchContext = SearchContext.Startups;
        } else if (this.props.location.pathname.includes('people')) {
            searchContext = SearchContext.Personal;
        } else {
            searchContext = SearchContext.All;
        }
        const parsed = queryString.parse(this.props.location.search);
        const searchFilters = this.getSearchFilters(searchContext);
        const selectedFilters = searchFilters.map(searchFilter => {
            const clone = _.cloneDeep(searchFilter);
            clone.filterOptions = [];
            if (!!parsed[searchFilter.id]) {
                const ids = parsed[searchFilter.id].split(',')
                clone.filterOptions = searchFilters.filter(sf => sf.id === searchFilter.id)[0].filterOptions.filter(filterOption => ids.includes(filterOption.id));
            }
            return clone;
        })
        this.setState({selectedFilters: selectedFilters, searchContext: searchContext, results: [], pageNumber: 0});
    }

    clearFullTextQuery = () => {
        this.setState({fullTextQuery: ''});
    }

    clearFilters = () => {
        this.setState({
            searchContext: SearchContext.All,
            selectedFilters: this.getSearchFilters(this.state.searchContext).map(filter => {
                const clone = _.cloneDeep(filter);
                clone.filterOptions = [];
                return clone;
            })
        }, this.submitQuery);
    };

    setSearchContext = (searchContext) => {
        this.clearFilters();
        if (this.state.searchContext === searchContext) {
            this.setState({selectedFilters: this.getSearchFilters(searchContext).map(filter => {
                    const clone = _.cloneDeep(filter);
                    clone.filterOptions = [];
                    return clone;
                }), searchContext: null}, this.submitQuery);
        } else {
            this.setState({selectedFilters: this.getSearchFilters(searchContext).map(filter => {
                    const clone = _.cloneDeep(filter);
                    clone.filterOptions = [];
                    return clone;
                }), searchContext: searchContext}, this.submitQuery);
        }
    };

    onSuggestionSelect = (suggestion) => {
        this.setState({fullTextQuery: suggestion}, this.submitQuery);
    }

    onFullTextQueryChange = (fullTextQuery) => {
        this.setState({fullTextQuery: fullTextQuery});
        Promise.all([
            this.context.apiGatewayClient.getSuggestions(SearchContext.Investors, fullTextQuery),
            this.context.apiGatewayClient.getSuggestions(SearchContext.Startups, fullTextQuery),
            this.context.apiGatewayClient.getSuggestions(SearchContext.Personal, fullTextQuery)
        ]).then(([investorResult, companyResult,personalResult ]) => {
            const suggestions = {};
            suggestions[SearchContext.Investors] = investorResult.data.profiles;
            suggestions[SearchContext.Startups] = companyResult.data.profiles;
            suggestions[SearchContext.Personal] = personalResult.data.profiles;
            this.setState({suggestions: suggestions});
        })
    };

    submitQuery = () => {
        const params = {}
        this.state.selectedFilters.filter(filter => filter.filterOptions.length > 0).forEach(filter => {
            params[filter.id] = filter.filterOptions.map(filterOption => filterOption.id).join(',')
        })
        params['query'] = this.state.fullTextQuery;
        this.setState({suggestions: []})
        this.props.history.push({
            pathname: this.getRedirectPath(this.state.searchContext),
            search: "?" + new URLSearchParams(params).toString()
        });
    };

    toggleUserSettingDropDown = () => {
        this.setState({userSettingsIsDropped: !this.state.userSettingsIsDropped});
    };

    openNavigationSlide = () => {
        this.setState({isNavigationSlideOpen: true});
    }

    closeNavigationSlide = () => {
        this.setState({isNavigationSlideOpen: false});
    }

    setCurrentProfile = (profile) => {
        this.context.setCurrentProfile(profile, profile.profileType);
        if (!!this.state.accountDropdownRef && !!this.state.accountDropdownRef.current) {
            this.state.accountDropdownRef.current.scrollTo({top: 0, behavior: 'smooth'});
        }
    }

    navigateTo = (route) => {
        this.props.history.push(route);
        this.props.toggle();
    }

    render() {
        const {authToken, currentProfile} = this.context;
        const isAuthenticated = !!authToken;

        return (
            <div className={classes.NavigationBar}>
                <div className={classes.NavigationBarInner}>
                    <div className={classes.NavigationPrimary}>
                        <div className={classes.NavigationBarLeft}>
                            <div className={classes.Logo}>
                                <NavLink to="/">
                                    <img src={Logo} />
                                </NavLink>
                            </div>
                            <div className={classes.SideNavToggle} onClick={this.openNavigationSlide}>
                                <HamburgerIcon />
                            </div>
                            <div className={classes.SearchControls} ref={this.state.searchRef}>
                                <SearchBar onSubmit={this.submitQuery}
                                           setSearchContext={this.setSearchContext}
                                           onChange={this.onFullTextQueryChange}
                                           suggestions={this.state.suggestions}
                                           onSuggestionSelect={this.onSuggestionSelect}
                                           value={this.state.fullTextQuery}/>
                            </div>
                        </div>
                        <div className={classes.NavigationBarRight}>
                            {!isAuthenticated &&
                            <Fragment>
                                <PopupContext.Consumer>
                                    {popupContext => (
                                    <Fragment>
                                        <NavigationButton text='Sign up' onClick={popupContext.openSignUpPopUp}/>
                                        <NavigationButton text='Sign in' primary={true} onClick={popupContext.openSignInPopUp}/>
                                    </Fragment>
                                    )}
                                </PopupContext.Consumer>
                            </Fragment>
                            }
                            {isAuthenticated &&
                            <div className={classes.AccountOption}>
                                <div className={classes.AccountOptionInner}
                                     onClick={this.toggleUserSettingDropDown}>
                                    <ProfilePhotoWithDefault logo={!!currentProfile ? currentProfile.logo : null}
                                                             name={!!currentProfile ? currentProfile.name : null}/>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    {(isAuthenticated && this.state.userSettingsIsDropped) &&
                    <div className={classes.AccountDropdown} ref={this.state.accountDropdownRef}>
                        <AccountDropdown toggle={this.toggleUserSettingDropDown}
                                         setCurrentProfile={this.setCurrentProfile}/>
                    </div>
                    }
                    <div className={classes.NavigationBarRightSmall}>
                        <NavigationSlide isOpen={this.state.isNavigationSlideOpen}
                                         setCurrentProfile={this.setCurrentProfile}
                                         close={this.closeNavigationSlide}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(NavigationBar);
