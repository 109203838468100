import React, {Component} from "react";
import classes from "../CreateProfile.module.css";
import SubmitButton from "../../Forms/SubmitButton/SubmitButton";
import MultiSelectCards from "../../Forms/Cards/MultiSelectCards";
import {FocusAreaFilterOptions} from "../../../constants/filteroptions/FocusAreaFilterOptions";
import sharedClasses from "../CreateProfile.module.css";

class FocusAreasStep extends Component {

    render() {
        return (
            <div>
                <div className={classes.CreateProfileHeader}>Let us know who you are</div>
                <div className={sharedClasses.CreateProfileDescription}>Let startups know which areas you are most interested in targeting.</div>
                <div className={classes.FormContainer}>
                    <MultiSelectCards options={FocusAreaFilterOptions}
                                      setSelected={(selectedOptions) => this.props.setFocusAreas(selectedOptions.map(option => option.id))}
                                      currentlySelected={FocusAreaFilterOptions.filter(option => this.props.focusAreas.find(focusArea => focusArea === option.id))}/>
                </div>
                <div className={classes.Navigation}>
                    <SubmitButton onClick={this.props.previousStep} className={sharedClasses.PreviousButton}>Previous</SubmitButton>
                    <SubmitButton onClick={this.props.onSubmit} isSubmitting={this.props.isSubmitting} className={classes.NextButton}>Finish</SubmitButton>
                </div>
                <div className={classes.PageDetailsContainer}>
                    3 of 3
                </div>
            </div>
        )
    }
}

export default FocusAreasStep;
