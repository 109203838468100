import React, {Component, createContext} from "react";
import AppSyncClient from "../clients/appsync/AppSync";
import ApiGatewayClient from "../clients/apigateway/ApiGateway";
import S3Client from "../clients/s3/S3Client";


const AppContext = createContext({});

export class AppProvider extends Component {
    state = {
        authToken: localStorage.getItem('authToken'),
        accountData: JSON.parse(localStorage.getItem('accountData')),
        currentProfile: JSON.parse(localStorage.getItem('currentProfile')),
        apiGatewayClient : new ApiGatewayClient(localStorage.getItem('authToken')),
        s3Client: new S3Client(),
        appSyncClient : new AppSyncClient(localStorage.getItem('authToken')),
    };

    componentDidMount() {
        if (!!this.state.authToken) {
            this.state.apiGatewayClient.validateAuthToken(this.state.authToken).then(result => {
                if (!result.data.isValid) {
                    this.clearAccountInfo();
                }
            });
        } else {
            this.clearAccountInfo();
        }

        document.addEventListener("visibilitychange", this.onTabVisibilityChange);
    }

    onTabVisibilityChange = () => {
        if (document.visibilityState === 'visible' && !!this.state.authToken) {
            this.state.apiGatewayClient.validateAuthToken(this.state.authToken).then(result => {
                if (!result.data.isValid) {
                    this.clearAccountInfo();
                }
            });
        }
    };

    clearAccountInfo = () => {
        this.setAuthToken(null);
        this.setCurrentProfile(null);
        this.setAccountData(null);
    };

    setAuthToken = (authToken) => {
        if (!authToken) {
            localStorage.removeItem('authToken');
            localStorage.removeItem('accountData');
            localStorage.removeItem('currentProfile');
            this.state.apiGatewayClient.setAuthToken(null);
            this.state.appSyncClient.setAuthToken(null);
            this.setState({authToken: null, accountData: null, currentProfile: null});
        } else {
            this.state.apiGatewayClient.setAuthToken(authToken);
            this.state.appSyncClient.setAuthToken(authToken);
            localStorage.setItem('authToken', authToken);
            this.setState({authToken: authToken});
        }
    };

    setAccountData = (accountData) => {
        if (!!accountData) {
            localStorage.setItem('accountData', JSON.stringify(accountData));
        } else {
            localStorage.removeItem('accountData');
        }
        this.setState({accountData: accountData});
    };

    setCurrentProfile = (profile, profileType) => {
        if (!!profile) {
            localStorage.setItem('currentProfile', JSON.stringify(profile));
            profile.profileType = profileType;
        } else {
            localStorage.removeItem('currentProfile');
        }
        this.setState({currentProfile: profile});
    };

    render () {
        const {
            authToken,
            accountData,
            currentProfile,
            apiGatewayClient,
            s3Client,
            appSyncClient,
        } = this.state;
        const {
            setAuthToken,
            setAccountData,
            setCurrentProfile,
        } = this;

        return (
            <AppContext.Provider value={{
                authToken,
                accountData,
                currentProfile,
                apiGatewayClient,
                s3Client,
                appSyncClient,
                setAuthToken,
                setAccountData,
                setCurrentProfile,
            }}>
                {this.props.children}
            </AppContext.Provider>
        )
    }
}


export default AppContext;
