import classes from "./Cards.module.css";
import React from "react";
import CardOption from "./CardOption";


const SingleSelectCards = (props) => {

    return (
        <div className={classes.CreateProfileCardOptionContainer}>
            {props.options.map((option, index) => {
                return (
                    <CardOption key={index}
                                option={option}
                                onClick={() => props.setSelected(option)}
                                isSelected={props.currentlySelected === option}/>
                );
            })}
        </div>
    );
};

export default SingleSelectCards;
