import React, {Component} from "react";
import classes from './FeedPostContent.module.css';
import ReactStringReplace from 'react-string-replace';
import {Contexts} from "../../Feed";
import {withRouter} from "react-router-dom";

class FeedPostContent extends Component {

    state = {
        isExpanded: false
    }

    expandContent = (e) => {
        e.stopPropagation();
        this.setState({isExpanded: true});
    }

    collapseContent = (e) => {
        e.stopPropagation();
        this.setState({isExpanded: false});
    }

    render() {
        const isOverSized = this.props.content.length > 400;

        let content;
        if (isOverSized && !this.state.isExpanded) {
            content = ReactStringReplace(this.props.content.slice(0, 300) + '...', /(\w+:\/\/\S+)/g, (match, _) => (
                <a className={classes.Link} href={match} target="_blank"><i className="fas fa-link" /> {match}</a>
            ));
        } else {
            content = ReactStringReplace(this.props.content, /(\w+:\/\/\S+)/g, (match, _) => (
                <a className={classes.Link} href={match} target="_blank"><i className="fas fa-link" /> {match}</a>
            ));
        }

        return (
            <pre>
                {content.map(node => typeof node === 'string' ? ReactStringReplace(node, /#(\w+)/g, (match, i) => (
                    <span className={classes.HashTag} onClick={e => {e.stopPropagation(); this.props.history.push(`/feed/${Contexts.HashTagContext}/${match}`)}}>#{match}</span>
                )) : node)}
                {isOverSized && !this.state.isExpanded &&
                    <div onClick={this.expandContent} className={classes.ShowMore}>
                        Show more <i className="fas fa-angle-down"/>
                    </div>
                }
                {isOverSized && this.state.isExpanded &&
                <div onClick={this.collapseContent} className={classes.ShowMore}>
                    Show less <i className="fas fa-angle-up"/>
                </div>
                }
            </pre>
        );
    }
}

export default withRouter(FeedPostContent);
