import React from 'react';
import {NavLink} from "react-router-dom";
import classes from './SearchResult.module.css';
import {InvestorTypeFilterOptions} from "../../../constants/filteroptions/InvestorTypeFilterOptions";
import GetDescription from './SearchResultDescription/SearchResultDescriptionHighlighter';
import ProfilePhotoWithDefault from "../../ProfilePage/ProfilePhotoWithDefault/ProfilePhotoWithDefault";


const PersonalSearchResult = (props) => {
    const profile = props.personal.profile;
    const description = GetDescription(props.personal.tokenOffsets, profile.description);
    return (
        <div className={classes.Container}>
            <NavLink to={`/profile/${profile.profileId}`} className={classes.LinkContainer}>
                <div className={classes.SearchResult}>
                    <div className={classes.Left}>
                        <div className={classes.Logo}>
                            <ProfilePhotoWithDefault logo={profile.logo} name={profile.name} />
                        </div>
                        <div className={classes.Metadata}>
                            <h3>
                                {profile.name}
                            </h3>
                            <div className={classes.SubTitle}>
                                <i className="fas fa-map-marker-alt" />
                                {profile.location}
                            </div>
                        </div>
                    </div>
                    <div className={classes.Right}>
                        <div className={classes.Description}>
                            {description}
                        </div>
                    </div>
                </div>
            </NavLink>
        </div>
    );
};

const getInvestorType = (investorTypeId) => {
    return InvestorTypeFilterOptions.find(investorTypeFilterOption => investorTypeFilterOption.id === investorTypeId);
};


export default PersonalSearchResult;
