import classes from "./Cards.module.css";
import React from "react";
import CardOption from "./CardOption";


const GetNewSelectedOptions = (currentlySelectedOptions, clickedOption) => {
    if (!currentlySelectedOptions) {
        return [clickedOption];
    } else if (!currentlySelectedOptions.find(option => clickedOption.id === option.id)) {
        return [...currentlySelectedOptions, clickedOption];
    } else {
        return currentlySelectedOptions.filter(option => clickedOption.id !== option.id);
    }
};

const MultiSelectCards = (props) => {

    return (
        <div className={classes.CreateProfileCardOptionContainer}>
            {props.options.map((option, index) => {
                return (
                    <CardOption key={index}
                                option={option}
                                onClick={() => props.setSelected(GetNewSelectedOptions(props.currentlySelected, option))}
                                isSelected={!!props.currentlySelected.find(selectedOption => option.id === selectedOption.id)}/>
                );
            })}
        </div>
    );
};

export default MultiSelectCards;
