import React, {Component, createRef} from "react";
import classes from "./EditFeaturedImages.module.css";
import Image from "../../../../../model/Image";
import SubmitButton from "../../../../Forms/SubmitButton/SubmitButton";
import GlobalPopup from "../../../../Popup/GlobalPopup/GlobalPopup";
import AppContext from "../../../../../context/AppProvider";
import _ from "lodash";
import InputLabel from "../../../../Forms/Label/InputLabel";
import stepClasses from "../../../Styles/Steps.module.css";
import EditPreviewPhoto from "../../../../Forms/AddPhoto/EditPreviewPhoto";
import AddPhotoButton from "../../../../Forms/AddPhoto/AddPhotoButton";
import sharedClasses from "../../styles/EditableFieldStyles.module.css";
import {ProfileAssetsBucketPrefix} from "../../../../../constants/ProfileAssetsBucketPrefix";

class EditFeaturedImages extends Component {

    static contextType = AppContext;

    state = {
        isEditing: false,
        isSubmitting: false,
        wrapperRef: createRef(),
        inputRef: createRef(),
        featuredImages: []
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick);
        this.setState({featuredImages: !!this.props.profile.featuredImages
                ? this.props.profile.featuredImages.map(featuredImage => new Image(null, featuredImage))
                : []});
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick);
    }

    handleClick = (event) => {
        if (!!this.state.wrapperRef && !!this.state.wrapperRef.current && !this.state.wrapperRef.current.contains(event.target)) {
            this.closeEditModal();
        }
    }

    openEditModal = () => {
        this.setState({isEditing: true});
    }

    closeEditModal = () => {
        this.setState({isEditing: false, isSubmitting: false, featuredImages: !!this.props.profile.featuredImages
                ? this.props.profile.featuredImages.map(featuredImage => new Image(null, featuredImage))
                : []});
    }

    selectFeaturedImages = (featuredImages) => {
        this.setState({featuredImages: featuredImages}, this.onDataChange);
    };

    canSubmit = () => {
        if (this.state.featuredImages.filter(featuredImage => !!featuredImage.file).length > 0) {
            return true;
        } else {
            const a = !this.props.profile.featuredImages ? [] : this.props.profile.featuredImages;
            const b = !this.state.featuredImages ? [] : this.state.featuredImages;
            return a.length !== b.length;
        }
    }

    handleSave = async () => {
        if (this.canSubmit()) {
            this.setState({isSubmitting: true}, async () => {
                const newFeaturedImages = await Promise.all(this.state.featuredImages.map(async featuredImage => {
                    if (!!featuredImage.file) {
                        const re = /(?:\.([^.]+))?$/;
                        const ext = re.exec(featuredImage.file.name)[1]
                        const imageUploadURLResponse = await this.context.apiGatewayClient.getImageUploadUrl(ext);
                        await this.context.s3Client.uploadImage(featuredImage.file, imageUploadURLResponse.data.uploadUrl);
                        return imageUploadURLResponse.data.fileName;
                    } else {
                        return featuredImage.src;
                    }
                }));
                const updatedProfile = _.cloneDeep(this.props.profile);
                updatedProfile.featuredImages = newFeaturedImages;
                await this.context.apiGatewayClient.updateProfile(updatedProfile);
                this.setState({isSubmitting: false, isEditing: false, newValue: null});
                this.props.setProfile(updatedProfile);
            })
        }
    }

    render() {
        const canSubmit = this.canSubmit();
        return (
            <div>
                <div className={classes.EditIcon} onClick={this.openEditModal}>
                    Add or remove photos
                </div>
                {this.state.isEditing &&
                <GlobalPopup onOutsideClick={this.closeEditModal}>
                    <div className={sharedClasses.PopupContainer} ref={this.state.wrapperRef}>
                        <div className={classes.Title}>
                            <h3>Profile photo</h3>
                        </div>
                        <div className={sharedClasses.ProfileEditPopup}>
                            <InputLabel required={true} className={stepClasses.InputLabel}>Featured Images</InputLabel>

                            {!this.state.featuredImages || this.state.featuredImages.length === 0 &&
                            <div className={stepClasses.NoFeaturedImages}>
                                <div className={stepClasses.NoFeaturedImagesIcon}>
                                    <i className="fas fa-images"/>
                                </div>
                                <div className={stepClasses.NoFeaturedImagesText}>
                                    No featured images
                                </div>

                            </div>
                            }

                            <div className={stepClasses.FeaturedImages}>
                                {this.state.featuredImages.map(featuredImage => {
                                    if (!featuredImage.file) {
                                        const alreadyUploadedImage = new Image(null, `${ProfileAssetsBucketPrefix}${featuredImage.src}`);
                                        return (
                                            <div className={stepClasses.FeaturedImage}>
                                                <EditPreviewPhoto
                                                    handlePhotoAdded={newImage => this.selectFeaturedImages(this.state.featuredImages.map(image => featuredImage !== image ? image : newImage))}
                                                    handlePhotoDelete={() => this.selectFeaturedImages(this.state.featuredImages.filter(image => featuredImage !== image))}
                                                    photo={alreadyUploadedImage}
                                                    label={'Add featured image'}/>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div className={stepClasses.FeaturedImage}>
                                                <EditPreviewPhoto
                                                    handlePhotoAdded={newImage => this.selectFeaturedImages(this.state.featuredImages.map(image => featuredImage !== image ? image : newImage))}
                                                    handlePhotoDelete={() => this.selectFeaturedImages(this.state.featuredImages.filter(image => featuredImage !== image))}
                                                    photo={featuredImage}
                                                    label={'Add featured image'}/>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                            <AddPhotoButton
                                handlePhotoAdded={image => this.selectFeaturedImages([...this.state.featuredImages, image])}
                                label='Add a featured image'/>
                        </div>
                        <div className={sharedClasses.SubmitContainer}>
                            <SubmitButton className={classes.CancelOption} onClick={this.closeEditModal}>
                                Cancel
                            </SubmitButton>
                            <SubmitButton className={classes.SaveOption}
                                          isDisabled={!canSubmit}
                                          onClick={this.handleSave}
                                          isSubmitting={this.state.isSubmitting}>
                                Save
                            </SubmitButton>
                        </div>
                    </div>
                </GlobalPopup>
                }
            </div>
        );
    }
}

export default EditFeaturedImages;
