import {EMAIL_REGEX} from "../../constants/Constants";

export const isValidPassword = (password) => {
    return password && password.length > 0;
}

export const isValidEmail = (email) => {
    if (!email) {
        return false;
    }
    return email.match(EMAIL_REGEX);
};
