import React, {Component, Fragment} from 'react';
import FeedPost from './../../Feed/FeedPost/FeedPost';
import AppContext from '../../../context/AppProvider';
import classes from './Posts.module.css';
import Placeholder from "./../../Feed/PlaceHolder/PlaceHolder";
import Spinner from "../../Spinner/spinner";

class Posts extends Component {

    static contextType = AppContext;

    render() {
        return (
            <Fragment>
                <div className={classes.PostsContainer}>
                    {this.props.feedPosts.map(feedPost => <FeedPost feedPost={feedPost}
                                                                    setHashTagSearch={this.setHashTagSearch}
                                                                    key={feedPost.postId}
                                                                    editFeedPost={this.props.editFeedPost}
                                                                    deleteFeedPost={this.props.deleteFeedPost}
                                                                    openSignInPopUp={this.props.openSignInPopUp}/>)}
                    {this.props.isLoading && this.props.pageNumber === 0 &&
                    <PlaceHolders/>
                    }
                    {!this.props.reachedEndOfResults &&
                    <div className={classes.SpinnerContainer}>
                        <Spinner/>
                    </div>
                    }
                </div>
            </Fragment>
        )
    }
}

const PlaceHolders = () => {
    return (
        <Fragment>
            <Placeholder />
            <Placeholder />
            <Placeholder />
            <Placeholder />
            <Placeholder />
        </Fragment>
    )
}

export default Posts;
