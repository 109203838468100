import AIML from '../../assets/Technologies/ai_ml.png';
import Autonomy from '../../assets/Technologies/autonomy.png';
import Aviation from '../../assets/Technologies/aviation.png';
import BigData from '../../assets/Technologies/bigdata.png';
import BioEngineering from '../../assets/Technologies/bioengineering.png';
import ConstructionTech from '../../assets/Technologies/constructiontech.png';
import EnergyStorage from '../../assets/Technologies/energystorage.png';
import FoodTech from '../../assets/Technologies/foodtech.png';
import GeneralEngineering from '../../assets/Technologies/generalengineering.png';
import GeoEngineering from '../../assets/Technologies/geoengineering.png';
import Hydro from '../../assets/Technologies/hydro.png';
import IoT from '../../assets/Technologies/IoT.png';
import Nuclear from '../../assets/Technologies/nuclear.png';
import Robotics from '../../assets/Technologies/robotics.png';
import Software from '../../assets/Technologies/software.png';
import Solar from '../../assets/Technologies/solar.png';
import Wind from '../../assets/Technologies/wind.png';

export const TechnologyFilterOptions = [
    {
        id: 'CONSTRUCTION_TECH',
        displayName: 'Construction Tech',
        icon: ConstructionTech,
        color: '#E5233D'
    },
    {
        id: 'SOFTWARE',
        displayName: 'Software',
        icon: Software,
        color: '#DDA73A'
    },
    {
        id: 'IOT',
        displayName: 'IoT',
        icon: IoT,
        color: '#4CA146'
    },
    {
        id: 'BIG_DATA',
        displayName: 'Big Data',
        icon: BigData,
        color: '#C7212F'
    },
    {
        id: 'AI_ML',
        displayName: 'AI/ML',
        icon: AIML,
        color: '#EF402D'
    },
    {
        id: 'ENERGY_STORAGE',
        displayName: 'Energy Storage',
        icon: EnergyStorage,
        color: '#27BFE6'
    },
    {
        id: 'AUTONOMY',
        displayName: 'Autonomy',
        icon: Autonomy,
        color: '#FBC412'
    },
    {
        id: 'BIO_ENGINEERING',
        displayName: 'Bio-Engineering',
        icon: BioEngineering,
        color: '#A31C44'
    },
    {
        id: 'GEO_ENGINEERING',
        displayName: 'Geo-Engineering',
        icon: GeoEngineering,
        color: '#F26A2E'
    },
    {
        id: 'WIND',
        displayName: 'Wind',
        icon: Wind,
        color: '#E01483'
    },
    {
        id: 'SOLAR',
        displayName: 'Solar',
        icon: Solar,
        color: '#F89D2A'
    },
    {
        id: 'HYDRO',
        displayName: 'Hydro',
        icon: Hydro,
        color: '#BF8D2C'
    },
    {
        id: 'NUCLEAR',
        displayName: 'Nuclear',
        icon: Nuclear,
        color: '#407F46'
    },
    {
        id: 'FOOD_TECH',
        displayName: 'Food Tech',
        icon: FoodTech,
        color: '#1F97D4'
    },
    {
        id: 'AVIATION',
        displayName: 'Aviation',
        icon: Aviation,
        color: '#59BA47'
    },
    {
        id: 'ROBOTICS',
        displayName: 'Robotics',
        icon: Robotics,
        color: '#136A9F'
    },
    {
        id: 'GENERAL_ENGINEERING',
        displayName: 'General Engineering',
        icon: GeneralEngineering,
        color: '#14496B'
    },
];
