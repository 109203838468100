import React, {Component} from 'react';
import classes from './SignUpPopUp.module.css';
import SignUp from './SignUp/SignUp';
import GlobalPopup from "../../Popup/GlobalPopup/GlobalPopup";
import sharedClasses from '../../../styles/FormStyles.module.css';
import ThirdPartySignIn from "./ThirdPartySignIn/ThirdPartySignIn";
import {CloseIcon} from "../../Icons/CloseIcon";
import AppContext from "../../../context/AppProvider";
import PopupContext from "../../../context/PopupsProvider";

const Context = {
    SignUp: 'SignUp',
    ThirdPartySignIn: 'ThirdPartySignIn'
}

class SignUpPopUp extends Component {

     static contextType = AppContext;

    state = {
        context: this.props.isThirdPartySignInOpen ? Context.ThirdPartySignIn : Context.SignUp,

        accountIdentityProvider: '',
        email: ''
    }

    componentWillReceiveProps(newProps, nextContext) {
        if (!!newProps.location && !!newProps.location.state && !!newProps.location.state.isSignInPopUpOpen) {
            this.setState({isSignInPopUpOpen: newProps.location.state.isSignInPopUpOpen})
        }
    };

    openSignThirdPartySignIn = (accountIdentityProvider, email) => {
        this.setState({context: Context.ThirdPartySignIn, accountIdentityProvider: accountIdentityProvider, email: email});
    };

    render() {
        return (
            <PopupContext.Consumer>
                {popupContext => (
                    <GlobalPopup onOutsideClick={popupContext.closeSignUpPopUp}>
                        <div className={classes.SignUpPopUp}>
                            <div className={sharedClasses.PopupInner}>
                                <div className={sharedClasses.Header}>
                                    <button className={sharedClasses.Exit} onClick={popupContext.closeSignUpPopUp}>
                                        <CloseIcon />
                                    </button>
                                    <h2>
                                        Sign up
                                    </h2>
                                </div>
                                <div className={sharedClasses.FormContainer}>
                                    {this.state.context === Context.SignUp &&
                                    <SignUp onCompletion={popupContext.closeSignUpPopUp}/>
                                    }
                                    {/*{this.state.context === Context.ThirdPartySignIn &&*/}
                                    {/*<ThirdPartySignIn openSignIn={this.props.openSignIn}*/}
                                    {/*                  accountIdentityProvider={this.state.accountIdentityProvider}*/}
                                    {/*                  email={this.state.email}/>*/}
                                    {/*}*/}
                                </div>
                            </div>
                        </div>
                    </GlobalPopup>
                )}
            </PopupContext.Consumer>
        )
    }
}

export default SignUpPopUp;
