import React, {Component, createRef, Fragment} from "react";
import classes from "./NavigationSlide.module.css";
import {withRouter} from "react-router-dom";
import AppContext from "../../../context/AppProvider";
import SideNav from "../../SideNav/SideNav";

class NavigationSlide extends Component {

    static contextType = AppContext;

    state = {
        accountDropdownRef: createRef(),
    }

    navigateTo = (route) => {
        this.props.history.push(route);
        this.props.close();
    }

    setCurrentProfile = (profile) => {
        this.props.setCurrentProfile(profile);
        if (!!this.state.accountDropdownRef && !!this.state.accountDropdownRef.current) {
            this.state.accountDropdownRef.current.scrollTo({top: 0, behavior: 'smooth'});
        }
    }

    render() {
        const {authToken} = this.context;
        const isAuthenticated = !!authToken;
        let containerClasses = this.props.isOpen
            ?  [classes.NavigationSmallOptions, classes.NavigationSmallOptionsActive]
            : [classes.NavigationSmallOptions];
        if (!isAuthenticated) {
            containerClasses.push(classes.NavigationSmallOptionsWithBackground)
        }

        return (
            <Fragment>
                {this.props.isOpen &&
                <div className={classes.Backdrop} onClick={this.props.close}/>
                }
                <div className={containerClasses.join(' ')} ref={this.state.accountDropdownRef}>
                    <SideNav onOptionSelected={this.props.close}/>
                </div>
            </Fragment>
        );
    }
}


export default withRouter(NavigationSlide);
