import React, {Component, createRef} from "react";
import classes from "./EditFounders.module.css";
import Image from "../../../../../model/Image";
import SubmitButton from "../../../../Forms/SubmitButton/SubmitButton";
import GlobalPopup from "../../../../Popup/GlobalPopup/GlobalPopup";
import AppContext from "../../../../../context/AppProvider";
import _ from "lodash";
import InputLabel from "../../../../Forms/Label/InputLabel";
import stepClasses from "../../../Styles/Steps.module.css";
import EditPreviewPhoto from "../../../../Forms/AddPhoto/EditPreviewPhoto";
import AddPhotoButton from "../../../../Forms/AddPhoto/AddPhotoButton";
import BasicTextInput from "../../../../Forms/Input/BasicTextInput";
import sharedClasses from "../../styles/EditableFieldStyles.module.css";
import {ProfileAssetsBucketPrefix} from "../../../../../constants/ProfileAssetsBucketPrefix";

class EditFounders extends Component {

    static contextType = AppContext;

    state = {
        isEditing: false,
        isSubmitting: false,
        wrapperRef: createRef(),
        inputRef: createRef(),
        founders: []
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick);
        this.setState({
            founders: !!this.props.profile.founders
                ? this.props.profile.founders.map(founder => {
                    return {
                        name: founder.name,
                        linkedIn: founder.linkedIn,
                        headshot: new Image(null, founder.headshot)
                    }
                })
                : []
        });
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick);
    }

    handleClick = (event) => {
        if (!!this.state.wrapperRef && !!this.state.wrapperRef.current && !this.state.wrapperRef.current.contains(event.target)) {
            this.closeEditModal();
        }
    }

    openEditModal = () => {
        this.setState({isEditing: true});
    }

    closeEditModal = () => {
        this.setState({
            isEditing: false,
            isSubmitting: false,
            founders: !!this.props.profile.founders
                ? this.props.profile.founders.map(founder => {
                    return {
                        name: founder.name,
                        linkedIn: founder.linkedIn,
                        headshot: new Image(null, founder.headshot)
                    }
                })
                : []
        });

    }

    selectFounders = (founders) => {
        this.setState({founders: founders});
    };

    handleSave = async () => {
        this.setState({isSubmitting: true}, async () => {
            const newFounders = await Promise.all(this.state.founders.map(async founder => {
                if (!!founder.headshot.file) {
                    const re = /(?:\.([^.]+))?$/;
                    const ext = re.exec(founder.headshot.file.name)[1]
                    const imageUploadURLResponse = await this.context.apiGatewayClient.getImageUploadUrl(ext);
                    await this.context.s3Client.uploadImage(founder.headshot.file, imageUploadURLResponse.data.uploadUrl);
                    return {
                        name: founder.name,
                        linkedIn: founder.linkedIn,
                        headshot: imageUploadURLResponse.data.fileName,
                    };
                } else {
                    return {
                        name: founder.name,
                        linkedIn: founder.linkedIn,
                        headshot: founder.headshot.src,
                    };
                }
            }));
            const updatedProfile = _.cloneDeep(this.props.profile);
            updatedProfile.founders = newFounders;
            await this.context.apiGatewayClient.updateProfile(updatedProfile);
            this.setState({isSubmitting: false, isEditing: false, newValue: null});
            this.props.setProfile(updatedProfile);
        })
    }

    render() {
        return (
            <div>
                <div className={classes.EditIcon} onClick={this.openEditModal}>
                    Add or remove people
                </div>
                {this.state.isEditing &&
                <GlobalPopup onOutsideClick={this.closeEditModal}>
                    <div className={sharedClasses.PopupContainer} ref={this.state.wrapperRef}>
                        <div className={classes.Title}>
                            <h3>Profile photo</h3>
                        </div>
                        <div className={sharedClasses.ProfileEditPopup}>
                            <InputLabel className={stepClasses.InputLabel} required={true}>Add Founders</InputLabel>
                            {!this.state.founders || this.state.founders.length === 0 &&
                            <div className={stepClasses.NoFeaturedImages}>
                                <div>
                                    <div className={stepClasses.NoFeaturedImagesIcon}>
                                        <i className="fas fa-users"/>
                                    </div>
                                    <div className={stepClasses.NoFeaturedImagesText}>
                                        No founders
                                    </div>
                                </div>
                            </div>
                            }
                            <div className={stepClasses.FounderInputs}>
                                {this.state.founders.map(founder => {
                                    if (!founder.headshot.file) {
                                        const alreadyUploadedHeadshot = !!founder.headshot.src
                                            ? new Image(null, `${ProfileAssetsBucketPrefix}${founder.headshot.src}`)
                                            : new Image(null, null);
                                        const modifiedFounder = {
                                            name: founder.name,
                                            linkedIn: founder.linkedIn,
                                            headshot: alreadyUploadedHeadshot
                                        }
                                        return (
                                            <div className={stepClasses.FounderInputContainer}>
                                                <FounderInput
                                                    removeFounder={() => this.selectFounders(this.state.founders.filter(f => f !== founder))}
                                                    onFounderChange={newFounder => this.selectFounders(this.state.founders.map(f => f !== founder ? f : newFounder))}
                                                    founder={modifiedFounder}/>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div className={stepClasses.FounderInputContainer}>
                                                <FounderInput
                                                    removeFounder={() => this.selectFounders(this.state.founders.filter(f => f !== founder))}
                                                    onFounderChange={newFounder => this.selectFounders(this.state.founders.map(f => f !== founder ? f : newFounder))}
                                                    founder={founder}/>
                                            </div>
                                        )
                                    }
                                })}
                            </div>

                            <div className={stepClasses.AddFounderButtonContainer}
                                 onClick={() => this.selectFounders([...this.state.founders, {
                                     headshot: new Image(null, null, true),
                                     name: '',
                                     linkedIn: ''
                                 }])}>
                                <i className="fas fa-plus"/>
                                Add a founder
                            </div>
                        </div>
                        <div className={sharedClasses.SubmitContainer}>
                            <SubmitButton className={classes.CancelOption} onClick={this.closeEditModal}>
                                Cancel
                            </SubmitButton>
                            <SubmitButton className={classes.SaveOption}
                                          isDisabled={false}
                                          onClick={this.handleSave}
                                          isSubmitting={this.state.isSubmitting}>
                                Save
                            </SubmitButton>
                        </div>
                    </div>
                </GlobalPopup>
                }
            </div>
        );
    }
}

const FounderInput = (props) =>  {
    return (
        <div className={stepClasses.FounderContainer}>
            <InputLabel required={true}>Headshot</InputLabel>
            {!props.founder.headshot.src &&
            <div className={stepClasses.NoHeadshot}>
                <div>
                    <div className={stepClasses.NoHeadshotIcon}>
                        <i className="fas fa-user"/>
                    </div>
                    <div className={stepClasses.NoHeadshotText}>
                        No headshot
                    </div>
                </div>
            </div>
            }
            {!!props.founder.headshot.src &&
            <EditPreviewPhoto handlePhotoAdded={photo => props.onFounderChange({
                name: props.founder.name,
                headshot: photo,
                linkedIn: props.founder.linkedIn
            })}
                              handlePhotoDelete={() => props.onFounderChange({
                                  name: props.founder.name,
                                  headshot: new Image(null, null, true),
                                  linkedIn: props.founder.linkedIn
                              })}
                              photo={props.founder.headshot}/>
            }
            {!props.founder.headshot.src &&
            <AddPhotoButton handlePhotoAdded={photo => props.onFounderChange({
                name: props.founder.name,
                headshot: photo,
                linkedIn: props.founder.linkedIn
            })}
                            className={stepClasses.AddHeadShotButton} label='Add a headshot'/>
            }

            <InputLabel required={true} className={stepClasses.InputLabel}>Name</InputLabel>
            <BasicTextInput value={props.founder.name} onChange={name => props.onFounderChange({name: name, headshot: props.founder.headshot, linkedIn: props.founder.linkedIn})} placeholder={'Name'}/>

            <InputLabel required={true} className={stepClasses.InputLabel}>LinkedIn</InputLabel>
            <BasicTextInput value={props.founder.linkedIn} onChange={linkedIn => props.onFounderChange({name: props.founder.name, headshot: props.founder.headshot, linkedIn: linkedIn})} placeholder={'LinkedIn'}/>

            <div className={stepClasses.RemoveFounderIconContainer}>
                <div className={stepClasses.RemoveFounderIcon} onClick={props.removeFounder}>
                    <i className="fas fa-trash-alt" /> Remove
                </div>
            </div>
        </div>
    );
};

export default EditFounders;
