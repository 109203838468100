/* eslint-disable */
import gql from 'graphql-tag';

export const sendMessage = gql(`
    mutation SendMessage($conversationId: ID!, $message: MessageInput!, $idToken: String!) {
        sendMessage(conversationId: $conversationId, 
            message: $message,
            idToken: $idToken
        ) {
            content
            contentType
            conversationId
            messageId
            sentAtDateEpoch
            author {
            profileId
            }
        }
    }
`) 

export const createConversation = gql(`
    mutation CreateConversation($createConversationInput: CreateConversationInput!, $idToken: String!) {
        createConversation(createConversationInput: $createConversationInput, idToken: $idToken)
    }
`)
    
