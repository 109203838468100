import React, {Component, Fragment} from 'react';
import {NavLink, withRouter} from "react-router-dom";
import FeaturedEntity from './FeaturedProfiles/FeaturedEntity/FeaturedEntity';
import Footer from './Footer/Footer';
import {FEATURED_INVESTORS} from '../../constants/FeaturedInvestorConstants';
import {FEATURED_STARTUPS} from '../../constants/FeaturedStartupConstants';
import classes from "./Landing.module.css";
import sharedClasses from '../../styles/Styles.module.css';
import AppContext from "../../context/AppProvider";
import QuestionnaireBar from "./QuestionnaireBar/QuestionnaireBar";
import SubmitButton from "../Forms/SubmitButton/SubmitButton";
import Carousel from "../Carousel/Carousel";
import PopupContext from "../../context/PopupsProvider";

class Landing extends Component {

    static contextType = AppContext;

    state = {
        partitionSize: 4,
    };

    componentDidMount = () => {
        this.checkPartitionSize();
        window.addEventListener('resize', this.checkPartitionSize);
    };

    checkPartitionSize = () => {
        let partitionSize = 3;
        if (window.innerWidth < 1200 && window.innerWidth > 700) {
            partitionSize = 2;
        } else if (window.innerWidth <= 700) {
            partitionSize = 1;
        }
        if (partitionSize !== this.state.partitionSize) {
            this.setState({partitionSize: partitionSize});
        }
    }

    partition = (list, partitionSize) => {
        let chunks = [], i = 0, n = list.length;
        while (i < n) {
            chunks.push(list.slice(i, i += partitionSize));
        }
        return chunks;
    }

    render() {
        return (
            <div className={sharedClasses.ContentContainer}>
                <div className={[classes.Container, classes.LandingContainer].join(' ')}>
                    <div className={[classes.ContainerInner].join(' ')}>
                        <div className={classes.LandingLeft}>
                            <h2 className={classes.LandingTitle}>
                                The network for climate tech.
                            </h2>
                            <div className={classes.Description}>
                                Connect with investors and startups and help solve our
                                generation’s most difficult climate challenges.
                            </div>
                            <QuestionnaireBar />
                        </div>
                    </div>
                </div>
                <div className={classes.FeaturedContainer}>
                    <div className={[classes.ContainerInner, classes.FeaturedProfilesContainer].join(' ')}>
                        <div className={classes.FeaturedProfiles}>
                            <h2 className={classes.Title}>
                                Featured investors
                            </h2>
                            <NavLink to={'/explore/investors'} className={classes.SeeAllLink}>
                                See all investors
                            </NavLink>
                            <Carousel>
                                {this.partition(FEATURED_INVESTORS, this.state.partitionSize).map(partition => (
                                    <div className={classes.FullWidth}>
                                        <div className={classes.SpaceBetween}>
                                            {partition.map(featuredInvestor => (
                                                <div className={classes.FeaturedProfile}>
                                                    <FeaturedEntity entity={featuredInvestor}/>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                        <div className={classes.FeaturedProfiles}>
                            <h2 className={classes.Title}>
                                Featured startups
                            </h2>
                            <NavLink to={'/explore/companies'} className={classes.SeeAllLink}>
                                See all startups
                            </NavLink>
                            <Carousel>
                                {this.partition(FEATURED_STARTUPS, this.state.partitionSize).map(partition => (
                                    <div className={classes.FullWidth}>
                                        <div className={classes.SpaceBetween}>
                                            {partition.map(featuredStartup => (
                                                <div className={classes.FeaturedProfile}>
                                                    <FeaturedEntity entity={featuredStartup}/>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                        <div className={classes.FeaturedProfiles}>
                            <h2 className={classes.Title}>
                                What you get
                            </h2>
                            <div className={classes.FeaturedDescription}>
                                How to get the most out of ClimateHub
                            </div>
                            <Carousel>
                                <div className={classes.FullWidth}>
                                    <div className={classes.LeftHalf}>
                                        <img src={'https://techcrunch.com/wp-content/uploads/2019/05/FuturePositive.jpg'} />
                                    </div>
                                    <div className={classes.RightHalf}>
                                        <div>
                                            <h3>As an investor</h3>
                                            <h2>
                                                Explore early stage startups, network with founders, and find unique opportunities.
                                            </h2>
                                            <div>
                                                <PopupContext.Consumer>
                                                    {popupContext => (
                                                        <SubmitButton className={classes.JohnTest} onClick={() => popupContext.openCreateProfilePopUp()}>Create an investor page</SubmitButton>
                                                    )}
                                                </PopupContext.Consumer>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.FullWidth}>
                                    <div className={classes.LeftHalf}>
                                        <img src={'https://prod-climate-investment-profile-images.s3-us-west-2.amazonaws.com/359e3cf3-ae9e-4fa3-9686-6ca31136085a.jpg'} />
                                    </div>
                                    <div className={classes.RightHalf}>
                                        <div>
                                            <h3>As a startup</h3>
                                            <h2>
                                                Gain exposure to climate focused investors, network with other founders, and make key connections.
                                            </h2>
                                            <div>
                                                <PopupContext.Consumer>
                                                    {popupContext => (
                                                        <SubmitButton className={classes.JohnTest} onClick={() => popupContext.openCreateProfilePopUp()}>Create a startup page</SubmitButton>
                                                    )}
                                                </PopupContext.Consumer>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default withRouter(Landing);
