import React, {Component} from 'react';
import classes from './BasicTextInput.module.css';
import emailRegex from 'email-regex';
import urlRegex from 'url-regex';
import {InputType} from "./InputTypes.constants";

class BasicTextInput extends Component {
    state = {
        isFocused: false
    };

    onFocusIn = () => {
        this.setState({isFocused: true});
    };

    onFocusOut = () => {
        this.setState({isFocused: false});
    };

    isValid = (type, value) => {
        if (type === InputType.WebLink) {
            return urlRegex({exact: true, strict: false}).test(value);
        } else if (type === InputType.Email) {
            return emailRegex({exact: true}).test(value);
        }
        return true;
    };

    render() {
        const containerClasses = [classes.Container];
        if (this.state.isFocused && !this.props.isDisabled) {
            containerClasses.push(classes.ContainerFocused);
        }
        if (!!this.props.error) {
            containerClasses.push(classes.ContainerError);
        }

        return (
            <div className={classes.ContainerOuter}>
                <div className={containerClasses.join(' ')}>
                    <input type={this.props.type}
                           value={this.props.value}
                           onChange={e => this.props.onChange(e.target.value)}
                           onFocus={this.onFocusIn}
                           onBlur={this.onFocusOut}
                           disabled={this.props.isDisabled}
                           placeholder={this.props.placeholder}
                           maxLength={this.props.maxLength}/>
                </div>
                {!!this.props.maxLength &&
                <div className={classes.CharacterCounter}>
                    {!!this.props.value ? this.props.value.length : 0} of {this.props.maxLength} characters used
                </div>
                }
                {!this.isValid(this.props.type, this.props.value) &&
                <div className={classes.TypeError}>
                    <i className="fas fa-info-circle"/> value is not of the proper type
                </div>
                }
                {!!this.props.error &&
                <div className={classes.Error}>
                    <i className="fas fa-exclamation-circle"/> {this.props.error}
                </div>
                }
            </div>
        );
    }
}

export default BasicTextInput;
