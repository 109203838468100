export const BusinessModelFilterOptions = [
    {
        id: 'B2B',
        displayName: 'B2B'
    },
    {
        id: 'B2C',
        displayName: 'B2C'
    },
    {
        id: 'B2G',
        displayName: 'B2G'
    },
    {
        id: 'MARKETPLACE',
        displayName: 'Marketplace'
    },
];