import React, {Component, createRef, Fragment} from 'react';
import FeedPost from './FeedPost/FeedPost';
import AppContext from '../../context/AppProvider';
import classes from './Feed.module.css';
import sharedClasses from '../../styles/Styles.module.css';
import Placeholder from "./PlaceHolder/PlaceHolder";
import CreateModal from "./CreateModal/CreateModal";
import Recommendations from "../Recommendations/Recommendations";
import {withRouter} from "react-router-dom";
import Spinner from "../Spinner/spinner";
import SubmitButton from "../Forms/SubmitButton/SubmitButton";
import {RecommendationsMatchType} from "../../constants/RecommendationMatchTypeConstants";
import SideNav from "../SideNav/SideNav";
import PopupContext from "../../context/PopupsProvider";


export const Contexts = {
    All: "All",
    FollowingPostsContext: "following",
    LikedPostsContext: "like",
    PersonalPostsContext: "personal",
    HashTagContext: "hashtag",
}
const AllSubContext = "AllSubContext"
const PageSize = 20;

class Feed extends Component {

    static contextType = AppContext;

    state = {
        pageNumber: 0,
        feedPosts: [],
        isLoading: false,
        isFeedPostSubmitLoading: false,
        reachedEndOfResults: false,
        context: Contexts.All,
        subContext: AllSubContext,

        ref: createRef()
    };

    componentDidMount() {
        this.initFeedPosts();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps === this.props) {
            return;
        }
        this.initFeedPosts();
    }

    initFeedPosts = () => {
        const pathComponents = this.props.location.pathname.split('/').filter(component => !!component && component.length > 0);
        let context = Contexts.All;
        let subContext = AllSubContext;
        if (pathComponents.includes('hashtag') && !!pathComponents[2] && pathComponents[2].length > 0) {
            context = Contexts.HashTagContext;
            subContext = '#' + pathComponents[2];
        } else if (pathComponents.includes('following') && !!pathComponents[2] && pathComponents[2].length > 0) {
            context = Contexts.FollowingPostsContext;
            subContext = pathComponents[2];
        } else if (pathComponents.includes('like') && !!pathComponents[2] && pathComponents[2].length > 0) {
            context = Contexts.LikedPostsContext;
            subContext = pathComponents[2];
        } else if (pathComponents.includes('personal') && !!pathComponents[2] && pathComponents[2].length > 0) {
            context = Contexts.PersonalPostsContext;
            subContext = pathComponents[2];
        }
        this.setState({
            isLoading: true,
            context: context,
            subContext: subContext,
            feedPosts: [],
            pageNumber: 0,
        }, () => {
            const requesterId = !!this.context.currentProfile ? this.context.currentProfile.profileId : '';
            this.context.apiGatewayClient.getFeedPosts(context, subContext, this.state.pageNumber, PageSize, requesterId).then(result => {
                const reachedEndOfResults = result.data.feedPosts.length < PageSize;
                this.setState({feedPosts: result.data.feedPosts, isLoading: false, reachedEndOfResults: reachedEndOfResults});
                if (this.state.pageNumber === 0 && !!this.state.ref && !!this.state.ref.current) {
                    this.state.ref.current.scrollTo({top: 0, behavior: 'smooth'});
                }
            });
        });
    }

    setSubContext = (subContext) => {
        this.setState({subContext: subContext});
    }

    setContext = (context, subContext) => {
        if (context === Contexts.All) {
            this.props.history.push(`/feed`);
        } else if (context === Contexts.HashTagContext) {
            this.props.history.push(`/feed/${Contexts.HashTagContext}/${subContext.slice(1,)}`);
        } else {
            this.props.history.push(`/feed/${context}/${subContext}`);
        }
    }

    handleScroll = (e) => {
        if (this.state.isLoading || this.state.reachedEndOfResults) {
            return;
        }
        if (e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 1000) {
            // you're at the bottom of the component
            this.setState({pageNumber : this.state.pageNumber + 1, isLoading: true}, () => {
                this.context.apiGatewayClient.getFeedPosts(this.state.context, this.state.subContext, this.state.pageNumber, PageSize).then(result => {
                    if (!!result.data && !!result.data.feedPosts && result.data.feedPosts.length > 0) {
                        const reachedEndOfResults = result.data.feedPosts.length < PageSize;
                        this.setState({feedPosts: [...this.state.feedPosts, ...result.data.feedPosts], isLoading: false, reachedEndOfResults: reachedEndOfResults});
                    } else {
                        this.setState({isLoading: false, reachedEndOfResults: true});
                    }
                });
            });
        }
    };

    editFeedPost = (feedPost) => {
        const updatedFeedPosts = this.state.feedPosts.map(post => {
            if (post.postId === feedPost.postId) {
                return feedPost;
            } else {
                return post;
            }
        });
        this.setState({feedPosts: updatedFeedPosts});
    };

    deleteFeedPost = (postId) => {
        this.setState({feedPosts: this.state.feedPosts.filter(post => post.postId !== postId)});
    };

    createFeedPost = async (content, images, videos) => {
        await this.setState({isFeedPostSubmitLoading: true}, async () => {
            const imageRefs = await Promise.all(images.map(async img => {
                const re = /(?:\.([^.]+))?$/;
                const ext = re.exec(img.file.name)[1]
                const result = await this.context.apiGatewayClient.getFeedMediaUploadUrl(ext);
                await this.context.s3Client.uploadImage(img.file, result.data.uploadUrl);
                return result.data.fileName;
            }));
            const videoRefs = await Promise.all(videos.map(async vid => {
                const re = /(?:\.([^.]+))?$/;
                const ext = re.exec(vid.file.name)[1]
                const result = await this.context.apiGatewayClient.getFeedMediaUploadUrl(ext);
                await this.context.s3Client.uploadImage(vid.file, result.data.uploadUrl);
                return result.data.fileName;
            }));

            const feedPost = {
                profileId: this.context.currentProfile.profileId,
                content: content,
                images: imageRefs,
                videos: videoRefs
            };

            const result = await this.context.apiGatewayClient.createFeedPost(feedPost);
            const feedPosts = this.state.feedPosts;
            feedPosts.unshift(result.data.feedPost);
            this.setState({feedPosts: feedPosts, isFeedPostSubmitLoading: false});
        });
    };

    onFollowButtonClick = (feedPost) => {
        if (!this.context.currentProfile) {
            this.context.openSignInPopUp();
            return;
        }

        if (feedPost.isFollowing) {
            this.context.apiGatewayClient.unFollow(feedPost.profileId, this.context.currentProfile.profileId);
        } else {
            this.context.apiGatewayClient.follow(feedPost.profileId, this.context.currentProfile.profileId);
        }

        this.setState({feedPosts: this.state.feedPosts.map(post => {
            if (feedPost.profileId === post.profileId) {
                post.isFollowing = !post.isFollowing
            }
            return post;
        })})
    }

    render() {
        const {currentProfile} = this.context;

        return (
            <div className={sharedClasses.ContentContainer} onScroll={this.handleScroll} ref={this.state.ref}>
                <div className={sharedClasses.ContentContainerInner}>
                    <div className={classes.SideNav}>
                        <SideNav />
                    </div>
                    <div className={classes.FeedPosts}>
                        {!!currentProfile && this.state.context === Contexts.All &&
                            <Fragment>
                                <div className={classes.CreatePost}>
                                    <CreateModal onSubmit={this.createFeedPost}
                                                 placeholder={"Share an update..."}
                                                 isSubmitting={this.state.isFeedPostSubmitLoading}/>
                                </div>
                                <div className={classes.CreateBottom} />
                            </Fragment>
                        }
                        {!currentProfile && this.state.context === Contexts.All &&
                        <Fragment>
                            <div className={classes.CreatePlaceholder}>
                                <h3>New to ClimateHub?</h3>
                                <div className={classes.CreatePlaceholderDescription}>Sign up to post and engage with
                                    other members.
                                </div>
                                <PopupContext.Consumer>
                                    {popupContext => (
                                        <SubmitButton className={classes.CreatePlaceholderButton}
                                              onClick={popupContext.openSignUpPopUp}>Sign up</SubmitButton>
                                        )}
                                </PopupContext.Consumer>
                            </div>
                            <div className={classes.CreateBottom} />
                        </Fragment>
                        }
                        {this.state.context === Contexts.HashTagContext &&
                        <div className={classes.HashTagSearchBarContainer}>
                            {this.state.subContext}
                        </div>
                        }
                        <div className={classes.FeedResults}>
                            {this.state.feedPosts.map(feedPost => <FeedPost feedPost={feedPost}
                                                                            key={feedPost.postId}
                                                                            onFollowButtonClick={this.onFollowButtonClick}
                                                                            editFeedPost={this.editFeedPost}
                                                                            deleteFeedPost={this.deleteFeedPost}/>)}
                            {this.state.isLoading && this.state.pageNumber === 0 &&
                            <PlaceHolders/>
                            }
                            {!this.state.reachedEndOfResults && !(this.state.pageNumber === 0 && this.state.isLoading) &&
                            <div className={classes.SpinnerContainer}>
                                <Spinner/>
                            </div>
                            }
                        </div>
                        {this.state.reachedEndOfResults && this.state.feedPosts && this.state.feedPosts.length > 0 &&
                        <div className={classes.EndOfResults}>
                            End of results.
                        </div>
                        }
                    </div>
                    <div className={sharedClasses.ClimateAds}>
                        <Recommendations profileId={this.context.profileId}
                                         toMatchWith={RecommendationsMatchType.Both}/>
                    </div>
                </div>
            </div>
        )
    }
}

const PlaceHolders = () => {
    return (
        <Fragment>
            <Placeholder />
            <Placeholder />
            <Placeholder />
            <Placeholder />
            <Placeholder />
            <Placeholder />
            <Placeholder />
            <Placeholder />
        </Fragment>
    );
};

export default withRouter(Feed);
