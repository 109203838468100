/* eslint-disable */
import gql from 'graphql-tag';

export const getConversation = gql(`
    query GetConversation($conversationId: String!, $idToken: String!) {
    getConversation(conversationId: $conversationId, idToken: $idToken) {
        __typename
        conversationId
        participants {
            profileId
        },
        metadata {
            admins {
                profileId
            }
        },
        mostRecentMessage {
            messageId,
            author {
                profileId
            },
            sentAtDateEpoch,
            content,
            contentType
        }
    }
}`);

export const getConversations = gql(`
    query GetConversations($profileId: String!, $exclusiveStartKey: ExclusiveStartKeyForConversationsIndexInput, $idToken: String!) {
        getConversations(profileId: $profileId, exclusiveStartKey: $exclusiveStartKey, idToken: $idToken) {
            __typename
            conversations {
                conversationId,
                participants {
                    profileId
                },
                metadata {
                    admins {
                        profileId
                    }
                },
                mostRecentMessage {
                    messageId,
                    author {
                        profileId
                    },
                    sentAtDateEpoch,
                    content,
                    contentType
                }
            }
            lastEvaluatedKey {
                id,
                sortId,
                latestMessageDateEpoch
            }
        }
}`);

export const getMessages = gql(`
query GetMessages($conversationId: String!, $exclusiveStartKey: ConversationsPrimaryKeyInput, $idToken: String!) {
    getMessages(conversationId: $conversationId, exclusiveStartKey: $exclusiveStartKey, idToken: $idToken) {
      conversationId
      messages {
        content
        contentType
        conversationId
        messageId
        sentAtDateEpoch
        author {
          profileId
        }
      }
      lastEvaluatedKey {
        id,
        sortId
      }
    }
  }
`)
