import React, {Component} from 'react';
import classes from "../CreateProfile.module.css";
import {ProfileType, ProfileTypes} from '../../../constants/ProfileTypeConstants'
import SingleSelectCards from "../../Forms/Cards/SingleSelectCards";
import SubmitButton from "../../Forms/SubmitButton/SubmitButton";
import {RocketIcon} from "../../Icons/RocketIcon";
import {HandshakeIcon} from "../../Icons/HandshakeIcon";
import PopupContext from "../../../context/PopupsProvider";

class ProfileTypeStep extends Component {

    state = {
        profileType: null
    }

    selectProfileType = (profileType) => {
        this.setState({profileType: profileType});
    }

    onSubmit = () => {
        if (!!this.state.profileType) {
            this.props.selectProfileType(this.state.profileType);
        }
    }

    render() {
        return (
            <div>
                <PopupContext.Consumer>
                    {popupContext => (
                        <div className={classes.CreateProfileHeaderCentered}>
                            {!popupContext.createProfilePopupTitle ? 'Let us know who you are' : popupContext.createProfilePopupTitle}
                        </div>
                    )}
                </PopupContext.Consumer>
                <div className={classes.FormContainer}>
                    <div onClick={() => this.selectProfileType(ProfileType.Startup)}
                         className={this.state.profileType === ProfileType.Startup
                             ? [classes.ProfileTypeContainer, classes.ProfileTypeContainerActive].join(' ')
                             : classes.ProfileTypeContainer}>
                        <div className={classes.ProfileTypeIcon}>
                            <RocketIcon />
                        </div>
                        <div className={classes.ProfileTypeContent}>
                            <h2>
                                Startup
                            </h2>
                            <div className={classes.ProfileTypeContentDescription}>
                                Create a page for your startup and gain exposure to climate focused investors
                            </div>
                        </div>
                    </div>
                    <div onClick={() => this.selectProfileType(ProfileType.Investor)}
                         className={this.state.profileType === ProfileType.Investor
                             ? [classes.ProfileTypeContainer, classes.ProfileTypeContainerActive].join(' ')
                             : classes.ProfileTypeContainer}>
                        <div className={classes.ProfileTypeIcon}>
                            <HandshakeIcon />
                        </div>
                        <div className={classes.ProfileTypeContent}>
                            <h2>
                                Investor
                            </h2>
                            <div className={classes.ProfileTypeContentDescription}>
                                Create a page for a venture capital firm, accelerator, public funder, corporation, or non-profit
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.NavigationSingleButton}>
                    <SubmitButton onClick={this.onSubmit} isDisabled={!this.state.profileType} className={classes.NextButton}>Next</SubmitButton>
                </div>
            </div>
        );
    }
}

export default ProfileTypeStep;



