import React, {Component, Fragment} from 'react';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import AppContext from './context/AppProvider';
import classes from './App.module.css';
import Landing from './containers/Landing/Landing';
import Explore from './containers/Explore/Explore';
import Feed from './containers/Feed/Feed';
import NativeAccountRedirect from './containers/Auth/NativeAccountRedirect/NativeAccountRedirect';
import ProfilePage from "./containers/ProfilePage/ProfilePage";
import FeedPostPage from './containers/Feed/FeedPostPage/FeedPostPage';
import NavigationBar from "./containers/NavigationBar/NavigationBar";
import GoogleAccountRedirect from "./containers/Auth/GoogleAccountRedirect/GoogleAccountRedirect";
import ConversationsPopup from "./containers/Conversations/ConversationsPopup";
import ConversationsPage from "./containers/Conversations/ConversationsPage";
import TermsOfService from "./containers/Legal/TermsOfService/TermsOfService";
import PrivacyPolicy from "./containers/Legal/PrivacyPolicy/PrivacyPolicy";
import SignInPopUp from "./containers/Auth/SignInPopUp/SignInPopUp";
import SignUpPopUp from "./containers/Auth/SignUpPopUp/SignUpPopUp";
import CreateProfilePopUp from "./containers/CreateProfile/CreateProfile";
import PersonalProfileOnboarding from "./containers/PersonalProfileOnboarding/PersonalProfileOnboarding";
import PopupContext from "./context/PopupsProvider";

class AppInner extends Component {

    static contextType = AppContext;

    render () {
        const routes = (
            <Fragment>
                <Switch>
                    <Route path="/feed" component={Feed} />
                    <Route path="/explore" component={Explore} />
                    <Route path='/profile/:profileId' component={ProfilePage}/>
                    <Route path='/feedPost/:postId/profile/:profileId' component={FeedPostPage} />
                    <Route path='/confirmAccount' component={NativeAccountRedirect}/>
                    <Route path='/googleAccountRedirect' component={GoogleAccountRedirect} />
                    {!!this.context.authToken && <Route path='/messages/create/:profileId' component={ConversationsPage} />}
                    {!!this.context.authToken && <Route path='/messages/:conversationId' component={ConversationsPage} />}
                    {!!this.context.authToken && <Route path='/messages' component={ConversationsPage} />}
                    <Route path='/terms' component={TermsOfService} />
                    <Route path='/privacy' component={PrivacyPolicy} />
                    {!this.context.authToken && <Route path="/" exact component={Landing} />}
                    {!!this.context.authToken ? <Redirect to="/feed"/> : <Redirect to="/"/>}
                </Switch>
            </Fragment>
        );

        return (
            <div className={classes.PrimaryContainer}>
                <div>
                    <NavigationBar />
                </div>
                {routes}
                {!this.props.location.pathname.includes('messages') && !!this.context.currentProfile && <ConversationsPopup />}
                <PopupContext.Consumer>
                    {popupContext => (
                        <Fragment>
                            {popupContext.isSignInPopUpOpen && <SignInPopUp />}
                            {popupContext.isSignUpPopUpOpen && <SignUpPopUp />}
                            {popupContext.isCreateProfilePopUpOpen && <CreateProfilePopUp createProfilePopupTitle={popupContext.createProfilePopupTitle}/>}
                            {popupContext.isPersonalProfileOnBoardingPopupOpen && <PersonalProfileOnboarding openCreateProfilePopUp={() => popupContext.openCreateProfilePopUp('Would you like to create a page?')}/>}
                        </Fragment>
                    )}
                </PopupContext.Consumer>
            </div>
        )
    }
}

export default withRouter(AppInner);
