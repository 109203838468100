import FeaturedImage2150 from '../assets/Featured/Investors/2150_featured.jpg';
import BetterVentures from '../assets/Featured/Investors/betterventures_featured.jpg';
import FuturePositiveCapital from '../assets/Featured/Investors/FuturePositive_featured.jpg';
import GreenTown from '../assets/Featured/Investors/greentown_featured.jpg';
import HomecomingCapital from '../assets/Featured/Investors/homecomingcapital_featured.jpg';
import Katapult from '../assets/Featured/Investors/katapult_featured.jpeg';
import Powerhouse from '../assets/Featured/Investors/powerhouse_featured.jpeg';
import UrbanUs from '../assets/Featured/Investors/UrbanUs_featured.jpeg';
import YCombinator from '../assets/Featured/Investors/ycombinator_featured.jpeg';

export const FEATURED_INVESTORS = [
    {
        name: '2150',
        logo: 'https://prod-climate-investment-profile-images.s3-us-west-2.amazonaws.com/27929e30-b829-4f5d-9f76-9b3852ccf4a9.png',
        image: FeaturedImage2150,
        link: 'https://climatehub.io/profile/03146c31-231d-4083-a30d-504fbf447873',
        location: 'London, England'
    },
    {
        name: 'Better Ventures',
        logo: 'https://prod-climate-investment-profile-images.s3-us-west-2.amazonaws.com/968552b9-ae4b-40b7-b6d7-fe4c2e2b254b.png',
        image: BetterVentures,
        link: 'https://climatehub.io/profile/da6f2757-7d2a-4ca9-88fd-b81f105e0c85',
        location: 'Oakland, CA'
    },
    {
        name: 'Future Positive Capital',
        logo: 'https://prod-climate-investment-profile-images.s3-us-west-2.amazonaws.com/895e6768-1615-408b-9551-78c47d117288.png',
        image: FuturePositiveCapital,
        link: 'https://climatehub.io/profile/31350c7c-5b33-48dc-9598-c62af9d56c12',
        location: 'Paris, France'
    },
    {
        name: 'Greentown Labs',
        logo: 'https://prod-climate-investment-profile-images.s3-us-west-2.amazonaws.com/24021f1d-93a7-4bcb-a74d-2637aabb53c2.png',
        image: GreenTown,
        link: 'https://climatehub.io/profile/9263d9d8-e602-4421-83c3-7ecaf6c5d168',
        location: 'Boston, MA'
    },
    {
        name: 'Homecoming Capital',
        logo: 'https://prod-climate-investment-profile-images.s3-us-west-2.amazonaws.com/bc92731c-256a-49d1-bb12-9287dbe8e40f.png',
        image: HomecomingCapital,
        link: 'https://climatehub.io/profile/2c402654-1ec5-4a34-a3e8-5fbbc55adbfa',
        location: 'San Francisco, CA'
    },
    {
        name: 'Katapult Accelerator',
        logo: 'https://prod-climate-investment-profile-images.s3-us-west-2.amazonaws.com/1c3ce9b5-6340-4dfa-9e7e-9c1ccc11a366.png',
        image: Katapult,
        link: 'https://climatehub.io/profile/42e96f3a-4fde-408e-ba1d-b93b153afec1',
        location: 'Oslo, Norway'
    },
    {
        name: 'Powerhouse',
        logo: 'https://prod-climate-investment-profile-images.s3-us-west-2.amazonaws.com/40c441f7-7f4c-4bf7-bee2-b922ffd41953.png',
        image: Powerhouse,
        link: 'https://climatehub.io/profile/7055c433-4cfd-4688-a02c-a0904a695893',
        location: 'Oakland, CA'
    },
    {
        name: 'UrbanUs',
        logo: 'https://prod-climate-investment-profile-images.s3-us-west-2.amazonaws.com/aafe7432-fb53-49b0-b2cc-b374c0e81eac.png',
        image: UrbanUs,
        link: 'https://climatehub.io/profile/5dfb8437-ec33-42a3-8f65-062059032f6c',
        location: 'San Francisco, CA'
    },
    {
        name: 'YCombinator',
        logo: 'https://prod-climate-investment-profile-images.s3-us-west-2.amazonaws.com/288c08fc-0fef-43c1-8399-67935cac1edf.png',
        image: YCombinator,
        link: 'https://climatehub.io/profile/6d316de8-107d-46d3-a03f-b69c84ddf0fd',
        location: 'San Francisco, CA'
    }
];
