import React from 'react';

import classes from './PlaceHolder.module.css';

const PlaceHolder = () => {
    return (
        <div className={classes.Container}>
            <div className={classes.SplitContainer}>
                <div className={classes.Profile}>
                    <div className={classes.LinearBackground} />
                </div>
                <div className={classes.Metadata}>
                    <div className={classes.TitleStrip}>
                        <div className={classes.LinearBackground} />
                    </div>
                    <div className={classes.Strip}>
                        <div className={classes.LinearBackground} />
                    </div>
                    <div className={classes.Strip}>
                        <div className={classes.LinearBackground} />
                    </div>
                    <div className={classes.Strip}>
                        <div className={classes.LinearBackground} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PlaceHolder;
