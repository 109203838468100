import React, {Component, createRef} from "react";
import classes from "./EditCompanyDetails.module.css";
import SubmitButton from "../../../../Forms/SubmitButton/SubmitButton";
import GlobalPopup from "../../../../Popup/GlobalPopup/GlobalPopup";
import AppContext from "../../../../../context/AppProvider";
import _ from "lodash";
import InputLabel from "../../../../Forms/Label/InputLabel";
import stepClasses from "../../../Styles/Steps.module.css";
import {BusinessStageFilterOptions} from "../../../../../constants/filteroptions/BusinessStageFilterOptions";
import SingleSelectCards from "../../../../Forms/Cards/SingleSelectCards";
import sharedClasses from "../../styles/EditableFieldStyles.module.css";
import MultiSelectCards from "../../../../Forms/Cards/MultiSelectCards";
import {FocusAreaFilterOptions} from "../../../../../constants/filteroptions/FocusAreaFilterOptions";
import {CompanySizeFilterOptions} from "../../../../../constants/filteroptions/CompanySizeFilterOptions";
import DatePicker from "../../../../Forms/DatePicker/DatePicker";
import {BusinessModelFilterOptions} from "../../../../../constants/filteroptions/BusinessModelFilterOptions";
import {TechnologyFilterOptions} from "../../../../../constants/filteroptions/TechnologyFilterOptions";
import {CloseIcon} from "../../../../Icons/CloseIcon";

class EditCompanyDetails extends Component {

    static contextType = AppContext;

    state = {
        isEditing: false,
        isSubmitting: false,
        wrapperRef: createRef(),
        focusAreas: !!this.props.profile.focusAreas ? this.props.profile.focusAreas : [],
        numEmployees: !!this.props.profile.numEmployees ? this.props.profile.numEmployees : [],
        foundedDateEpoch: this.props.profile.foundedDateEpoch,
        businessModels: !!this.props.profile.businessModels ? this.props.profile.businessModels : [],
        businessStage: this.props.profile.businessStage,
        technologies: !!this.props.profile.technologies ? this.props.profile.technologies : [],
        previousInvestors: !!this.props.profile.previousInvestors ? this.props.profile.previousInvestors : [],
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick);
        this.setState({
            businessStage: this.props.profile.businessStage,
        });
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick);
    }

    handleClick = (event) => {
        if (!!this.state.wrapperRef && !!this.state.wrapperRef.current && !this.state.wrapperRef.current.contains(event.target)) {
            this.closeEditModal();
        }
    }

    openEditModal = () => {
        this.setState({isEditing: true});
    }

    closeEditModal = () => {
        this.setState({isEditing: false, isSubmitting: false, newValue: null});
    }

    selectFocusAreas = (focusAreas) => {
        this.setState({focusAreas: focusAreas});
    };

    selectNumEmployees = (numEmployees) => {
        this.setState({numEmployees: numEmployees});
    };

    selectFoundedDateEpoch = (foundedDateEpoch) => {
        this.setState({foundedDateEpoch: foundedDateEpoch});
    };

    selectBusinessModels = (businessModels) => {
        this.setState({businessModels: businessModels});
    };

    selectBusinessStage = (businessStage) => {
        this.setState({businessStage: businessStage});
    };

    selectTechnologies = (technologies) => {
        this.setState({technologies: technologies});
    };

    selectPreviousInvestors = (previousInvestors) => {
        this.setState({previousInvestors: previousInvestors});
    };

    handleSave = async () => {
        this.setState({isSubmitting: true}, async () => {
            const updatedProfile = _.cloneDeep(this.props.profile);
            updatedProfile.focusAreas = this.state.focusAreas;
            updatedProfile.numEmployees = this.state.numEmployees;
            updatedProfile.foundedDateEpoch = this.state.foundedDateEpoch;
            updatedProfile.businessModels = this.state.businessModels;
            updatedProfile.businessStage = this.state.businessStage;
            updatedProfile.technologies = this.state.technologies;
            updatedProfile.previousInvestors = this.state.previousInvestors;
            await this.context.apiGatewayClient.updateProfile(updatedProfile);
            this.setState({
                isSubmitting: false,
                isEditing: false,
            });
            this.props.setProfile(updatedProfile);
        })
    }

    render() {
        return (
            <div>
                <div className={classes.EditIcon} onClick={this.openEditModal}>
                    Edit details
                </div>
                {this.state.isEditing &&
                <GlobalPopup onOutsideClick={this.closeEditModal}>
                    <div className={sharedClasses.PopupContainer} ref={this.state.wrapperRef}>
                        <div className={classes.Title}>
                            <h3>Edit focus areas</h3>
                        </div>
                        <div className={sharedClasses.ProfileEditPopup}>
                            <InputLabel required={true} className={stepClasses.InputLabel}>Select your focus areas</InputLabel>
                            <MultiSelectCards options={FocusAreaFilterOptions}
                                              setSelected={(selectedOptions) => this.selectFocusAreas(selectedOptions.map(option => option.id))}
                                              currentlySelected={FocusAreaFilterOptions.filter(option => this.state.focusAreas.find(focusArea => focusArea === option.id))}/>
                            <InputLabel required={true} className={stepClasses.InputLabel}>Employees</InputLabel>
                            <SingleSelectCards options={CompanySizeFilterOptions}
                                               setSelected={(option) => this.selectNumEmployees(option.id)}
                                               currentlySelected={CompanySizeFilterOptions.find(option => option.id === this.state.numEmployees)}/>
                            <InputLabel required={true} className={stepClasses.InputLabel}>Founding Date</InputLabel>
                            <DatePicker value={this.state.foundedDateEpoch} onChange={this.selectFoundedDateEpoch}
                                        placeholder={'When was your startup founded?'}/>
                            <InputLabel required={true} className={stepClasses.InputLabel}>Business Model</InputLabel>
                            <MultiSelectCards options={BusinessModelFilterOptions}
                                              setSelected={(selectedOptions) => this.selectBusinessModels(selectedOptions.map(option => option.id))}
                                              currentlySelected={BusinessModelFilterOptions.filter(option => this.state.businessModels.find(businessModel => businessModel === option.id))}/>
                            <InputLabel required={true} className={stepClasses.InputLabel}>Business Stage</InputLabel>
                            <SingleSelectCards options={BusinessStageFilterOptions}
                                               setSelected={(option) => this.selectBusinessStage(option.id)}
                                               currentlySelected={BusinessStageFilterOptions.find(option => option.id === this.state.businessStage)}/>
                            <InputLabel required={true} className={stepClasses.InputLabel}>Select technologies</InputLabel>
                            <MultiSelectCards options={TechnologyFilterOptions}
                                              setSelected={(selectedOptions) => this.selectTechnologies(selectedOptions.map(option => option.id))}
                                              currentlySelected={TechnologyFilterOptions.filter(option => this.state.technologies.find(technology => technology === option.id))}/>
                            {/*<InputLabel required={false} className={stepClasses.InputLabel}>Select from a investor*/}
                            {/*    already on ClimateHub or add your own</InputLabel>*/}
                            {/*<SearchBar onSubmit={this.onSubmit}*/}
                            {/*           onChange={this.onInputChange}*/}
                            {/*           suggestions={this.state.suggestions}*/}
                            {/*           onSuggestionSelect={this.onSuggestionSelect}*/}
                            {/*           value={this.state.input}/>*/}
                            {/*<div className={classes.PastInvestments}>*/}
                            {/*    <PastInvestments previousInvestors={this.state.previousInvestors}*/}
                            {/*                     removePastInvestment={this.removePastInvestment}/>*/}
                            {/*</div>*/}
                        </div>
                        <div className={sharedClasses.SubmitContainer}>
                            <SubmitButton className={classes.CancelOption} onClick={this.closeEditModal}>
                                Cancel
                            </SubmitButton>
                            <SubmitButton className={classes.SaveOption}
                                          onClick={this.handleSave}
                                          isSubmitting={this.state.isSubmitting}>
                                Save
                            </SubmitButton>
                        </div>
                    </div>
                </GlobalPopup>
                }
            </div>
        );
    }
}

const PastInvestments = (props) => {
    return !!props.previousInvestors ? props.previousInvestors.map(pastInvestment => {
        return (
            <div className={classes.InvestmentRange}>
                {pastInvestment}
                <div className={classes.RemoveInvestmentRange} onClick={() => props.removePastInvestment(pastInvestment)}>
                    <CloseIcon />
                </div>
            </div>
        );
    }) : null;
};

export default EditCompanyDetails;
