import React, {Component, createRef} from "react";
import classes from "./EditHeader.module.css";
import SubmitButton from "../../../Forms/SubmitButton/SubmitButton";
import GlobalPopup from "../../../Popup/GlobalPopup/GlobalPopup";
import AppContext from "../../../../context/AppProvider";
import _ from "lodash";
import InputLabel from "../../../Forms/Label/InputLabel";
import stepClasses from "../../Styles/Steps.module.css";
import BasicTextInput from "../../../Forms/Input/BasicTextInput";
import {InputType} from "../../../Forms/Input/InputTypes.constants";
import SingleSelectCards from "../../../Forms/Cards/SingleSelectCards";
import emailRegex from "email-regex";
import urlRegex from "url-regex";
import {FundingStageFilterOptions} from "../../../../constants/filteroptions/FundingStageFilterOptions";
import sharedClasses from "../styles/EditableFieldStyles.module.css";

class EditCompanyHeader extends Component {

    static contextType = AppContext;

    state = {
        isEditing: false,
        isSubmitting: false,
        wrapperRef: createRef(),
        name: this.props.profile.name,
        fundingStage: this.props.profile.fundingStage,
        location: this.props.profile.location,
        linkedIn: this.props.profile.linkedIn,
        email: this.props.profile.email,
        website: this.props.profile.website,
        twitter: this.props.profile.twitter,
        youtube: this.props.profile.youtube,
        facebook: this.props.profile.facebook
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick);
    }

    handleClick = (event) => {
        if (!!this.state.wrapperRef && !!this.state.wrapperRef.current && !this.state.wrapperRef.current.contains(event.target)) {
            this.closeEditModal();
        }
    }

    openEditModal = () => {
        this.setState({isEditing: true});
    }

    closeEditModal = () => {
        this.setState({isEditing: false, isSubmitting: false, newValue: null});
    }

    selectName = (name) => {
        this.setState({name: name});
    }

    selectFundingStage = (fundingStage) => {
        this.setState({fundingStage: fundingStage});
    }

    selectLocation = (location) => {
        this.setState({location: location});
    }

    selectTwitter = (twitter) => {
        this.setState({twitter: twitter});
    }

    selectFacebook = (facebook) => {
        this.setState({facebook: facebook});
    }

    selectYoutube = (youtube) => {
        this.setState({youtube: youtube});
    }

    selectLinkedIn = (linkedIn) => {
        this.setState({linkedIn: linkedIn});
    }

    selectEmail = (email) => {
        this.setState({email: email});
    }

    selectWebsite = (website) => {
        this.setState({website: website});
    }

    isEmailValid = (email) => {
        return !email || email.length === 0 || emailRegex({exact: true}).test(email);
    }

    isWebLinkValid = (webLink) => {
        return !webLink || webLink.length === 0 || urlRegex({exact: true, strict: false}).test(webLink);
    }

    canSubmit = () => {
        return (this.state.name !== this.props.profile.name
            || this.state.fundingStage !== this.props.profile.fundingStage
            || this.state.location !== this.props.profile.location
            || this.state.linkedIn !== this.props.profile.linkedIn
            || this.state.email !== this.props.profile.email
            || this.state.website !== this.props.profile.website
            || this.state.twitter !== this.props.profile.twitter
            || this.state.youtube !== this.props.profile.youtube
            || this.state.facebook !== this.props.profile.facebook)
            && (!!this.state.name && this.state.name.length > 0)
            && this.isEmailValid(this.state.email)
            && this.isWebLinkValid(this.state.linkedIn)
            && this.isWebLinkValid(this.state.website)
            && this.isWebLinkValid(this.state.twitter)
            && this.isWebLinkValid(this.state.youtube)
            && this.isWebLinkValid(this.state.facebook);
    }

    handleSave = async () => {
        if (this.canSubmit()) {
            this.setState({isSubmitting: true}, async () => {
                const updatedProfile = _.cloneDeep(this.props.profile);
                updatedProfile.name = this.state.name;
                updatedProfile.fundingStage = this.state.fundingStage;
                updatedProfile.location = this.state.location;
                updatedProfile.linkedIn = this.state.linkedIn;
                updatedProfile.email = this.state.email;
                updatedProfile.website = this.state.website;
                updatedProfile.twitter = this.state.twitter;
                updatedProfile.youtube = this.state.youtube;
                updatedProfile.facebook = this.state.facebook;
                await this.context.apiGatewayClient.updateProfile(updatedProfile);
                this.setState({
                    isSubmitting: false,
                    isEditing: false,
                });
                this.props.setProfile(updatedProfile);
            })
        }
    }

    render() {
        const canSubmit = this.canSubmit();
        return (
            <div>
                <div className={classes.EditIcon} onClick={this.openEditModal}>
                    Edit header
                </div>
                {this.state.isEditing &&
                <GlobalPopup onOutsideClick={this.closeEditModal}>
                    <div className={sharedClasses.PopupContainer} ref={this.state.wrapperRef}>
                        <div className={classes.Title}>
                            <h3>Edit basic details</h3>
                        </div>
                        <div className={sharedClasses.ProfileEditPopup}>
                            <InputLabel required={true} className={stepClasses.InputLabel}>Name</InputLabel>
                            <BasicTextInput value={this.state.name} onChange={this.selectName} placeholder={'Name'}/>

                            <InputLabel required={false} className={stepClasses.InputLabel}>Funding stage</InputLabel>
                            <SingleSelectCards options={FundingStageFilterOptions}
                                               setSelected={(option) => this.selectFundingStage(option.id)}
                                               currentlySelected={FundingStageFilterOptions.find(option => option.id === this.state.fundingStage)}/>

                            <InputLabel required={false} className={stepClasses.InputLabel}>Location</InputLabel>
                            <BasicTextInput value={this.state.location} onChange={this.selectLocation}
                                            placeholder={'Location'}/>

                            <InputLabel required={false} className={stepClasses.InputLabel}>Twitter</InputLabel>
                            <BasicTextInput value={this.state.twitter} type={InputType.WebLink}
                                            onChange={this.selectTwitter} placeholder={'Twitter'}/>

                            <InputLabel required={false} className={stepClasses.InputLabel}>Facebook</InputLabel>
                            <BasicTextInput value={this.state.facebook} type={InputType.WebLink}
                                            onChange={this.selectFacebook} placeholder={'Facebook'}/>

                            <InputLabel required={false} className={stepClasses.InputLabel}>Youtube</InputLabel>
                            <BasicTextInput value={this.state.youtube} type={InputType.WebLink}
                                            onChange={this.selectYoutube} placeholder={'Youtube'}/>

                            <InputLabel required={false} className={stepClasses.InputLabel}>LinkedIn</InputLabel>
                            <BasicTextInput value={this.state.linkedIn} type={InputType.WebLink}
                                            onChange={this.selectLinkedIn} placeholder={'LinkedIn'}/>

                            <InputLabel required={false} className={stepClasses.InputLabel}>Email</InputLabel>
                            <BasicTextInput value={this.state.email} type={InputType.Email} onChange={this.selectEmail}
                                            placeholder={'Email'}/>

                            <InputLabel required={false} className={stepClasses.InputLabel}>Website</InputLabel>
                            <BasicTextInput value={this.state.website} type={InputType.WebLink}
                                            onChange={this.selectWebsite} placeholder={'Website'}/>
                        </div>
                        <div className={sharedClasses.SubmitContainer}>
                            <SubmitButton className={classes.CancelOption} onClick={this.closeEditModal}>
                                Cancel
                            </SubmitButton>
                            <SubmitButton className={classes.SaveOption}
                                          isDisabled={!canSubmit}
                                          onClick={this.handleSave}
                                          isSubmitting={this.state.isSubmitting}>
                                Save
                            </SubmitButton>
                        </div>
                    </div>
                </GlobalPopup>
                }
            </div>
        );
    }
}

export default EditCompanyHeader;
