import React, {Component, createRef} from "react";
import classes from "./SearchFiltersScrollMenu.module.css";
import {AngleRightIcon} from "../../../Icons/AngleRightIcon";
import {AngleLeftIcon} from "../../../Icons/AngleLeftIcon";

class SearchFiltersScrollMenu extends Component {

    state = {
        isOverflow: false,
        atStart: false,
        atEnd: false,
        wrapperRef: createRef()
    }

    componentDidMount() {
        this.refreshScrollControls();

        window.addEventListener('resize', this.refreshScrollControls);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.refreshScrollControls);
    }

    refreshScrollControls = () => {
        this.handleScroll({target: this.state.wrapperRef.current});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps === this.props) {
            return;
        }
        this.refreshScrollControls();
    }

    handleScroll = (e) => {
        if (!e.target) {
            return;
        }

        if (e.target.scrollWidth - e.target.scrollLeft <= e.target.clientWidth + 5) {
            this.setState({atEnd: true});
        } else {
            this.setState({atEnd: false});
        }

        if (e.target.scrollLeft <= 5) {
            this.setState({atStart: true});
        } else {
            this.setState({atStart: false});
        }

        this.checkForOverflow();
    };

    checkForOverflow = () => {
        let childrenWidth = 0;
        for (let i = 0; i < this.state.wrapperRef.current.children.length; i++) {
            const childElt = this.state.wrapperRef.current.children[i];
            childrenWidth += childElt.clientWidth + this.getHorizontalMargin(childElt);
        }
        if (childrenWidth > this.state.wrapperRef.current.clientWidth) {
            this.setState({isOverflow: true});
        } else {
            this.setState({isOverflow: false});
        }
    }

    getHorizontalMargin = (elt) => {
        const marginLeft = parseInt(getComputedStyle(elt).marginLeft.match(/(.*)px/)[0]);
        const marginRight = parseInt(getComputedStyle(elt).marginRight.match(/(.*)px/)[0]);
        return marginLeft + marginRight;
    }

    scrollLeft = () => {
        this.state.wrapperRef.current.scrollBy({left:-150, behavior: "smooth"});
        this.refreshScrollControls();
    }

    scrollRight = () => {
        this.state.wrapperRef.current.scrollBy({left:150, behavior: "smooth"});
        this.refreshScrollControls();
    }

    render() {
        return (
            <div className={classes.ScrollMenu}>
                <div className={classes.ScrollMenuContainer} onScroll={this.handleScroll} ref={this.state.wrapperRef}>
                    {
                        this.props.filters.map(filter => {
                            const selectedOptionsForFilter = this.props.selectedFilters.find(selectedFilter => selectedFilter.id === filter.id).filterOptions;
                            const hasFiltersSelected = selectedOptionsForFilter.length > 0;
                            const isExpanded = this.props.expandedFilters.some(expandedFilter => expandedFilter.id === filter.id);
                            return (
                                <div
                                    className={isExpanded ? [classes.FilterHeader, classes.FilterHeaderSelected].join(' ') : classes.FilterHeader}
                                    onClick={() => this.props.toggleFilterSectionExpansion(filter)}>
                                    <div className={classes.FilterHeaderLabel}>
                                        {filter.displayName}
                                        {hasFiltersSelected
                                            ?
                                            <div className={classes.FilterCount}>
                                                {selectedOptionsForFilter.length}
                                            </div>
                                            : null}
                                    </div>
                                    {isExpanded &&
                                    <span className={classes.Caret}><i className="fas fa-caret-down"/></span>}
                                </div>
                            )
                        })
                    }
                    <div className={classes.FilterHeaderPseudo}>...</div>
                </div>
                {!this.state.atStart && this.state.isOverflow &&
                <div className={classes.CaretLeft} onClick={this.scrollLeft}>
                    <div className={classes.CaretLeftContent}>
                        <AngleLeftIcon />
                    </div>
                </div>
                }
                {!this.state.atEnd && this.state.isOverflow &&
                <div className={classes.CaretRight}>
                    <div className={classes.CaretRightContent} onClick={this.scrollRight}>
                        <AngleRightIcon />
                    </div>
                </div>
                }
            </div>
        );
    }
}

export default SearchFiltersScrollMenu;
