import React, {Component, createRef} from "react";
import classes from "./FeedPostOptions.module.css";
import {EditIcon} from "../../../Icons/EditIcon";


class FeedPostOptions extends Component {

    state = {
        expanded: false,
        wrapperRef: createRef()
    }

    toggle = (e) => {
        e.stopPropagation();
        this.setState({expanded: !this.state.expanded});
    }

    collapse = () => {
        this.setState({expanded: false});
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (this.state.wrapperRef && !this.state.wrapperRef.current.contains(event.target)) {
            this.setState({expanded: false});
        }
    }

    render() {
        return (
            <div className={classes.Container} ref={this.state.wrapperRef}>
                <div className={classes.ContainerInner}>
                    <div className={classes.DropdownToggler} onClick={this.toggle}>
                        <i className="fas fa-ellipsis-h" />
                    </div>

                    {this.state.expanded &&
                    <div className={classes.OptionsDropdown}>
                        <div className={classes.OptionsDropdownInner}>
                            <div className={classes.DropdownOption} onClick={e => {
                                e.stopPropagation();
                                this.collapse();
                                this.props.enterEditMode();
                            }}>
                                Edit <EditIcon />
                            </div>
                            <div className={classes.DropdownOption} onClick={e => {
                                e.stopPropagation();
                                this.collapse();
                                this.props.openDeleteFeedPostModal();
                            }}>
                                Delete <i className="fas fa-trash-alt"/>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        );
    }
}

export default FeedPostOptions;
