import React from "react";


const GetDescription = (tokenOffsets, description) => {
    let descriptionOffsets = tokenOffsets['description'];

    if (!!descriptionOffsets && descriptionOffsets.length > 0) {
        const splitDescription = description.split(/\s+/);
        if (description.length <= 350) {
            return <p>{splitDescription.map((token, idx) =>
                descriptionOffsets.includes(idx) ? <b>{token} </b> : token + ' '
            )}</p>
        } else {
            const criticalAreaSize = 40 / Math.min(2, descriptionOffsets.length); // take only up to 2 critical sections
            descriptionOffsets = descriptionOffsets.slice(0, 2);
            return <p>
                {descriptionOffsets.map(offset => GetSplit(splitDescription, offset, criticalAreaSize))}
            </p>
        }
    } else {
        return description && description.length > 350
            ? description.slice(0,350) + "..."
            : description;
    }
}

const GetSplit = (splitDescription, offset, criticalAreaSize) => {
    const leftOverBefore = Math.max(0, criticalAreaSize / 2 - offset);
    const leftOverAfter = Math.max(0, criticalAreaSize / 2 - (splitDescription.length - offset));
    const startIndex = Math.max(0, offset - criticalAreaSize / 2 - leftOverAfter);
    const endIndex = offset + criticalAreaSize / 2 + leftOverBefore;
    return <span>
        {startIndex > 0 ? '...' : ''}
        {splitDescription.slice(startIndex, offset).join(' ')}
        <b> {splitDescription[offset]} </b>
        {splitDescription.slice(offset + 1, endIndex).join(' ')}
        {endIndex < splitDescription.length ? '...' : ''}
    </span>
}

export default GetDescription;
