import React, {Component, Fragment} from "react";
import classes from './Description.module.css';

class Description extends Component {
    state = {
        isExpanded: false,
        isOverSized: false,
    };

    componentDidMount() {
        if (!!this.props.description && this.props.description.length > 950) {
            this.setState({isOverSized: true});
        }
    }

    toggleDescriptionExpansion = () => {
        this.setState({isExpanded: !this.state.isExpanded});
    };

    render() {
        const description = this.state.isExpanded || !this.state.isOverSized
            ? this.props.description
            : this.props.description.slice(0, 750) + '...';
        return (
            <div className={classes.DescriptionContainer}>
                <div>
                    <pre>
                        {description}
                    </pre>
                </div>
                <div className={classes.ExpandCollapseButtonContainer}>
                    <div className={this.state.isExpanded ? [classes.ExpandCollapseButton, classes.Expanded].join(' ') : classes.ExpandCollapseButton}
                         onClick={this.toggleDescriptionExpansion}>
                        {this.state.isOverSized &&
                            <Fragment>
                                {this.state.isExpanded ? 'Show less' : 'Show more'}
                                <i className="fas fa-angle-down"/>
                            </Fragment>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Description;
