import React, {Component, createRef} from "react";
import classes from "./InvestorQuestionnaire.module.css";
import SubmitButton from "../../Forms/SubmitButton/SubmitButton";
import sharedClasses from "../CreateProfile.module.css";
import ProfilePhotoWithDefault from "../../ProfilePage/ProfilePhotoWithDefault/ProfilePhotoWithDefault";
import Image from "../../../model/Image";
import InputLabel from "../../Forms/Label/InputLabel";
import stepClasses from "../../ProfilePage/Styles/Steps.module.css";
import BasicTextInput from "../../Forms/Input/BasicTextInput";

class BasicDetailsStep extends Component {

    state = {
        wrapperRef: createRef(),
        inputRef: createRef(),
    }
    
    handleInputClick = () => {
        this.state.inputRef.current.click();
    };

    handleUploadedImage = (e) => {
        const [file] = e.target.files;
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                this.props.setPhoto(new Image(file, event.target.result));
            };
            reader.readAsDataURL(file);
        }
    };

    render() {
        return (
            <div>
                <div className={sharedClasses.CreateProfileHeader}>Set the scene</div>
                <div className={sharedClasses.CreateProfileDescription}>Make a great first impression by adding a high quality photo, name and description</div>
                <div className={classes.Content}>
                    <div className={classes.BasicDetailsLeft}>
                        <div className={classes.ImageContainer}>
                            <div className={classes.ImageContainerInner}>
                                <ProfilePhotoWithDefault logo={!!this.props.photo ? this.props.photo.src : null} isUploadedLocally={true}/>
                                <div onClick={this.handleInputClick}  className={classes.ChangeButton}>
                                    <i className="fas fa-camera" />
                                </div>
                            </div>
                        </div>
                        <input className={sharedClasses.InvisibleInput} ref={this.state.inputRef} type="file"
                               accept={['.png', '.jpg']} multiple={false} onChange={this.handleUploadedImage}/>
                    </div>
                    <div className={classes.BasicDetailsRight}>
                        <InputLabel required={true} className={stepClasses.InputLabel}>Name</InputLabel>
                        <BasicTextInput value={this.props.name} onChange={this.props.setName} placeholder={'Name'}/>

                        <InputLabel required={false} className={stepClasses.InputLabel}>Location</InputLabel>
                        <BasicTextInput value={this.props.location} onChange={this.props.setLocation}
                                        placeholder={'Location'}/>
                    </div>
                </div>
                <div className={sharedClasses.Navigation}>
                    <SubmitButton onClick={this.props.previousStep} className={sharedClasses.PreviousButton}>Previous</SubmitButton>
                    <SubmitButton onClick={this.props.nextStep}
                                  isDisabled={!this.props.name}
                                  className={sharedClasses.NextButton}>Next</SubmitButton>
                </div>
                <div className={sharedClasses.PageDetailsContainer}>
                    3 of 4
                </div>
            </div>
        )
    }
}

export default BasicDetailsStep;
