import React, {Component} from 'react';
import classes from "./AddPhoto.module.css";
import Image from '../../../model/Image';

class EditPreviewPhoto extends Component {

    state = {
        inputRef: React.createRef()
    };

    handleEditClick = () => {
        this.state.inputRef.current.click();
    };

    handleUploadedImage = (e) => {
        const [file] = e.target.files;
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                this.props.handlePhotoAdded(new Image(file, event.target.result, true));
            };
            reader.readAsDataURL(file);
        }
    };

    render() {
        return (
            <div className={classes.Container}>
                <input ref={this.state.inputRef} type="file" accept={['.png', '.jpg']} multiple={false} onChange={this.handleUploadedImage}/>
                <div className={classes.PhotoContainer}>

                    {!!this.props.photo &&
                    <div className={classes.PhotoPreview}>
                        <img src={!!this.props.photo ? this.props.photo.src : null}/>
                    </div>
                    }

                    {!this.props.photo &&
                    <div>
                        <div className={classes.IconPrimary}>
                            <i className="far fa-image"/>
                        </div>
                        <div>
                            {this.props.displayText}
                        </div>
                    </div>
                    }
                    {!!this.props.photo &&
                    <div className={classes.OptionIcons}>
                        <div className={classes.OptionIcon} onClick={this.props.handlePhotoDelete}>
                            Delete <i className="fas fa-trash-alt"/>
                        </div>
                        <div className={classes.OptionIcon} onClick={this.handleEditClick}>
                            Edit <i className="fas fa-edit"/>
                        </div>
                    </div>
                    }
                </div>
            </div>
        );
    }
}

export default EditPreviewPhoto;
