import React, {Component} from "react";
import classes from "./CreateModal.module.css";
import {NavLink} from "react-router-dom";
import ProfilePhotoWithDefault from "../../ProfilePage/ProfilePhotoWithDefault/ProfilePhotoWithDefault";
import FeedSubmit from "../FeedPost/FeedSubmit/FeedSubmit";
import AppContext from "../../../context/AppProvider";

class CreateModal extends Component {

    static contextType = AppContext;

    state = {
        input: '',
        isFocused: false,
    }

    onFocusIn = () => {
        this.setState({isFocused: true});
    };

    onFocusOut = () => {
        this.setState({isFocused: false});
    };

    onInputChange = (e) => {
        this.setState({input: e.target.value});
    }

    onSubmit = (images, videos) => {
        const input = this.state.input;
        this.setState({input: ''});
        this.props.onSubmit(input, images, videos);
    }

    render() {
        return (
            <div className={classes.Container}>
                <div className={classes.Header}>
                    <NavLink to={`/profile/${this.context.currentProfile.profileId}`}>
                        <div className={classes.HeaderImage} >
                            <ProfilePhotoWithDefault logo={this.context.currentProfile.logo} name={this.context.currentProfile.name}/>
                        </div>
                    </NavLink>
                    <div>
                        <h3>{this.context.currentProfile.name}</h3>
                    </div>
                </div>
                <div className={classes.InputContainer}>
                    <div className={classes.InputContainerInner}>
                        <div className={classes.Input}>
                            <textarea maxLength="2000"
                                      placeholder={this.props.placeholder}
                                      onChange={this.onInputChange}
                                      value={this.state.input}
                                      onFocus={this.onFocusIn}
                                      onBlur={this.onFocusOut}/>
                        </div>
                        <FeedSubmit onSubmit={this.onSubmit}
                                    isDisabled={this.state.input.trim().length === 0}
                                    isSubmitting={this.props.isSubmitting}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default CreateModal;
