import React, {Component, Fragment} from "react";
import sharedClasses from "../../../../styles/FormStyles.module.css";
import AppContext from '../../../../context/AppProvider';
import TextInput from '../../../Forms/Input/TextInput';
import SubmitButton from "../../../Forms/SubmitButton/SubmitButton";
import {NavLink, withRouter} from "react-router-dom";
import {isValidEmail, isValidPassword} from "../../Auth.util";
import PopupContext from "../../../../context/PopupsProvider";

const cognitoDomainPrefix = 'climatehubdev';
const cognitoName = `https://${cognitoDomainPrefix}.auth.us-west-2.amazoncognito.com`;
const hostName = 'http://localhost:3000';
const cognitoClientId = '7srpm9gj9lvrtukfrt1v4kuvbf';
const redirectUrl = `${cognitoName}/oauth2/authorize?identity_provider=Google&redirect_uri=${hostName}/googleAccountRedirect&response_type=TOKEN&client_id=${cognitoClientId}&scope=aws.cognito.signin.user.admin email openid phone profile`

class SignUp extends Component {

    static contextType = AppContext;

    state = {
        email: '',
        password: '',
        confirmPassword: '',
        name: '',
        isSubmitting: false,
        isErrorSubmitMessageShowing: false,
        errors: [],
        confirmationEmailSent: false,
    };

    isValidConfirmPassword = (confirmPassword) => {
        return confirmPassword === this.state.password;
    };

    isValidName = (name) => {
        return !!name && name.length > 0;
    }

    onEmailInputChange = (event) => {
        if (isValidEmail(event.target.value)) {
            const errors = this.state.errors.filter(err => err !== 'email');
            this.setState({email: event.target.value, errors: errors});
        } else {
            this.setState({email: event.target.value});
        }
    };

    onPasswordInputChange = (event) => {
        if (isValidPassword(event.target.value)) {
            const errors = this.state.errors.filter(err => err !== 'password');
            this.setState({password: event.target.value, errors: errors});
        } else {
            this.setState({password: event.target.value});
        }
    };

    onConfirmPasswordInputChange = (event) => {
        if (this.isValidConfirmPassword(event.target.value)) {
            const errors = this.state.errors.filter(err => err !== 'confirmPassword');
            this.setState({confirmPassword: event.target.value, errors: errors});
        } else {
            this.setState({confirmPassword: event.target.value});
        }
    };

    onNameInputChange = (event) => {
        if (this.isValidName(event.target.value)) {
            const errors = this.state.errors.filter(err => err !== 'name');
            this.setState({name: event.target.value, errors: errors});
        } else {
            this.setState({name: event.target.value});
        }
    }

    handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            this.handleSubmit();
        }
    };

    hasError = (error) => {
        return this.state.errors.filter(err => err === error).length > 0;
    };

    handleSubmit = () => {
        const email = this.state.email;
        const password = this.state.password;
        const confirmPassword = this.state.confirmPassword;
        const name = this.state.name;
        const errors = [];
        if (!isValidEmail(email)) {
            errors.push('email');
        }
        if (!isValidPassword(password)) {
            errors.push('password');
        }
        if (!this.isValidConfirmPassword(confirmPassword)) {
            errors.push('confirmPassword');
        }
        if (!this.isValidName(name)) {
            errors.push('name');
        }

        if (errors.length > 0) {
            this.setState({errors: errors});
        } else {
            this.setState({isSubmitting: true}, () => {
                this.context.apiGatewayClient.signUp(email, password, name).then(res => {
                    this.setState({isSubmitting: false, confirmationEmailSent: true});
                }).catch(() => {
                    this.setState({isSubmitting: false, isErrorSubmitMessageShowing: true});
                });
            });
        }
    };

    resendConfirmationCode = () => {
        const email = this.state.email;
        this.setState({isSubmitting: true});
        this.context.apiGatewayClient.resendConfirmationCode(email).then(() => {
            this.setState({isSubmitting: false})
        })
    }

    render() {
        return (
            <PopupContext.Consumer>
                {popupContext => (
                    <Fragment>
                        {!this.state.confirmationEmailSent &&
                        <div className={sharedClasses.FormInner}>
                            <TextInput error={this.hasError('name') ? 'You must specify a valid email' : null}
                                       label="Name"
                                       icon="fas fa-user"
                                       type="text"
                                       value={this.state.name}
                                       onKeyDown={this.handleKeyDown}
                                       onChange={this.onNameInputChange}/>
                            <TextInput error={this.hasError('email') ? 'You must specify a valid email' : null}
                                       label="Email"
                                       icon="fas fa-envelope"
                                       type="email"
                                       value={this.state.email}
                                       onKeyDown={this.handleKeyDown}
                                       onChange={this.onEmailInputChange}/>
                            <TextInput error={this.hasError('password') ? 'Password must contain at least 8 characters' : null}
                                       label="Password"
                                       icon="fas fa-lock"
                                       type="password"
                                       value={this.state.password}
                                       onKeyDown={this.handleKeyDown}
                                       onChange={this.onPasswordInputChange}/>
                            <TextInput error={this.hasError('confirmPassword') ? 'Confirmed password must match' : null}
                                       label="Confirm Password"
                                       icon="fas fa-lock"
                                       type="password"
                                       value={this.state.confirmPassword}
                                       onKeyDown={this.handleKeyDown}
                                       onChange={this.onConfirmPasswordInputChange}/>
                            <div className={sharedClasses.SubmitButtonWrapper}>
                                <SubmitButton isSubmitting={this.state.isSubmitting}
                                              onClick={this.handleSubmit}
                                              isFullWidth={true}
                                              disabled={this.state.isSubmitting}>
                                    {this.state.isSubmitting ? 'Creating account...' : 'Join'}
                                </SubmitButton>
                            </div>
                            {/*<div className={sharedClasses.SubmitButtonWrapper}>*/}
                            {/*    <SubmitButton onClick={() => window.open(redirectUrl)}*/}
                            {/*                  isFullWidth={true}*/}
                            {/*                  className={sharedClasses.GoogleButton}>*/}
                            {/*        <GoogleIcon /> Continue with Google*/}
                            {/*    </SubmitButton>*/}
                            {/*</div>*/}
                            {this.state.isErrorSubmitMessageShowing &&
                            <div className={sharedClasses.Error}>
                                <i className="fas fa-exclamation-circle"/> An account already exists with the given email.
                            </div>
                            }
                        </div>
                        }
                        {this.state.confirmationEmailSent &&
                        <div>
                            An email has been sent to your address. Please open the email and click the link to confirm your
                            account.
                            <div className={sharedClasses.SubmitButtonWrapper}>
                                <SubmitButton isSubmitting={this.state.isSubmitting}
                                              onClick={this.resendConfirmationCode}
                                              isFullWidth={true}
                                              disabled={this.state.isSubmitting}>
                                    Resend link
                                </SubmitButton>
                            </div>
                        </div>
                        }
                        <div className={sharedClasses.TermsAndConditions}>
                            <div className={sharedClasses.SwitchPopupCenter}>
                                By clicking Join, you agree to our
                                <NavLink className={sharedClasses.LabelUnderline} to='/terms' target='_blank'>Terms of Service</NavLink> and
                                <NavLink className={sharedClasses.LabelUnderline} to='/privacy' target='_blank'>Privacy Policy</NavLink>
                            </div>
                        </div>
                        <div className={sharedClasses.SwitchPopup}>
                            <div className={sharedClasses.SwitchPopupCenter}>
                                Already have an account?<span className={sharedClasses.LabelUnderline} onClick={popupContext.openSignInPopUp}> Sign in</span>
                            </div>
                        </div>
                    </Fragment>
                )}
            </PopupContext.Consumer>
        );
    }
}

export default withRouter(SignUp);
