import React, {Component} from 'react';
import AppContext from '../../context/AppProvider';
import ProfileTypeStep from './PrerequisiteSteps/ProfileTypeStep';
import classes from './CreateProfile.module.css'
import GlobalPopup from "../Popup/GlobalPopup/GlobalPopup";
import {withRouter} from "react-router-dom";
import {ProfileType} from "../../constants/ProfileTypeConstants";
import InvestorQuestionnaire from "./InvestorSteps/InvestorQuestionnaire";
import StartupQuestionnaire from "./StartupSteps/StartupQuestionnaire";
import {CloseIcon} from "../Icons/CloseIcon";
import PopupContext from "../../context/PopupsProvider";


class CreateProfile extends Component {

    static contextType = AppContext;

    state = {
        profileType: null,
    };

    selectProfileType = (type) => {
        this.setState({profileType: type});
    };

    render() {
        return (
            <PopupContext.Consumer>
                {popupContext => (
                    <GlobalPopup onOusideClick={popupContext.closeCreateProfilePopUp}>
                        <div className={classes.Container}>
                            <button className={classes.Exit} onClick={popupContext.closeCreateProfilePopUp}>
                                <CloseIcon />
                            </button>
                            {!this.state.profileType &&
                            <ProfileTypeStep
                                profileType={this.state.profileType}
                                selectProfileType={this.selectProfileType}/>
                            }
                            {this.state.profileType === ProfileType.Investor &&
                            <InvestorQuestionnaire clearProfileType={() => this.selectProfileType(null)}
                                                   closePopUp={popupContext.closeCreateProfilePopUp}/>
                            }
                            {this.state.profileType === ProfileType.Startup &&
                            <StartupQuestionnaire clearProfileType={() => this.selectProfileType(null)}
                                                  closePopUp={popupContext.closeCreateProfilePopUp}/>
                            }
                        </div>
                    </GlobalPopup>
                )}
            </PopupContext.Consumer>
        );
    }
}

export default withRouter(CreateProfile);

