export const InvestorTypeFilterOptions = [
    {
        id: 'INDIVIDUAL',
        displayName: 'Individual',
        badgeColor: '#F8F9FB'
    },
    {
        id: 'VENTURE_CAPITAL',
        displayName: 'Venture Capital',
        badgeColor: '#F8F9FB'
    },
    {
        id: 'NON_PROFIT',
        displayName: 'Non-Profit',
        badgeColor: '#F8F9FB'
    },
    {
        id: 'PUBLIC_FUNDING',
        displayName: 'Public Funding',
        badgeColor: '#F8F9FB'
    },
    {
        id: 'CORPORATION',
        displayName: 'Corporation',
        badgeColor: '#F8F9FB'
    },
    {
        id: 'ACCELERATOR',
        displayName: 'Accelerator',
        badgeColor: '#F8F9FB'
    }
];

