import React, {Component, createRef} from "react";
import classes from '../Legal.module.css';
import sharedClasses from '../../../styles/Styles.module.css';

class TermsOfService extends Component {

    state = {
        introRef: createRef(),
        communicationsRef: createRef(),
        contentRef: createRef(),
        prohibitedRef: createRef(),
        minorsRef: createRef(),
        accountsRef: createRef(),
        intellectualPropertyRef: createRef(),
        copyrightRef: createRef(),
        dmcaRef: createRef(),
        feedbackRef: createRef(),
        linksRef: createRef(),
        limitationRef: createRef(),
        terminationRef: createRef(),
        governingRef: createRef(),
        changesRef: createRef(),
        amendmentsRef: createRef(),
        waiverRef: createRef(),
        acknowledgementRef: createRef(),
        contactRef: createRef()
    }

    scrollIntoView = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    }

    render() {
        return (
            <div className={sharedClasses.ContentContainer}>
                <div className={sharedClasses.ContentContainerInner}>
                    <div className={classes.TableOfContents}>
                        <div className={classes.Link} onClick={() => this.scrollIntoView(this.state.introRef)}>1. Introduction</div>
                        <div className={classes.Link} onClick={() => this.scrollIntoView(this.state.communicationsRef)}>2. Communications</div>
                        <div className={classes.Link} onClick={() => this.scrollIntoView(this.state.contentRef)}>3. Content</div>
                        <div className={classes.Link} onClick={() => this.scrollIntoView(this.state.prohibitedRef)}>4. Prohibited Uses</div>
                        <div className={classes.Link} onClick={() => this.scrollIntoView(this.state.minorsRef)}>5. No Use By Minors</div>
                        <div className={classes.Link} onClick={() => this.scrollIntoView(this.state.accountsRef)}>6. Accounts</div>
                        <div className={classes.Link} onClick={() => this.scrollIntoView(this.state.intellectualPropertyRef)}>7. Intellectual Property</div>
                        <div className={classes.Link} onClick={() => this.scrollIntoView(this.state.copyrightRef)}>8. Copyright Policy</div>
                        <div className={classes.Link} onClick={() => this.scrollIntoView(this.state.dmcaRef)}>9. DMCA Notice and Procedure for Copyright Infringement Claims</div>
                        <div className={classes.Link} onClick={() => this.scrollIntoView(this.state.feedbackRef)}>10. Error Reporting and Feedback</div>
                        <div className={classes.Link} onClick={() => this.scrollIntoView(this.state.linksRef)}>11. Links To Other Web Sites</div>
                        <div className={classes.Link} onClick={() => this.scrollIntoView(this.state.limitationRef)}>12. Limitation of Liability</div>
                        <div className={classes.Link} onClick={() => this.scrollIntoView(this.state.terminationRef)}>13. Termination</div>
                        <div className={classes.Link} onClick={() => this.scrollIntoView(this.state.governingRef)}>14. Governing Law</div>
                        <div className={classes.Link} onClick={() => this.scrollIntoView(this.state.changesRef)}>15. Changes to Service</div>
                        <div className={classes.Link} onClick={() => this.scrollIntoView(this.state.amendmentsRef)}>16. Amendments To Terms</div>
                        <div className={classes.Link} onClick={() => this.scrollIntoView(this.state.waiverRef)}>17. Waiver And Severability</div>
                        <div className={classes.Link} onClick={() => this.scrollIntoView(this.state.acknowledgementRef)}>18. Acknowledgement</div>
                        <div className={classes.Link} onClick={() => this.scrollIntoView(this.state.contactRef)}>19. Contact Us</div>
                    </div>
                    <div className={classes.Content}>

                        <h2>
                            Terms of Service
                        </h2>

                        <div>
                            Effective date: 04/22/2021
                        </div>

                        <div className={classes.Section} ref={this.state.introRef}>
                            <h3>1. Introduction</h3>
                            <pre>
                                <div>
                                    Welcome to <b>ClimateHub, LLC</b> (“<b>Company</b>”, “<b>we</b>”, “<b>our</b>”, “<b>us</b>”)! As you have just clicked our
                                    Terms of Service, please pause, grab a cup of coffee and carefully read the following pages.
                                    It will take you approximately 20 minutes.
                                </div>
                                <div>
                                    These Terms of Service (“<b>Terms</b>”, “<b>Terms of Service</b>”) govern your use of our web
                                    pages located at https://climatehub.io/ operated by ClimateHub, LLC.
                                </div>
                                <div>
                                    Our Privacy Policy also governs your use of our Service and explains how we collect,
                                    safeguard and disclose information that results from your use of our web pages.
                                    Please read it here https://climatehub.io/privacy.
                                </div>

                                <div>
                                    Your agreement with us includes these Terms and our Privacy Policy (“<b>Agreements</b>”).
                                    You acknowledge that you have read and understood Agreements, and agree to be bound by them.
                                </div>
                                <div>
                                    If you do not agree with (or cannot comply with) Agreements, then you may not use the
                                    Service, but please let us know by emailing at hello@climatehub.io so we can try to
                                    find a solution. These Terms apply to all visitors, users and others who wish to
                                    access or use Service.
                                </div>
                                <div>
                                    Thank you for being responsible.
                                </div>
                            </pre>
                        </div>

                        <div className={classes.Section} ref={this.state.communicationsRef}>
                            <h3>2. Communications</h3>
                            <pre>
                                By creating an Account on our Service, you agree to subscribe to newsletters, marketing or promotional materials and other information we may send. However, you may opt out of receiving any, or all, of these communications from us by emailing us at  hello@climatehub.io.
                            </pre>
                        </div>

                        <div className={classes.Section} ref={this.state.contentRef}>
                            <h3>3. Content</h3>
                            <pre>
                                <div>
                                    Our Service allows you to post, link, store, share and otherwise make available
                                    certain information, text, graphics, videos, or other material (“Content”).
                                    You are responsible for Content that you post on or through Service, including
                                    its legality, reliability, and appropriateness.
                                </div>
                                <div>
                                    By posting Content on or through Service, You represent and warrant that: (i)
                                    Content is yours (you own it) and/or you have the right to use it and the right to
                                    grant us the rights and license as provided in these Terms, and (ii) that the
                                    posting of your Content on or through Service does not violate the privacy rights,
                                    publicity rights, copyrights, contract rights or any other rights of any person or
                                    entity. We reserve the right to terminate the account of anyone found to be
                                    infringing on a copyright.
                                </div>
                                <div>
                                    You retain any and all of your rights to any Content you submit, post or display on
                                    or through Service and you are responsible for protecting those rights. We take no
                                    responsibility and assume no liability for Content you or any third party posts
                                    on or through Service. However, by posting Content using Service you grant us the
                                    right and license to use, modify, publicly perform, publicly display, reproduce,
                                    and distribute such Content on and through Service. You agree that this license
                                    includes the right for us to make your Content available to other users of Service,
                                    who may also use your Content subject to these Terms.
                                </div>
                                <div>
                                    ClimateHub, LLC has the right but not the obligation to monitor and edit all Content provided by users.
                                </div>
                                <div>
                                    In addition, Content found on or through this Service are the property of ClimateHub,
                                    LLC. You may not distribute, modify, transmit, reuse, download, repost, copy, or
                                    use said Content, whether in whole or in part, for commercial purposes or for personal
                                    gain, without express advance written permission from us.
                                </div>
                            </pre>
                        </div>

                        <div className={classes.Section} ref={this.state.prohibitedRef}>
                            <h3>4. Prohibited Uses</h3>
                            <pre>
                                <div>
                                    You may use Service only for lawful purposes and in accordance with Terms.
                                    You agree not to use Service:<br />
                                    (a) In any way that violates any applicable national or international law or regulation.<br />
                                    (b) For the purpose of exploiting, harming, or attempting to exploit or harm minors
                                    in any way by exposing them to inappropriate content or otherwise.<br />
                                    (c) To transmit, or procure the sending of, any advertising or promotional material,
                                    including any “junk mail”, “chain letter”, “spam”, or any other similar solicitation.<br />
                                    (d) To impersonate or attempt to impersonate Company, a Company employee, another user, or any other person or entity.<br />
                                    (e) In any way that infringes upon the rights of others, or in any way is illegal,
                                    threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.<br />
                                    (f) To engage in any other conduct that restricts or inhibits anyone’s use or
                                    enjoyment of Service, or which, as determined by us, may harm or offend Company or users of Service or expose them to liability.<br />
                                </div>
                                <div>
                                    Additionally, you agree not to:<br />
                                    (a) Use Service in any manner that could disable, overburden, damage, or impair Service or interfere with any other party’s use of Service, including their ability to engage in real time activities through Service.
                                    (b) Use any robot, spider, or other automatic device, process, or means to access Service for any purpose, including monitoring or copying any of the material on Service.
                                    (c) Use any manual process to monitor or copy any of the material on Service or for any other unauthorized purpose without our prior written consent.
                                    (d) Use any device, software, or routine that interferes with the proper working of Service.
                                    (e) Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.
                                    (f) Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of Service, the server on which Service is stored, or any server, computer, or database connected to Service.
                                    (g) Attack Service via a denial-of-service attack or a distributed denial-of-service attack.
                                    (h) Take any action that may damage or falsify Company rating.
                                    (i) Otherwise attempt to interfere with the proper working of Service.
                                </div>
                            </pre>
                        </div>

                        <div className={classes.Section} ref={this.state.minorsRef}>
                            <h3>5. No Use By Minors</h3>
                            <pre>
                                Service is intended only for access and use by individuals at least eighteen (18) years old. By accessing or using any of ClimateHub, LLC, you warrant and represent that you are at least eighteen (18) years of age and with the full authority, right, and capacity to enter into this agreement and abide by all of the terms and conditions of Terms. If you are not at least eighteen (18) years old, you are prohibited from both the access and usage of Service.
                            </pre>
                        </div>

                        <div className={classes.Section} ref={this.state.accountsRef}>
                            <h3>6. Accounts</h3>
                            <pre>
                                <div>
                                    When you create an account with us, you guarantee that you are above the age of 18,
                                    and that the information you provide us is accurate, complete, and current at all times.
                                    Inaccurate, incomplete, or obsolete information may result in the immediate termination
                                    of your account on Service.
                                </div>
                                <div>
                                    You are responsible for maintaining the confidentiality of your account and password,
                                    including but not limited to the restriction of access to your computer and/or account.
                                    You agree to accept responsibility for any and all activities or actions that occur
                                    under your account and/or password, whether your password is with our Service or a
                                    third-party service. You must notify us immediately upon becoming aware of any
                                    breach of security or unauthorized use of your account.
                                </div>
                                <div>
                                    You may not use as a username the name of another person or entity or that is not
                                    lawfully available for use, a name or trademark that is subject to any rights of
                                    another person or entity other than you, without appropriate authorization.
                                    You may not use as a username any name that is offensive, vulgar or obscene.
                                </div>
                                <div>
                                    We reserve the right to refuse service, terminate accounts, remove or edit content,
                                    or cancel orders in our sole discretion.
                                </div>
                            </pre>
                        </div>

                        <div className={classes.Section} ref={this.state.intellectualPropertyRef}>
                            <h3>7. Intellectual Property</h3>
                            <pre>
                                Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of ClimateHub, LLC and its licensors. Service is protected by copyright, trademark, and other laws of the United States. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of ClimateHub, LLC.
                            </pre>
                        </div>

                        <div className={classes.Section} ref={this.state.copyrightRef}>
                            <h3>8. Copyright Policy</h3>
                            <pre>
                                <div>
                                    We respect the intellectual property rights of others. It is our policy to respond
                                    to any claim that Content posted on Service infringes on the copyright or other
                                    intellectual property rights (“Infringement”) of any person or entity.
                                </div>
                                <div>
                                    If you are a copyright owner, or authorized on behalf of one, and you believe that
                                    the copyrighted work has been copied in a way that constitutes copyright infringement,
                                    please submit your claim via email to hello@climatehub.io, with the subject line:
                                    “Copyright Infringement” and include in your claim a detailed description of the alleged
                                    Infringement as detailed below, under “DMCA Notice and Procedure for Copyright Infringement Claims”
                                </div>
                                <div>
                                    You may be held accountable for damages (including costs and attorneys' fees) for
                                    misrepresentation or bad-faith claims on the infringement of any Content found on
                                    and/or through Service on your copyright.
                                </div>
                            </pre>
                        </div>

                        <div className={classes.Section} ref={this.state.dmcaRef}>
                            <h3>9. DMCA Notice and Procedure for Copyright Infringement Claims</h3>
                            <pre>
                                <div>
                                    You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA)
                                    by providing our Copyright Agent with the following information in writing
                                    (see 17 U.S.C 512(c)(3) for further detail):
                                    (a) an electronic or physical signature of the person authorized to act on behalf of
                                    the owner of the copyright's interest;<br />
                                    (b) a description of the copyrighted work that you claim has been infringed, including
                                    the URL (i.e., web page address) of the location where the copyrighted work exists
                                    or a copy of the copyrighted work;<br />
                                    (c) identification of the URL or other specific location on Service where the
                                    material that you claim is infringing is located;<br />
                                    (d) your address, telephone number, and email address;<br />
                                    (e) a statement by you that you have a good faith belief that the disputed use is
                                    not authorized by the copyright owner, its agent, or the law;<br />
                                    (f) a statement by you, made under penalty of perjury, that the above information
                                    in your notice is accurate and that you are the copyright owner or authorized to
                                    act on the copyright owner's behalf.<br />
                                </div>
                                <div>
                                    You can contact our Copyright Agent via email at hello@climatehub.io
                                </div>
                            </pre>
                        </div>

                        <div className={classes.Section} ref={this.state.feedbackRef}>
                            <h3>10. Error Reporting and Feedback</h3>
                            <pre>
                                You may provide us directly at hello@climatehub.io with information and feedback
                                concerning errors, suggestions for improvements, ideas, problems, complaints, and other
                                matters related to our Service (“<b>Feedback</b>”). You acknowledge and agree that:
                                (i) you shall not retain, acquire or assert any intellectual property right or other
                                right, title or interest in or to the Feedback; (ii) Company may have development
                                ideas similar to the Feedback; (iii) Feedback does not contain confidential information
                                or proprietary information from you or any third party; and (iv) Company is not
                                under any obligation of confidentiality with respect to the Feedback. In the event the
                                transfer of the ownership to the Feedback is not possible due to applicable mandatory
                                laws, you grant ClimateHub, LLC and its affiliates an exclusive, transferable,
                                irrevocable, free-of-charge, sub-licensable, unlimited and perpetual right to use
                                (including copy, modify, create derivative works, publish, distribute and commercialize)
                                Feedback in any manner and for any purpose.
                            </pre>
                        </div>

                        <div className={classes.Section} ref={this.state.linksRef}>
                            <h3>11. Links To Other Web Sites</h3>
                            <pre>
                                <div>
                                    Our Service may contain links to third party web sites or services that are not
                                    owned or controlled by ClimateHub, LLC.
                                </div>
                                <div>
                                    ClimateHub, LLC has no control over, and assumes no responsibility for the content,
                                    privacy policies, or practices of any third party web sites or services. We do not
                                    warrant the offerings of any of these entities/individuals or their websites.
                                </div>
                                <div>
                                    YOU ACKNOWLEDGE AND AGREE THAT CLIMATEHUB, LLC SHALL NOT BE RESPONSIBLE OR LIABLE,
                                    DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY
                                    OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES
                                    AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY WEB SITES OR SERVICES.
                                </div>
                                <div>
                                    WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES OF ANY
                                    THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.
                                </div>
                            </pre>
                        </div>

                        <div className={classes.Section} ref={this.state.limitationRef}>
                            <h3>12. Limitation Of Liability</h3>
                            <pre>
                                EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES (INCLUDING ATTORNEYS' FEES AND ALL RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF CLIMATEHUB, LLC, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
                            </pre>
                        </div>

                        <div className={classes.Section} ref={this.state.terminationRef}>
                            <h3>13. Termination</h3>
                            <pre>
                                <div>
                                    We may terminate or suspend your account and bar access to Service immediately,
                                    without prior notice or liability, under our sole discretion, for any reason
                                    whatsoever and without limitation, including but not limited to a breach of Terms.
                                </div>
                                <div>
                                    If you wish to terminate your account, you may simply discontinue using Service or
                                    request for account deletion by emailing hello@climatehub.io.
                                </div>
                                <div>
                                    All provisions of Terms which by their nature should survive termination shall
                                    survive termination, including, without limitation, ownership provisions, warranty
                                    disclaimers, indemnity and limitations of liability.
                                </div>
                            </pre>
                        </div>

                        <div className={classes.Section} ref={this.state.governingRef}>
                            <h3>14. Governing Law</h3>
                            <pre>
                                <div>
                                    These Terms shall be governed and construed in accordance with the laws of State of
                                    Delaware without regard to its conflict of law provisions.
                                </div>
                                <div>
                                    Our failure to enforce any right or provision of these Terms will not be considered a
                                    waiver of those rights. If any provision of these Terms is held to be invalid or
                                    unenforceable by a court, the remaining provisions of these Terms will remain in
                                    effect. These Terms constitute the entire agreement between us regarding our Service
                                    and supersede and replace any prior agreements we might have had between us regarding Service.
                                </div>
                            </pre>
                        </div>

                        <div className={classes.Section} ref={this.state.changesRef}>
                            <h3>15. Changes To Service</h3>
                            <pre>
                                We reserve the right to withdraw or amend our Service, and any service or material we provide via Service, in our sole discretion without notice. We will not be liable if for any reason all or any part of Service is unavailable at any time or for any period. From time to time, we may restrict access to some parts of Service, or the entire Service, to users, including registered users.
                            </pre>
                        </div>

                        <div className={classes.Section} ref={this.state.amendmentsRef}>
                            <h3>16. Amendments To Terms</h3>
                            <pre>
                                <div>
                                    We may amend Terms at any time by posting the amended terms on this site.
                                    It is your responsibility to review these Terms periodically.
                                </div>
                                <div>
                                    Your continued use of the Platform following the posting of revised Terms means that
                                    you accept and agree to the changes. You are expected to check this page frequently
                                    so you are aware of any changes, as they are binding on you. We will notify you
                                    via email with any updates to the Terms of Service and Privacy Policy.
                                </div>
                                <div>
                                    By continuing to access or use our Service after any revisions become effective,
                                    you agree to be bound by the revised terms. If you do not agree to the new terms,
                                    you are no longer authorized to use Service.
                                </div>
                            </pre>
                        </div>

                        <div className={classes.Section} ref={this.state.waiverRef}>
                            <h3>17. Waiver And Severability</h3>
                            <pre>
                                <div>
                                    No waiver by ClimateHub, LLC of any term or condition set forth in Terms shall be
                                    deemed a further or continuing waiver of such term or condition or a waiver of any
                                    other term or condition, and any failure of ClimateHub, LLC to assert a right or
                                    provision under Terms shall not constitute a waiver of such right or provision.
                                </div>
                                <div>
                                    If any provision of Terms is held by a court or other tribunal of competent jurisdiction
                                    to be invalid, illegal or unenforceable for any reason, such provision shall be
                                    eliminated or limited to the minimum extent such that the remaining provisions of
                                    Terms will continue in full force and effect.
                                </div>
                            </pre>
                        </div>

                        <div className={classes.Section} ref={this.state.acknowledgementRef}>
                            <h3>18. Acknowledgement</h3>
                            <pre>
                                BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.
                            </pre>
                        </div>

                        <div className={classes.Section} ref={this.state.contactRef}>
                            <h3>19. Contact Us</h3>
                            <pre>
                                <div>
                                    Please send your feedback, comments, requests for technical support:
                                </div>
                                <div>
                                    By email: hello@climatehub.io.
                                </div>
                            </pre>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TermsOfService;
