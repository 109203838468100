import React, {Component} from 'react';
import classes from "./CompanyInformation.module.css";
import sharedClasses from '../Styles/SharedStyles.module.css';
import Description from '../Description/Description';
import {CompanySizeFilterOptions} from '../../../constants/filteroptions/CompanySizeFilterOptions';
import {FocusAreaFilterOptions} from "../../../constants/filteroptions/FocusAreaFilterOptions";
import moment from 'moment';
import {withRouter} from "react-router-dom";
import EditOverview from "../EditableField/Overview/EditOverview";
import {BusinessModelFilterOptions} from "../../../constants/filteroptions/BusinessModelFilterOptions";
import {BusinessStageFilterOptions} from "../../../constants/filteroptions/BusinessStageFilterOptions";
import {TechnologyFilterOptions} from "../../../constants/filteroptions/TechnologyFilterOptions";
import EditCompanyDetails from "../EditableField/CompanyInformation/Details/EditCompanyDetails";

class CompanyInformation extends Component {

    render() {
        return (
            <div className={sharedClasses.Container}>
                {this.props.canEdit &&
                <div className={classes.EditIconContainer}>
                    <EditOverview profile={this.props.profile}
                                  setProfile={this.props.setProfile}
                                  title={'Overview'}/>
                </div>
                }
                <div className={sharedClasses.Section}>
                    <Description description={this.props.profile.description}/>
                </div>
                {this.props.canEdit &&
                <div className={classes.EditIconContainer}>
                    <EditCompanyDetails profile={this.props.profile}
                                        setProfile={this.props.setProfile}/>
                </div>
                }
                {!!this.props.profile.focusAreas && this.props.profile.focusAreas.length > 0 &&
                <div className={sharedClasses.SplitSection}>
                    <div className={sharedClasses.SplitSectionLeft}>
                        <h3>Focus Areas</h3>
                    </div>
                    <div className={sharedClasses.SplitSectionRight}>
                        <div className={sharedClasses.IconsContainer}>
                            {this.props.profile.focusAreas && this.props.profile.focusAreas.map(focusArea =>
                                FocusAreaFilterOptions.find(f => f.id === focusArea).displayName
                            ).join(', ')
                            }
                        </div>
                    </div>
                </div>
                }
                {!!this.props.profile.numEmployees &&
                <div className={sharedClasses.SplitSection}>
                    <div className={sharedClasses.SplitSectionLeft}>
                        <h3>Number of employees</h3>
                    </div>
                    <div className={sharedClasses.SplitSectionRight}>
                        {!!this.props.profile && this.props.profile.numEmployees &&
                        CompanySizeFilterOptions.find(size => size.id === this.props.profile.numEmployees).displayName
                        }
                    </div>
                </div>
                }
                {!!this.props.profile.foundedDateEpoch &&
                <div className={sharedClasses.SplitSection}>
                    <div className={sharedClasses.SplitSectionLeft}>
                        <h3>Founded Date</h3>
                    </div>
                    <div className={sharedClasses.SplitSectionRight}>
                        {!!this.props.profile.foundedDateEpoch &&
                        <div>
                            {`${moment.unix(this.props.profile.foundedDateEpoch / 1000).format('MMMM, YYYY')}`}
                        </div>
                        }
                    </div>
                </div>
                }
                {!!this.props.profile.businessModels && this.props.profile.businessModels.length > 0 &&
                <div className={sharedClasses.SplitSection}>
                    <div className={sharedClasses.SplitSectionLeft}>
                        <h3>Business Model</h3>
                    </div>
                    <div className={sharedClasses.SplitSectionRight}>
                        <div className={sharedClasses.IconsContainer}>
                            {!!this.props.profile.businessModels &&
                            this.props.profile.businessModels.map(businessModel =>
                                BusinessModelFilterOptions.find(businessModelFilterOption => businessModelFilterOption.id === businessModel).displayName
                            ).join(', ')
                            }
                        </div>
                    </div>
                </div>
                }
                {!!this.props.profile.businessStages && this.props.profile.businessStages.length > 0 &&
                <div className={sharedClasses.SplitSection}>
                    <div className={sharedClasses.SplitSectionLeft}>
                        <h3>Business Stage</h3>
                    </div>
                    <div className={sharedClasses.SplitSectionRight}>
                        <div className={sharedClasses.IconsContainer}>
                            {!!this.props.profile.businessStages &&
                            this.props.profile.businessStages.map(businessStage =>
                                BusinessStageFilterOptions.find(b => b.id === businessStage).displayName
                            ).join(', ')
                            }
                        </div>
                    </div>
                </div>
                }
                {!!this.props.profile.technologies && this.props.profile.technologies.length > 0 &&
                <div className={sharedClasses.SplitSection}>
                    <div className={sharedClasses.SplitSectionLeft}>
                        <h3>Technologies</h3>
                    </div>
                    <div className={sharedClasses.SplitSectionRight}>
                        <div className={sharedClasses.IconsContainer}>
                            {!!this.props.profile.technologies &&
                            this.props.profile.technologies.map(technology =>
                                TechnologyFilterOptions.find(t => t.id === technology).displayName
                            ).join(', ')
                            }
                        </div>
                    </div>
                </div>
                }
                {!!this.props.profile.previousInvestors && this.props.profile.previousInvestors.length > 0 &&
                <div className={sharedClasses.SplitSection}>
                    <div className={sharedClasses.SplitSectionLeft}>
                        <h3>Previous Investors</h3>
                    </div>
                    <div className={sharedClasses.SplitSectionRight}>
                        {!!this.props.profile && this.props.profile.previousInvestors && this.props.profile.previousInvestors.join(', ')}
                    </div>
                </div>
                }
            </div>
        );
    }
}

export default withRouter(CompanyInformation);
