import React, {Component, Fragment} from "react";
import classes from "./SideNav.module.css";
import {RocketIcon} from "../Icons/RocketIcon";
import {HandshakeIcon} from "../Icons/HandshakeIcon";
import {NavLink, withRouter} from "react-router-dom";
import AppContext from "../../context/AppProvider";
import SubmitButton from "../Forms/SubmitButton/SubmitButton";
import {ClockIcon} from "../Icons/ClockIcon";
import {MailIcon} from "../Icons/MailIcon";
import {PersonIcon} from "../Icons/PersonIcon";
import PopupContext from "../../context/PopupsProvider";

class SideNav extends Component {

    static contextType = AppContext;

    isFeedAllActive = () => {
        return this.props.location.pathname === '/feed';
    }

    onFollowingMouseDown = (e, popupContext) => {
        if (!this.context.currentProfile) {
            e.stopPropagation();
            popupContext.openCreateProfilePopUp();
        }
    }

    onFollowingClick = () => {
        if (!!this.props.onOptionSelected) {
            this.props.onOptionSelected();
        }
    }

    onMessagesMouseDown = (e, popupContext) => {
        if (!this.context.currentProfile) {
            e.stopPropagation();
            popupContext.openCreateProfilePopUp();
        }
    }

    onMessagesClick = () => {
        if (!!this.props.onOptionSelected) {
            this.props.onOptionSelected();
        }
    }

    handleOptionSelected = () => {
        if (!!this.props.onOptionSelected) {
            this.props.onOptionSelected();
        }
    }

    render() {
        const followingLink = !!this.context.currentProfile
            ? `/feed/following/${this.context.currentProfile.profileId}`
            : '/feed/following/';

        return (
            <PopupContext.Consumer>
                {popupContext => (
                    <div className={classes.Container}>
                        <div className={classes.NavGroup}>
                            <div className={classes.NavHeader}>Explore</div>
                            <NavLink to={'/explore/investors'} activeClassName={classes.NavOptionActive} onClick={this.handleOptionSelected}>
                                <div className={classes.NavOption}>
                                    <HandshakeIcon /> Investors
                                </div>
                            </NavLink>
                            <NavLink to={'/explore/companies'} activeClassName={classes.NavOptionActive} onClick={this.handleOptionSelected}>
                                <div className={classes.NavOption}>
                                    <RocketIcon /> Startups
                                </div>
                            </NavLink>
                            <NavLink to={'/messages'} activeClassName={classes.NavOptionActive} onClick={this.onMessagesClick} onMouseDown={e => this.onMessagesMouseDown(e, popupContext)}>
                                <div className={classes.NavOption}>
                                    <MailIcon/> Messages
                                </div>
                            </NavLink>
                        </div>
                        <div className={classes.NavGroupNews}>
                            <div className={classes.NavHeader}>News <span> See the latest in climate tech</span></div>
                            <NavLink to={'/feed'} activeClassName={classes.NavOptionActive} isActive={this.isFeedAllActive} onClick={this.handleOptionSelected}>
                                <div className={classes.NavOption}>
                                    <ClockIcon /> Latest
                                </div>
                            </NavLink>
                            <NavLink to={followingLink} activeClassName={classes.NavOptionActive} onClick={this.onFollowingClick} onMouseDown={e => this.onFollowingMouseDown(e, popupContext)}>
                                <div className={classes.NavOption}>
                                    <PersonIcon /> Following
                                </div>
                            </NavLink>
                        </div>
                        {!!this.context.currentProfile &&
                        <Fragment>
                            <div className={classes.CreateProfileButtonSubText}>
                                Create a startup or investor page
                            </div>
                            <SubmitButton className={classes.CreateProfileButton} onClick={() => popupContext.openCreateProfilePopUp()}>
                                Create a page
                            </SubmitButton>
                        </Fragment>
                        }
                        {!this.context.currentProfile &&
                        <Fragment>
                            <SubmitButton className={classes.CreateProfileButton} onClick={popupContext.openSignInPopUp}>
                                Sign in
                            </SubmitButton>
                        </Fragment>
                        }
                        <div className={classes.FooterPage}>
                            <div className={classes.ContactOption}>
                                Seattle, WA
                            </div>
                            <div className={classes.ContactOption}>
                                <a href="mailto:hello@climatehub.io?Subject=Hello%20ClimateHub" target="_blank">hello@climatehub.io</a>
                            </div>
                            <div className={classes.ContactOption}>
                                <NavLink to='/terms' target='_blank'>Terms of Service</NavLink>
                            </div>
                            <div className={classes.ContactOption}>
                                <NavLink to='/privacy' target='_blank'>Privacy Policy</NavLink>
                            </div>
                            <div className={classes.ContactOption}>
                                © 2021 ClimateHub
                            </div>
                        </div>
                    </div>
                )}
            </PopupContext.Consumer>
        );
    }
}

export default withRouter(SideNav);
