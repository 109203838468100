const PROD = 'prod';
const BETA = 'beta';

export const getAppSyncIdentityPoolId = () => {
    switch (process.env.REACT_APP_DOMAIN) {
        case PROD:
            return 'us-west-2:39a6714d-11f6-4715-aa7f-a6b33ee1e693';
        case BETA:
        default:
            return 'us-west-2:d087dd31-97ae-4ebb-97f4-c6c9915e5ddc';
    }
}

export const getAppSyncUrl = () => {
    switch (process.env.REACT_APP_DOMAIN) {
        case PROD:
            return 'https://c5r2top6gbfsve75nm2zik5zi4.appsync-api.us-west-2.amazonaws.com/graphql';
        case BETA:
        default:
            return 'https://7j2tg3aucrchblw6nka4wox36y.appsync-api.us-west-2.amazonaws.com/graphql';
    }
}
