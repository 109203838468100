import React, {Component} from 'react';
import classes from './TextArea.module.css';

class TextArea extends Component {
    state = {
        isFocused: false
    };

    onFocusIn = () => {
        this.setState({isFocused: true});
    };

    onFocusOut = () => {
        this.setState({isFocused: false});
    };

    render() {
        const containerClasses = [classes.TextArea];
        if (this.state.isFocused && !this.props.isDisabled) {
            containerClasses.push(classes.TextAreaFocused);
        }
        if (!!this.props.error) {
            containerClasses.push(classes.TextAreaError);
        }

        return (
            <div className={classes.Container}>
                <textarea className={containerClasses.join(' ')}
                          value={this.props.value}
                          onFocus={this.onFocusIn}
                          onBlur={this.onFocusOut}
                          placeholder={this.props.placeholder}
                          onChange={e => this.props.onChange(e.target.value)}/>
                {!!this.props.error &&
                <div className={classes.Error}>
                    <i className="fas fa-exclamation-circle"/> {this.props.error}
                </div>
                }
            </div>
        );
    }
}

export default TextArea;
