import * as CognitoIdentity from 'aws-sdk/clients/cognitoidentity';
import {getAppSyncIdentityPoolId, getAppSyncUrl} from "./config";
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { getConversation, getConversations, getMessages } from '../../graphql/queries';
import { sendMessage, createConversation } from '../../graphql/mutations';

const AWS = require('aws-sdk');

const INVOKE_URL = {
    APP_SYNC_URL: getAppSyncUrl()
}
const IDENTITY_POOL_ID = getAppSyncIdentityPoolId();
const AWS_REGION = 'us-west-2';

class AppSyncClient {

    #authToken = null;
    #cognitoIdentity = new CognitoIdentity({region: AWS_REGION});
    #client = null;

    constructor(authToken) {
        this.#authToken = authToken;
    }

    setAuthToken = (authToken) => {
        this.#authToken = authToken;
    };

    getClient = async () => {
        try {
            if (this.#client) {
                return this.#client;
            } else {
                const appSyncClient = await this.createAppSyncClient();
                if (!this.#client) {
                    this.#client = appSyncClient;
                }
                return this.#client;
            }
        } catch (e) {
            console.log('encountered error');
            console.log(e);
        }
    };

    createAppSyncClient = () =>  {
        return new Promise((resolve, reject) => {
            this.#doAuthentication(resolve, reject)
        })
    };

    #doAuthentication = (resolve, reject) => {
        const params = {
            IdentityPoolId: IDENTITY_POOL_ID
        };
        this.#cognitoIdentity.getId(params, (err, data) => {
            if (err) {
                console.log(err);
                reject();
            } else {
                this.#getCredentialsForIdentity(data.IdentityId, resolve, reject);
            }
        });
    };

    #getCredentialsForIdentity = (identityId, resolve, reject) => {
        const getCredentialsParams = {
            IdentityId: identityId
        };
        let accessKey;
        let secretKey;
        let sessionToken;
        this.#cognitoIdentity.getCredentialsForIdentity(getCredentialsParams, (err, data) => {
            if (err) {
                console.log(err);
                reject();
            } else {
                let credentials = data.Credentials;

                accessKey = credentials.AccessKeyId;
                secretKey = credentials.SecretKey;
                sessionToken = credentials.SessionToken;

                const config = {
                    invokeUrl: INVOKE_URL.APP_SYNC_URL,
                    region: AWS_REGION,
                    accessKey,
                    secretKey,
                    sessionToken
                };

                if (!this.#client) {
                    resolve(new AWSAppSyncClient({
                        url: config.invokeUrl,
                        region: config.region,
                        auth: {
                            type: AUTH_TYPE.AWS_IAM,
                            credentials: new AWS.Credentials({
                                accessKeyId: config.accessKey,
                                secretAccessKey: config.secretKey,
                                sessionToken: config.sessionToken
                            })
                        },
                        cacheOptions: {
                            addTypename: false
                        },
                        defaultOptions: {
                            watchQuery: {
                                fetchPolicy: 'network-only',
                                errorPolicy: 'ignore',
                            },
                            query: {
                                fetchPolicy: 'network-only',
                                errorPolicy: 'all',
                            },
                        },
                    }));
                } else {
                    resolve(this.#client);
                }
            }
        });
    };

    getConversation = async (conversationId) => {
        const client = await this.getClient();
        const { data: conversation, error, _ } = await client.query({
            query: getConversation,
            variables: {
                conversationId: conversationId,
                idToken: this.#authToken
            }
        });
        if (error) {
            console.log("Error: " + error);
        } else {
            return conversation;
        }       
    }

    getConversations = async (profileId, exclusiveStartKey) => {
        const client = await this.getClient();
        const { data: conversation, error, _ } = await client.query({
            query: getConversations,
            variables: {
                profileId: profileId,
                exclusiveStartKey: exclusiveStartKey,
                idToken: this.#authToken
            },
            fetchPolicy: 'network-only'
        });
        if (error) {
            console.log("Error: " + error);
        } else {
            return conversation;
        }       
    }

    getMessages = async (conversationId, exclusiveStartKey) => {
        const client = await this.getClient();
        const { data: conversation, error, _ } = await client.query({
            query: getMessages,
            variables: {
                conversationId: conversationId,
                exclusiveStartKey: exclusiveStartKey,
                idToken: this.#authToken
            },
            fetchPolicy: 'network-only'
        });
        if (error) {
            console.log("Error: " + error);
        } else {
            return conversation;
        }       
    }


    // * MUTATIONS
    sendMessage = async (conversationId, message) => {
        const client = await this.getClient();
        const { data: conversation, error, _ } = await client.mutate({
            mutation: sendMessage,
            variables: {
                conversationId: conversationId,
                message: message,
                idToken: this.#authToken
            }
        });
        if (error) {
            console.log("Error: " + error);
        } else {
            return conversation;
        }      
    }

    createConversation = async (createConversationInput) => {
        const client = await this.getClient();
        const { data: conversation, error, _ } = await client.mutate({
            mutation: createConversation,
            variables: {
                createConversationInput: createConversationInput,
                idToken: this.#authToken
            }
        });
        if (error) {
            console.log("Error: " + error);
        } else {
            return conversation;
        }      
    }

 }


export default AppSyncClient;
