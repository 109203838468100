import React, {Component, Fragment} from "react";
import classes from "./FeedPostComment.module.css";
import FeedPostOptions from "../../Feed/FeedPost/FeedPostOptions/FeedPostOptions";
import moment from "moment";
import SubmitButton from "../../Forms/SubmitButton/SubmitButton";
import AppContext from "../../../context/AppProvider";
import GlobalPopup from "../../Popup/GlobalPopup/GlobalPopup";
import FeedPostContent from "../../Feed/FeedPost/FeedPostContent/FeedPostContent";
import ProfilePhotoWithDefault from "../../ProfilePage/ProfilePhotoWithDefault/ProfilePhotoWithDefault";
import {NavLink, withRouter} from "react-router-dom";
import Carousel from "../../Carousel/Carousel";
import FeedSubmit from "../../Feed/FeedPost/FeedSubmit/FeedSubmit";
import Image from "../../../model/Image";
import {ProfileType} from "../../../constants/ProfileTypeConstants";

class FeedPostComment extends Component {

    static contextType = AppContext;
    
    state = {
        isInEditMode: false,
        newCommentContent: '',
        isCommentSubmitting: false,
        isCommentDeleting: false,
        isDeleteFeedModalOpen: false
    }

    componentDidMount() {
        this.setState({newCommentContent: this.props.comment.content});
    }

    enterEditMode = () => {
        this.setState({isInEditMode: true});
    };

    openDeleteFeedPostModal = () => {
        this.setState({isDeleteFeedModalOpen: true});
    };

    closeDeleteFeedPostModal = () => {
        this.setState({isDeleteFeedModalOpen: false});
    };

    exitEditMode = () => {
        this.setState({isInEditMode: false});
    };

    editComment = async (images, videos) => {
        await this.setState({isCommentSubmitting: true}, async () => {
            const imageRefs = await Promise.all(images.filter(img => !img.isUploadedLocally).map(async img => {
                const re = /(?:\.([^.]+))?$/;
                const ext = re.exec(img.file.name)[1]
                const result = await this.context.apiGatewayClient.getFeedMediaUploadUrl(ext);
                await this.context.s3Client.uploadImage(img.file, result.data.uploadUrl);
                return result.data.fileName;
            }));
            const videoRefs = await Promise.all(videos.filter(vid => !vid.isUploadedLocally).map(async vid => {
                const re = /(?:\.([^.]+))?$/;
                const ext = re.exec(vid.file.name)[1]
                const result = await this.context.apiGatewayClient.getFeedMediaUploadUrl(ext);
                await this.context.s3Client.uploadImage(vid.file, result.data.uploadUrl);
                return result.data.fileName;
            }));

            const comment = {
                profileId: this.context.currentProfile.profileId,
                commentId: this.props.comment.commentId,
                content: this.state.newCommentContent,
                images: [...images.filter(img => img.isUploadedLocally).map(img => img.src), ...imageRefs],
                videos: [...videos.filter(video => video.isUploadedLocally).map(vid => vid.src), ...videoRefs],
            };

            const result = await this.context.apiGatewayClient.editFeedPostComment(this.props.feedPost.postId, comment);
            this.setState({isInEditMode: false, isCommentSubmitting: false});
            this.props.onEditComment(result.data.comment);
        });
    };

    deleteComment = () => {
        const comment = {
            profileId: this.context.currentProfile.profileId,
            commentId: this.props.comment.commentId,
        };
        this.setState({isCommentDeleting: true}, () => {
            this.context.apiGatewayClient.deleteFeedPostComment(this.props.feedPost.postId, comment).then(() => {
                this.props.onCommentDelete(this.props.comment.commentId);
                this.setState({isCommentDeleting: false, isDeleteFeedModalOpen: false});
            });
        });
    };

    setNewCommentContent = (newCommentContent) => {
        this.setState({newCommentContent: newCommentContent})
    };

    navigateToProfile = () => {
        this.props.history.push(`/profile/${this.props.comment.profileId}`);
    }

    render() {
        const {currentProfile} = this.context;
        const profileRedirectUrl = this.props.comment.profileType === ProfileType.Investor
            ? `/profile/${this.props.comment.profileId}`
            : `/profile/${this.props.comment.profileId}`;

        return (
            <div className={classes.Comment}>
                {this.state.isDeleteFeedModalOpen &&
                <GlobalPopup onOutsideClick={this.closeDeleteFeedPostModal}>
                    <div className={classes.DeleteFeedPostContainer}>
                        <h3>
                            Are you sure you want to delete this comment?
                        </h3>
                        <div className={classes.DeleteSubmitContainer}>
                            <SubmitButton className={classes.EditSubmitCancelButton}
                                          isDisabled={this.state.isCommentDeleting}
                                          onClick={this.closeDeleteFeedPostModal}>Cancel</SubmitButton>
                            <SubmitButton className={classes.EditSubmitButton}
                                          isSubmitting={this.state.isCommentDeleting}
                                          onClick={this.deleteComment}>Delete</SubmitButton>
                        </div>
                    </div>
                </GlobalPopup>
                }
                <div className={classes.FeedPostPrimary}>
                    <div className={classes.FeedPostLeft}>
                        {!!currentProfile && currentProfile.profileId === this.props.comment.profileId &&
                        <FeedPostOptions enterEditMode={this.enterEditMode}
                                         openDeleteFeedPostModal={this.openDeleteFeedPostModal}/>
                        }
                        {!this.props.comment.isProfileDeleted &&
                        <NavLink to={profileRedirectUrl}>
                            <div className={classes.Logo}>
                                <ProfilePhotoWithDefault logo={this.props.comment.logo}
                                                         name={this.props.comment.name}/>
                            </div>
                        </NavLink>
                        }
                        {this.props.comment.isProfileDeleted &&
                        <div className={classes.Logo}>
                            <i className="fas fa-user-slash"/>
                        </div>
                        }
                        <div className={classes.FeedPostHeader}>
                            <h3>{this.props.comment.isProfileDeleted ?
                                <i>This profile no longer exists</i>
                                : <NavLink to={profileRedirectUrl}>{this.props.comment.name}</NavLink>}
                            </h3>
                            <div className={classes.Timestamp}>
                                {this.props.comment.creationDateEpoch !== this.props.comment.lastEditedDateEpoch &&
                                <span>Edited</span>
                                }
                                {moment.unix(this.props.comment.lastEditedDateEpoch).fromNow()}
                            </div>
                        </div>
                    </div>
                    <div className={classes.FeedPostRight}>
                        <div className={classes.FeedPostContent}>
                            {!this.state.isInEditMode &&
                            <Fragment>
                                <div className={classes.FeedPostText}>
                                    <FeedPostContent content={this.props.comment.content}/>
                                </div>
                                <Carousel images={this.props.comment.images}
                                          videos={this.props.comment.videos}
                                          content={this.props.comment.content}/>
                            </Fragment>
                            }
                            {this.state.isInEditMode &&
                            <div className={classes.EditContainer} onClick={e => e.stopPropagation()}>
                                <div className={classes.Input}>
                                    <textarea maxLength="2000"
                                              onChange={e => this.setNewCommentContent(e.target.value)}
                                              value={this.state.newCommentContent}
                                    />
                                </div>
                                <div className={classes.EditSubmitContainer}>
                                    <FeedSubmit onSubmit={this.editComment}
                                                images={!!this.props.comment.images ? this.props.comment.images.map(image => new Image(null, image, true)) : []}
                                                videos={!!this.props.comment.videos ? this.props.comment.videos.map(video => new Image(null, video, true)) : []}
                                                isDisabled={this.state.isCommentSubmitting}
                                                shouldShowCancel={true}
                                                onCancel={this.exitEditMode}
                                                isSubmitting={this.state.isCommentSubmitting}/>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(FeedPostComment);
