export const CompanySizeFilterOptions = [
    {
        id: 'LEVEL_1',
        displayName: '1-10'
    },
    {
        id: 'LEVEL_2',
        displayName: '11-50'
    },
    {
        id: 'LEVEL_3',
        displayName: '51-100'
    },
    {
        id: 'LEVEL_4',
        displayName: '101-200'
    },
    {
        id: 'LEVEL_5',
        displayName: '201+'
    }
];
