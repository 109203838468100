import React, {Component, Fragment} from "react";
import classes from "./ProfilePhotoPreview.module.css";
import ProfilePhotoWithDefault from "../ProfilePhotoWithDefault/ProfilePhotoWithDefault";
import GlobalPopup from "../../Popup/GlobalPopup/GlobalPopup";
import {CloseIcon} from "../../Icons/CloseIcon";

class ProfilePhotoPreview extends Component {

    state = {
        isPreviewOpen: false
    }

    openPreview = () => {
        this.setState({isPreviewOpen: true});
    }

    closePreview = () => {
        this.setState({isPreviewOpen: false});
    }

    render() {
        return (
            <Fragment>
                <div className={classes.ProfilePhotoInner} onClick={this.openPreview}>
                    <ProfilePhotoWithDefault logo={this.props.logo} name={this.props.name}/>
                </div>
                {this.state.isPreviewOpen &&
                <GlobalPopup onOutsideClick={this.closePreview}>
                    <div className={classes.ProfilePhotoPreview}>
                        <div className={classes.CloseHeader}>
                            <div className={classes.CloseIcon} onClick={this.closePreview}>
                                <CloseIcon />
                            </div>
                        </div>
                        <div className={classes.ProfilePhotoPreviewContainer}>
                            <ProfilePhotoWithDefault logo={this.props.logo} name={this.props.name}/>
                        </div>
                    </div>
                </GlobalPopup>
                }

            </Fragment>
        );
    }
}

export default ProfilePhotoPreview;
