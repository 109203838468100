import React, {Component, createRef} from 'react';
import {withRouter} from "react-router-dom";
import classes from './NativeAccountRedirect.module.css';
import AppContext from '../../../context/AppProvider';
import Spinner from '../../Spinner/spinner';
import PopupContext from "../../../context/PopupsProvider";

class NativeAccountRedirect extends Component {

    static contextType = AppContext;

    state = {}

    componentDidMount = async () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const code = urlParams.get('code');
        const username = urlParams.get('username');

        await this.context.apiGatewayClient.confirmSignUp(code, username);
        this.setState({ref: createRef()}, () => {
            this.state.ref.current.click();
            this.props.history.push({pathname: '/'});
        })
    }

    render () {
        return (
            <PopupContext.Consumer>
                {popupContext => (
                    <div className={classes.Container} ref={this.state.ref} onClick={popupContext.openSignInPopUp}>
                        <div className={classes.Spinner}>
                            <Spinner />
                        </div>
                        <div>
                            Signing you in...
                        </div>
                    </div>
                )}
            </PopupContext.Consumer>
        )
    }
}

export default withRouter(NativeAccountRedirect);
