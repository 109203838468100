import React, {Component, createRef} from "react";
import Conversations from "./Conversations/Conversations";
import sharedClasses from "../../styles/Styles.module.css";
import classes from "./ConversationsPage.module.css";
import SideNav from "../SideNav/SideNav";
import Recommendations from "../Recommendations/Recommendations";
import {RecommendationsMatchType} from "../../constants/RecommendationMatchTypeConstants";

class ConversationsPage extends Component {

    state = {
        ref: createRef()
    }

    render() {
        return (
            <div className={sharedClasses.ContentContainer} onScroll={this.handleScroll} ref={this.state.ref}>
                <div className={classes.ContentContainerInner}>
                    <div className={classes.SideNav}>
                        <SideNav openMessages={this.props.openMessages}/>
                    </div>
                    <div className={classes.Conversations}>
                        <Conversations />
                    </div>
                    <div className={sharedClasses.ClimateAds}>
                        <Recommendations profileId={this.context.profileId}
                                         toMatchWith={RecommendationsMatchType.Both}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default ConversationsPage;
