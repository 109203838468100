import Concept from '../../assets/BusinessStage/idea-2.svg';
import ProductPrototype from '../../assets/BusinessStage/prototype.png';
import GoToMarket from '../../assets/BusinessStage/gotomarket.png';
import GrowthAndExpansion from '../../assets/BusinessStage/growthexpansion.png';


export const BusinessStageFilterOptions = [
    {
        id: 'CONCEPT',
        displayName: 'Concept',
        icon: Concept
    },
    {
        id: 'PRODUCT_PROTOTYPE',
        displayName: 'Product / Prototype',
        icon: ProductPrototype
    },
    {
        id: 'GO_TO_MARKET',
        displayName: 'Go to market',
        icon: GoToMarket
    },
    {
        id: 'GROWTH_AND_EXPANSION',
        displayName: 'Growth and Expansion',
        icon: GrowthAndExpansion
    }
];