import React, {Component, createRef, Fragment} from "react";
import ProfilePhotoWithDefault from "../../ProfilePage/ProfilePhotoWithDefault/ProfilePhotoWithDefault";
import classes from './Conversation.module.css';
import AppContext from "../../../context/AppProvider";
import SubmitButton from "../../Forms/SubmitButton/SubmitButton";
import {PaperAirplaneIcon} from "../../Icons/PaperAirplaneIcon";
import PlaceHolder from "./PlaceHolder/PlaceHolder";
import moment from "moment";
import {withRouter} from "react-router-dom";
import ReactStringReplace from "react-string-replace";


class Conversation extends Component {

    static contextType = AppContext;

    state = {
        scrollRef: createRef(),
        messages: [],
        newMessageContent: '',
        isSubmitting: false,
        isLoading: false,
        messagesEndRef: createRef(),
        inputRef: createRef(),
        lastEvaluatedKey: null,
        reachedEndOfResults: false
    }

    componentDidMount = async () => {
        this.setState({isLoading: true}, async () => {
            await this.fetchMessages();
        });
    }

    handleScroll = async (e) => {
        if (this.state.isLoading || this.state.reachedEndOfResults) {
            return;
        }

        // Because the scroll direction is opposite we need to add the scroll top rather than subtract
        if (e.target.scrollHeight + e.target.scrollTop < e.target.clientHeight + 1000) {
            await this.fetchMessages()
        }
    };

    fetchMessages = async () => {
        this.setState({isLoading: true}, async () => {
            const requesterId = this.context.currentProfile.profileId;
            const result = (await this.context.appSyncClient.getMessages(this.props.selectedConversation.conversationId, this.state.lastEvaluatedKey))['getMessages'];
            const enrichedMessages = await Promise.all(result['messages'].map(async message => {
                return {
                    ...message,
                    messageMetadata: {
                        logo: message.author.profileId === requesterId ? this.context.currentProfile.logo : this.props.selectedConversation.otherProfile.logo,
                        name: message.author.profileId === requesterId ? this.context.currentProfile.name : this.props.selectedConversation.otherProfile.name
                    }
                }
            }));
            this.setState({
                messages: [...this.state.messages, ...enrichedMessages],
                isLoading: false,
                lastEvaluatedKey: result['lastEvaluatedKey'],
                reachedEndOfResults: enrichedMessages.length === 0 || !result.lastEvaluatedKey
            });
        })
    }

    onNewMessageContentChange = (e) => {
        this.setState({newMessageContent: e.target.value});
    }

    handleKeyDown = async (event) => {
        if (event.key === 'Enter') {
            await this.onMessageSubmit();
        }
    };

    onMessageSubmit = async () => {
        const newMessageContent = this.state.newMessageContent;
        if (!newMessageContent || newMessageContent.trim().length === 0) {
            return;
        }
        this.setState({isSubmitting: true, newMessageContent: ''}, async () => {
            const message = {
                content: newMessageContent,
                contentType: "text",
                author: {
                    profileId: this.context.currentProfile.profileId
                }
            }

            const sendMessageResponse = await this.context.appSyncClient.sendMessage(this.props.selectedConversation.conversationId, message);
            await this.props.onMessageCreation();

            this.setState({
                isSubmitting: false,
                messages: [{
                    ...sendMessageResponse['sendMessage'],
                    messageMetadata: {
                        logo: this.context.currentProfile.logo,
                        name: this.context.currentProfile.name
                    }
                }, ...this.state.messages]}, this.scrollToBottom);
        });
    }

    scrollToBottom = () => {
        this.state.messagesEndRef.current.scrollIntoView();
    }

    navigateToProfile = (profileId) => {
        this.props.history.push(`/profile/${profileId}`);
    }

    render() {
        return (
            <Fragment>
                <div className={classes.Messages} ref={this.state.scrollRef} onScroll={this.handleScroll}>
                    {this.state.isLoading && this.state.messages.length === 0 &&
                    <div className={classes.PlaceHolders}>
                        <PlaceHolder/>
                        <PlaceHolder/>
                        <PlaceHolder/>
                        <PlaceHolder/>
                    </div>
                    }
                    {this.state.messages.map(message => (
                        <div className={classes.Message}>
                            <div className={classes.Logo} onClick={() => this.navigateToProfile(message.author.profileId)}>
                                <ProfilePhotoWithDefault logo={message.messageMetadata.logo} name={message.messageMetadata.name}/>
                            </div>
                            <div className={classes.MessageContent}>
                                <div className={classes.MessageContentHeader}>
                                    <h3 onClick={() => this.navigateToProfile(message.author.profileId)}>{message.messageMetadata.name}</h3>
                                    <div className={classes.Timestamp}>
                                        {moment.unix(parseInt(message.sentAtDateEpoch) / 1000).fromNow()}
                                    </div>
                                </div>
                                <pre>
                                    {ReactStringReplace(message.content, /(\w+:\/\/\S+)/g, (match, _) => (
                                        <a className={classes.Link} href={match} target="_blank"><i className="fas fa-link" /> {match}</a>
                                    ))}
                                </pre>
                            </div>
                        </div>
                    ))}
                    <div ref={this.state.messagesEndRef} />
                </div>
                <div className={classes.CreateMessage}>
                    <div className={classes.InputContainer}>
                        <input type="text"
                               ref={this.state.inputRef}
                               onChange={this.onNewMessageContentChange}
                               value={this.state.newMessageContent}
                               placeholder={"Send a message..."}
                               onKeyDown={this.handleKeyDown}/>
                    </div>
                    <SubmitButton onClick={this.onMessageSubmit}
                                  className={classes.CreateMessageButton}
                                  isSubmitting={this.state.isSubmitting}
                                  isDisabled={!this.state.newMessageContent || this.state.newMessageContent.length === 0}>
                        {!this.state.isSubmitting && <PaperAirplaneIcon />}
                    </SubmitButton>
                </div>
            </Fragment>
        );
    }
}

export default withRouter(Conversation);
