import sharedClasses from "../Styles/SharedStyles.module.css";
import React, {Component} from "react";
import {InvestorTypeFilterOptions} from "../../../constants/filteroptions/InvestorTypeFilterOptions";


class CompanySubTitle extends Component {

    render() {
        let type = 'Startup';
        let location = this.props.profile.location;
        if (!!this.props.charLimit && location && location.length > this.props.charLimit) {
            location = this.props.profile.location.slice(0, this.props.charLimit - type.length) + '...';
        }

        return (
            <div className={sharedClasses.SubTitle}>
                <div>
                    {type}
                </div>
                {!!type && !!location &&
                    <div>
                        <i className="fas fa-circle" />
                    </div>
                }
                <div>
                    {location}
                </div>
            </div>
        );
    }
}

export default CompanySubTitle;

