import React from 'react';

import classes from './spinner.module.css';

const Spinner = (props) => {
    return (
        <div className={classes.Container}>
            <div className={classes.Spinner}/>
        </div>
    );
};

export default Spinner;