import React, {Component} from 'react';
import classes from '../SignInPopUp.module.css';
import sharedClasses from '../../../../styles/FormStyles.module.css';
import TextInput from "../../../Forms/Input/TextInput";
import SubmitButton from "../../../Forms/SubmitButton/SubmitButton";
import AppContext from '../../../../context/AppProvider';
import {withRouter} from 'react-router-dom';
import {ProfileType} from "../../../../constants/ProfileTypeConstants";
import {GoogleIcon} from "../../../Icons/GoogleIcon";
import {isValidEmail, isValidPassword} from "../../Auth.util";
import {CloseIcon} from "../../../Icons/CloseIcon";
import PopupContext from "../../../../context/PopupsProvider";

const cognitoDomainPrefix = 'climatehubdev';
const cognitoName = `https://${cognitoDomainPrefix}.auth.us-west-2.amazoncognito.com`;
const hostName = 'http://localhost:3000';
const cognitoClientId = '7srpm9gj9lvrtukfrt1v4kuvbf';
const redirectUrl = `${cognitoName}/oauth2/authorize?identity_provider=Google&redirect_uri=${hostName}/googleAccountRedirect&response_type=TOKEN&client_id=${cognitoClientId}&scope=aws.cognito.signin.user.admin email openid phone profile`


class SignIn extends Component {

    static contextType = AppContext;

    state = {
        email: '',
        password: '',
        isSubmitting: false,
        validationErrors: [],
        submissionError: null
    };

    handleKeyDown = (e, popupContext) => {
        if (e.key === 'Enter') {
            this.handleSubmit(e, popupContext);
        }
    };

    onEmailInputChange = (event) => {
        if (isValidEmail(event.target.value)) {
            const errors = this.state.validationErrors.filter(err => err !== 'email');
            this.setState({email: event.target.value, validationErrors: errors});
        } else {
            this.setState({email: event.target.value});
        }
    };

    onPasswordInputChange = (event) => {
        if (isValidPassword(event.target.value)) {
            const errors = this.state.validationErrors.filter(err => err !== 'password');
            this.setState({password: event.target.value, validationErrors: errors});
        } else {
            this.setState({password: event.target.value});
        }
    };

    hasError = (error) => {
        return this.state.validationErrors.filter(err => err === error).length > 0;
    };

    handleResendCode = () => {
        this.setState({isResubmittingEmail: true});
        this.context.apiGatewayClient.resendConfirmationCode(this.state.email).then(res => {
            this.setState({isResubmittingEmail: false});
        })
    };

    isPersonalProfileFilled = (profile) => {
        return !!profile.logo && !!profile.name && !!profile.description;
    }

    handleSubmit = (e, popupContext) => {
        const email = this.state.email;
        const password = this.state.password;
        const errors = [];
        if (!isValidEmail(email)) {
            errors.push('email');
        }
        if (!isValidPassword(password)) {
            errors.push('password');
        }

        if (errors.length > 0) {
            this.setState({validationErrors: errors});
        } else {
            this.setState({isSubmitting: true}, () => {
                this.context.apiGatewayClient.signIn(email, password).then(res => {
                    this.context.setAuthToken(res.data.token);
                    this.context.setAccountData({email: res.data.accountData.email});
                    this.context.apiGatewayClient.setAuthToken(res.data.token);
                    this.context.apiGatewayClient.getAccount().then(result => {
                        if (!!result.data.profiles && result.data.profiles.length > 0) {
                            let currentProfile;
                            if (result.data.profiles.filter(profile => profile.profileType === ProfileType.Personal).length > 0) {
                                currentProfile = result.data.profiles.find(profile => profile.profileType === ProfileType.Personal);
                                this.context.setCurrentProfile(currentProfile);
                            } else {
                                currentProfile = result.data.profiles[0];
                                this.context.setCurrentProfile(currentProfile);
                            }

                            if (currentProfile.profileType !== ProfileType.Investor
                                && currentProfile.profileType !== ProfileType.Startup) {
                                if (!this.isPersonalProfileFilled(currentProfile)) {
                                    popupContext.openPersonalProfileOnBoarding();
                                } else {
                                    popupContext.openCreateProfilePopUp();
                                }
                            }
                        }
                        this.setState({isSubmitting: false}, this.props.closePopUp);
                    });
                }).catch((e) => {
                    if (e.response && e.response.data.errors[0] === "User is not confirmed.") {
                        this.setState({
                            isSubmitting: false,
                            submissionError: "This account has not been verified yet. Please check your email " +
                                "to verify your account or click below to resend the verification link",
                            showResendButton: true
                        });
                    } else if (e.response && e.response.data.errors[0] === "Password reset required for the user") {
                        this.props.openResetPassword();
                    } else {
                        this.setState({
                            isSubmitting: false,
                            submissionError: "The email or password supplied was incorrect. Please try again.",
                            showResendButton: false
                        })
                    }
                });
            });
        }
    };

    render() {
        return (
            <PopupContext.Consumer>
                {popupContext => (
                    <div className={classes.SignInPopUp}>
                        <div className={sharedClasses.PopupInner}>
                            <div className={sharedClasses.Header}>
                                <button className={sharedClasses.Exit} onClick={popupContext.closeSignInPopUp}>
                                    <CloseIcon />
                                </button>
                                <h2>
                                    Sign in
                                </h2>
                            </div>
                            <div className={sharedClasses.FormContainer}>
                                <div className={sharedClasses.FormInner}>
                                    <TextInput error={this.hasError('email') ? 'You must specify a valid email' : null}
                                               label="Email"
                                               icon="fas fa-envelope"
                                               type="email"
                                               value={this.state.email}
                                               onChange={this.onEmailInputChange}
                                               onKeyDown={e => this.handleKeyDown(e, popupContext)}
                                               isDisabled={this.state.isSubmitting}/>
                                    <TextInput
                                        error={this.hasError('password') ? 'Password must contain at least 8 characters' : null}
                                        label="Password"
                                        icon="fas fa-lock"
                                        type="password"
                                        value={this.state.password}
                                        onChange={this.onPasswordInputChange}
                                        onKeyDown={e => this.handleKeyDown(e, popupContext)}
                                        isDisabled={this.state.isSubmitting}/>
                                    <div className={sharedClasses.SubmitButtonWrapper}>
                                        <SubmitButton isSubmitting={this.state.isSubmitting}
                                                      onClick={e => this.handleSubmit(e, popupContext)}
                                                      isFullWidth={true}
                                                      disabled={this.state.isSubmitting}>
                                            {this.state.isSubmitting ? 'Signing in...' : 'Sign in'}
                                        </SubmitButton>
                                    </div>
                                    {!!this.state.submissionError &&
                                    <div className={sharedClasses.Error}>
                                        <i className="fas fa-exclamation-circle"/> {this.state.submissionError}
                                        {this.state.showResendButton &&
                                        <div className={classes.ResendEmailButtonContainer}>
                                            <SubmitButton isSubmitting={this.state.isResubmittingEmail}
                                                          onClick={this.handleResendCode}
                                                          isFullWidth={false}
                                                          disabled={this.state.isResubmittingEmail}>
                                                {this.state.isResubmittingEmail ? "Sending email..." : "Resend verification email"}
                                            </SubmitButton>
                                        </div>
                                        }
                                    </div>
                                    }
                                    {/*<div className={sharedClasses.SubmitButtonWrapper}>*/}
                                    {/*    <SubmitButton onClick={() => window.open(redirectUrl)}*/}
                                    {/*                  isFullWidth={true}*/}
                                    {/*                  className={sharedClasses.GoogleButton}>*/}
                                    {/*        <GoogleIcon /> Continue with Google*/}
                                    {/*    </SubmitButton>*/}
                                    {/*</div>*/}
                                    <div className={sharedClasses.SwitchPopup}>
                                        <div className={sharedClasses.SideSwitchPopupLeft}>
                                            Forgot password? <span className={sharedClasses.LabelUnderline}
                                                                   onClick={this.props.openResetPassword}> Reset password</span>
                                        </div>
                                        <div>
                                            Don't have an account? <span className={sharedClasses.LabelUnderline}
                                                                             onClick={popupContext.openSignUpPopUp}> Sign up</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </PopupContext.Consumer>
        );
    }
}

export default withRouter(SignIn);
