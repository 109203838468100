import React from "react";

export const AngleDownIcon = () => {
    return (
        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="angle-down" role="img"
             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="svg-inline--fa fa-angle-down fa-w-10 fa-3x">
            <path fill="currentColor"
                  d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z"
                  className="">
            </path>
        </svg>
    );
};
