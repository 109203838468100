import React from "react";
import classes from "./SocialIcon.module.css";

const SocialIcon = (props) => {
    let link = props.link;
    if (link.slice(0,4) !== "http") {
        link = "https://" + link;
    }

    return (
        <div className={classes.SocialIcon}>
            <a target="_blank" href={link} className={classes.Link}>
                <Icon iconType={props.iconType} />
            </a>
        </div>
    );
};

const Icon = (props) => {
    switch (props.iconType) {
        case 'linkedin':
            return <i className="fab fa-linkedin-in"/>;
        case 'Facebook':
            return <i className="fab fa-facebook-f" />;
        case 'Twitter':
            return <i className="fab fa-twitter"/>;
        default:
            return null;
    }
};

export default SocialIcon;