import React, {Component} from 'react';
import classes from '../SignInPopUp.module.css';
import sharedClasses from '../../../../styles/FormStyles.module.css';
import SubmitButton from "../../../Forms/SubmitButton/SubmitButton";
import AppContext from '../../../../context/AppProvider';
import {withRouter} from 'react-router-dom';
import {GoogleIcon} from "../../../Icons/GoogleIcon";
import ProfileIcon from '../../../../assets/GoogleProfileIcon.png';
import {CloseIcon} from "../../../Icons/CloseIcon";

const cognitoDomainPrefix = 'climatehubdev';
const cognitoName = `https://${cognitoDomainPrefix}.auth.us-west-2.amazoncognito.com`;
const hostName = 'http://localhost:3000';
const cognitoClientId = '7srpm9gj9lvrtukfrt1v4kuvbf';
const redirectUrl = `${cognitoName}/oauth2/authorize?identity_provider=Google&redirect_uri=${hostName}/googleAccountRedirect&response_type=TOKEN&client_id=${cognitoClientId}&scope=aws.cognito.signin.user.admin email openid phone profile`



class ThirdPartySignIn extends Component {

    static contextType = AppContext;

    render() {
        return (
            <div className={classes.SignInPopUp}>
                <div className={sharedClasses.PopupInner}>
                    <div className={sharedClasses.Header}>
                        <button className={sharedClasses.Exit} onClick={this.props.closePopUp}>
                            <CloseIcon />
                        </button>
                        <h2>
                            Welcome back
                        </h2>
                    </div>
                    <div className={sharedClasses.FormContainer}>
                        <div className={sharedClasses.FormInner}>
                            <div className={sharedClasses.CenteredText}>
                                <div>
                                    This account was created using Google. Please log in instead.
                                </div>
                                <div>
                                    <img src={ProfileIcon} />
                                </div>
                                <div>
                                    <b>{this.props.email}</b>
                                </div>
                            </div>
                            <div className={sharedClasses.SubmitButtonWrapper}>
                                <SubmitButton onClick={() => window.open(redirectUrl)}
                                              isFullWidth={true}
                                              className={sharedClasses.GoogleButton}>
                                    <GoogleIcon /> Continue with Google
                                </SubmitButton>
                            </div>
                            <div className={sharedClasses.SwitchPopup}>
                                <div className={sharedClasses.SideSwitchPopupLeft}>
                                    Not you? <span className={sharedClasses.LabelUnderline}
                                                   onClick={this.props.openSignIn}> Use another account</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ThirdPartySignIn);
