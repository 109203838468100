import React from "react";
import classes from "./People.module.css";
import {ProfileAssetsBucketPrefix} from "../../../constants/ProfileAssetsBucketPrefix";

const People = ({founders}) => {
    if (!founders) {
        return null;
    }

    return founders.map((founder, index) => {
        let linkedIn = founder.linkedIn ? founder.linkedIn : '';
        if (linkedIn.slice(0,4) !== "http") {
            linkedIn = "https://" + linkedIn;
        }

        return (
            <div key={index} className={classes.Founder}>
                <div className={classes.FounderHeadshot}>
                    <div className={classes.FounderHeadshotInner}>
                        <img src={`${ProfileAssetsBucketPrefix}${founder.headshot}`} />
                    </div>
                    {!!founder.linkedIn &&
                    <a target="_blank" href={linkedIn}>
                        <div className={classes.LinkedIn}>
                            <i className="fab fa-linkedin-in"/>
                        </div>
                    </a>
                    }
                </div>
                <div className={classes.FounderName}>
                    {founder.name}
                </div>
            </div>
        );
    });
};

export default People;
