import classes from "./InputLabel.module.css";
import React from "react";

const InputLabel = (props) => {
    const containerClasses = [classes.Container];
    if (props.className) {
        containerClasses.push(props.className);
    }

    return (
        <div className={containerClasses.join(' ')}>
            <div className={classes.Label}>
                {props.children}
                {props.required &&
                <div className={classes.RequiredAnnotation}>
                    *
                </div>
                }
            </div>
        </div>
    );
};

export default InputLabel;
