import classes from "./AccountDropdown.module.css";
import React, {Component} from "react";
import AppContext from "../../../context/AppProvider";
import SubmitButton from "../../Forms/SubmitButton/SubmitButton";
import {withRouter} from "react-router-dom";
import ProfilePhotoWithDefault from "../../ProfilePage/ProfilePhotoWithDefault/ProfilePhotoWithDefault";
import Expand from "react-expand-animated";
import sharedClasses from "../../../styles/FormStyles.module.css";
import {CloseIcon} from "../../Icons/CloseIcon";
import PopupContext from "../../../context/PopupsProvider";

class AccountDropdown extends Component {

    static contextType = AppContext;

    state = {
        profiles: [],
        profilesExpanded: false,
        isLoadingProfiles: false,
    };

    componentDidMount() {
        this.setState({isLoadingProfiles: true});
        this.context.apiGatewayClient.getAccount().then(result => {
            this.setState({
                profiles: !!result.data.profiles ? result.data.profiles : [],
                isLoadingProfiles: false
            });
        }).catch(() => {
            this.setState({isLoadingProfiles: false});
        });
    }

    logout = () => {
        this.context.setCurrentProfile(null, null);
        this.context.setAuthToken(null);
        this.props.history.push('/');
        this.props.toggle();
    };

    handleViewProfile = (profileId) => {
        this.props.history.push(`/profile/${profileId}`);
        this.props.toggle();
    }

    navigateTo = (route) => {
        this.props.history.push(route);
        this.props.toggle();
    }

    render() {
        return (
                <div className={classes.ContainerInner}>
                    <button className={sharedClasses.Exit} onClick={this.props.toggle}>
                        <CloseIcon />
                    </button>
                    {!!this.context.currentProfile &&
                    <div className={classes.ActiveProfile}>
                        <div className={classes.ActiveProfileImage}>
                            <ProfilePhotoWithDefault
                                logo={!!this.context.currentProfile ? this.context.currentProfile.logo : null}
                                name={!!this.context.currentProfile ? this.context.currentProfile.name : null}/>
                        </div>
                        <h3>{!!this.context.currentProfile ? this.context.currentProfile.name : null}</h3>
                        <SubmitButton className={classes.ViewProfileButton}
                                      onClick={() => this.handleViewProfile(this.context.currentProfile.profileId)}>
                            View profile
                        </SubmitButton>
                    </div>
                    }
                    <div className={classes.OtherProfilesHeader}>
                        Other profiles
                    </div>
                    <Expand open={this.state.profiles.length > 0} duration={200}>
                        <div className={classes.Profiles}>
                            {this.state.profiles.filter(profile => !this.context.currentProfile || profile.profileId !== this.context.currentProfile.profileId).map(profile =>
                                <div className={classes.Profile} onClick={e => {e.stopPropagation(); this.props.setCurrentProfile(profile)}}>
                                    <div className={classes.LogoContainer}>
                                        <ProfilePhotoWithDefault logo={profile.logo} name={profile.name}/>
                                    </div>
                                    <div className={classes.ProfileName}>
                                        {profile.name}
                                    </div>
                                </div>
                            )}
                            <PopupContext.Consumer>
                                {popupContext => (
                                    <div className={classes.Profile} onClick={() => popupContext.openCreateProfilePopUp()}>
                                        <div className={classes.LogoContainer}>
                                            <i className="fas fa-user-plus" />
                                        </div>
                                        <div className={classes.ProfileName}>
                                            Create a page
                                        </div>
                                    </div>
                                )}
                            </PopupContext.Consumer>
                        </div>
                    </Expand>
                    <div className={classes.LogoutContainer}>
                        <SubmitButton className={classes.LogoutButton} onClick={this.logout}>
                            <i className="fas fa-sign-out-alt"/>
                            Logout
                        </SubmitButton>
                    </div>
                </div>
        );
    }
}

export default withRouter(AccountDropdown);
