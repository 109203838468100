import React, {Component} from "react";
import classes from "./MessageButton.module.css";

class MessageButton extends Component {

    render() {
        return (
            <div className={classes.MessageButton}
                 onClick={this.props.onClick}>
                Message
            </div>
        );
    }
}

export default MessageButton;
