export const FundingStageFilterOptions = [
    {
        id: 'BOOTSTRAPPING',
        displayName: 'Bootstrapping'
    },
    {
        id: 'CROWD_FUNDING',
        displayName: 'CrowdFunding'
    },
    {
        id: 'PRE_SEED',
        displayName: 'Pre-seed'
    },
    {
        id: 'SEED',
        displayName: 'Seed'
    },
    {
        id: 'SERIES_A',
        displayName: 'Series A'
    },
    {
        id: 'SERIES_B',
        displayName: 'Series B'
    },
    {
        id: 'SERIES_C',
        displayName: 'Series C'
    },
    {
        id: 'SERIES_D',
        displayName: 'Series D'
    },
    {
        id: 'PUBLIC',
        displayName: 'Public'
    },
    {
        id: 'PRIVATE',
        displayName: 'Private'
    },
    {
        id: 'NON_PROFIT',
        displayName: 'Non-profit'
    }
];