import React, {Component} from "react";
import classes from "./FollowButton.module.css";

class FollowButton extends Component {

    render() {
        return (
            <div className={this.props.isFollowing ? [classes.FollowButton, classes.FollowButtonFollowing].join(' ') : classes.FollowButton}
                 onClick={this.props.onClick}>
                {this.props.isFollowing ? 'Following' : 'Follow'}
            </div>
        );
    }
}

export default FollowButton;
