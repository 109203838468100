import React, {Component} from "react";
import classes from './WebLinkPreview.module.css';
import {withRouter} from "react-router-dom";

class WebLinkPreview extends Component {
    
    render() {
        let description = this.props.description;
        if (!!description && description.length > 150) {
            description = description.slice(0,150) + '...'
        }
        return (
            !!this.props.title && !!this.props.img && !!this.props.description &&
                <a href={this.props.previewUrl} target="_blank" className={classes.LinkContainer}>
                    <div className={classes.PreviewContainer}>
                        <div className={classes.PreviewContainerLeft}>
                            <img src={this.props.img} />
                        </div>
                        <div className={classes.PreviewContainerRight}>
                            <div>
                                <h2>{this.props.title}</h2>
                                <div>{description}</div>
                            </div>
                        </div>
                    </div>
                </a>
        );
    }
}

export default withRouter(WebLinkPreview);
