import React, {Component, Fragment} from "react";
import classes from "./Recommendations.module.css";
import AppContext from '../../context/AppProvider';
import PlaceHolder from "./PlaceHolder/PlaceHolder";
import {RecommendationsMatchType} from "../../constants/RecommendationMatchTypeConstants";
import ProfilePhotoWithDefault from "../ProfilePage/ProfilePhotoWithDefault/ProfilePhotoWithDefault";
import {ProfileTypes} from "../../constants/ProfileTypeConstants";
import {withRouter} from "react-router-dom";
import FollowButton from "../FollowButton/FollowButton";
import PopupContext from "../../context/PopupsProvider";
import SubmitButton from "../Forms/SubmitButton/SubmitButton";

const RecommendationsLimit = 5;

class Recommendations extends Component {

    static contextType = AppContext;

    state = {
        profiles: [],
        isLoading: true,
        hadError: false
    }

    componentDidMount() {
        this.initRecommendations();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.profileId === this.props.profileId && prevProps.toMatchWith === this.props.toMatchWith) {
            return;
        }
        this.initRecommendations();
    }

    initRecommendations = () => {
        this.setState({isLoading: true});
        const requesterId = !!this.context.currentProfile ? this.context.currentProfile.profileId : '';
        this.context.apiGatewayClient.getRecommendations(this.props.profileId, this.props.toMatchWith, RecommendationsLimit, requesterId).then(result => {
            this.setState({profiles: !!result.data && !!result.data.profiles ? result.data.profiles : [], isLoading: false})
        }).catch(() => { // TODO: set undefined to null
            this.context.apiGatewayClient.getRecommendations(undefined, RecommendationsMatchType.Both, RecommendationsLimit, requesterId).then(result => {
                this.setState({profiles: !!result.data && !!result.data.profiles ? result.data.profiles : [], isLoading: false, hadError: true})
            });
        });
    }

    handleFollowClick = (profile, popupContext) => {
        if (!this.context.currentProfile) {
            popupContext.openSignInPopUp();
            return;
        }

        if (this.state.profiles.find(p => profile.profileId === p.profileId).isFollowing) {
            this.context.apiGatewayClient.unFollow(profile.profileId, this.context.currentProfile.profileId);
        } else {
            this.context.apiGatewayClient.follow(profile.profileId, this.context.currentProfile.profileId);
        }

        this.setState({profiles: this.state.profiles.map(p => {
            if (profile.profileId === p.profileId) {
                p.isFollowing = !p.isFollowing;
            }
            return p;
        })})
    }

    navigateToProfile = (profileId) => {
        this.props.history.push(`/profile/${profileId}`);
    }

    render() {
        let title = null;
        if (this.state.hadError) {
            title = 'Recommended';
        } else if (this.props.toMatchWith === RecommendationsMatchType.Investor) {
            title = !!this.props.name ? `Similar investors to ${this.props.name}` : 'Similar investors';
        } else if (this.props.toMatchWith === RecommendationsMatchType.Startup) {
            title = !!this.props.name ? `Similar startups to ${this.props.name}` : 'Similar startups';
        } else if (this.props.toMatchWith === RecommendationsMatchType.Both) {
            title = 'Recommended';
        }

        return (
            <div className={classes.Container}>
                <h2>{title}</h2>
                {!this.state.isLoading && this.state.profiles.map(profile =>
                    <div className={classes.Recommendation}>
                        <div className={classes.RecommendationTitle}>
                            <div className={classes.Logo} onClick={() => this.navigateToProfile(profile.profileId)}>
                                <ProfilePhotoWithDefault logo={profile.logo} name={profile.name}/>
                            </div>
                            <div className={classes.Title}>
                                <h3 onClick={() => this.navigateToProfile(profile.profileId)}>{!!profile.name && profile.name.length > 17 ? profile.name.substring(0, 14) + '...' : profile.name}</h3>
                                <div className={classes.RecommendationSubtitle}>
                                    <span>{ProfileTypes.find(pt => pt.id === profile.profileType).displayName}</span>
                                </div>
                            </div>
                            <div className={classes.FollowButtonContainer}>
                                <PopupContext.Consumer>
                                    {popupContext => (
                                        <FollowButton onClick={() => this.handleFollowClick(profile, popupContext)}
                                                      isFollowing={profile.isFollowing}/>
                                    )}
                                </PopupContext.Consumer>
                            </div>
                        </div>
                        <div>
                            {!!profile.description && profile.description.length > 100 ? profile.description.substring(0, 100) + '...' : profile.description}
                        </div>
                    </div>
                )}
                {this.state.isLoading && <Placeholders />}
            </div>
        );
    }
}

const Placeholders = () => {
    return (
        <Fragment>
            <PlaceHolder />
            <PlaceHolder />
            <PlaceHolder />
        </Fragment>
    )
}

export default withRouter(Recommendations);
