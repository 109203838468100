import React, {Component, createRef} from "react";

import classes from './FollowersPopup.module.css'
import AppContext from "../../../context/AppProvider";
import {withRouter} from "react-router-dom";
import ProfilePhotoWithDefault from "../ProfilePhotoWithDefault/ProfilePhotoWithDefault";
import FollowButton from "../../FollowButton/FollowButton";
import {CloseIcon} from "../../Icons/CloseIcon";
import PopupContext from "../../../context/PopupsProvider";

const Tabs = {
    Following: 'Following',
    Followers: 'Followers'
}

class FollowersPopup extends Component {

    static contextType = AppContext;

    state = {
        wrapperRef: createRef(),
        selectedTab: Tabs.Followers
    }

    isFollowing = (profiles, profileId) => {
        if (!profiles) {
            return false;
        }
        return profiles.filter(profile => profile.profileId === profileId).length > 0;
    }

    setTab = (tab) => {
        this.setState({selectedTab: tab});
    }

    navigateToProfile = (profileId) => {
        this.props.history.push(`/profile/${profileId}`);
        this.props.close();
    }

    render() {
        let profiles = this.state.selectedTab === Tabs.Following ?
            this.props.following
            : this.props.followers;
        if (!profiles) {
            profiles = [];
        }
        return (
            <div className={classes.Container} ref={this.state.wrapperRef}>
                <div className={classes.Header}>
                    <div className={classes.Close} onClick={e => {e.stopPropagation(); this.props.close()}}>
                        <CloseIcon />
                    </div>
                    <div className={this.state.selectedTab === Tabs.Followers ? [classes.HeaderItem, classes.HeaderActive].join(' ') : classes.HeaderItem} onClick={() => this.setTab(Tabs.Followers)}>
                        Followers ({this.props.followers.length})
                    </div>
                    <div className={this.state.selectedTab === Tabs.Following ? [classes.HeaderItem, classes.HeaderActive].join(' ') : classes.HeaderItem} onClick={() => this.setTab(Tabs.Following)}>
                        Following ({this.props.following.length})
                    </div>
                </div>
                <div className={classes.ProfileList}>
                    {profiles.map(profile =>
                        <div className={classes.FollowerRow}>
                            <div className={classes.Logo} onClick={e => {e.stopPropagation(); this.navigateToProfile(profile.profileId);}}>
                                <ProfilePhotoWithDefault logo={profile.logo} name={profile.name}/>
                            </div>
                            <div className={classes.NameMetadataContainer}>
                                <div className={classes.Name} onClick={e => {e.stopPropagation(); this.navigateToProfile(profile.profileId);}}>
                                    {profile.name}
                                </div>
                                <div className={classes.Metadata}>
                                    {profile.location}
                                </div>
                            </div>
                            {(!this.context.currentProfile || profile.profileId !== this.context.currentProfile.profileId) &&
                            <PopupContext.Consumer>
                                {popupContext => (
                                <FollowButton onClick={() => this.props.handleFollowButtonClicked(profile, popupContext)}
                                              isFollowing={this.isFollowing(this.props.myFollowing, profile.profileId)}/>
                                )}
                            </PopupContext.Consumer>
                            }
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default withRouter(FollowersPopup);
