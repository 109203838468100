import React, {Component} from "react";
import classes from "../CreateProfile.module.css";
import SubmitButton from "../../Forms/SubmitButton/SubmitButton";
import SingleSelectCards from "../../Forms/Cards/SingleSelectCards";
import {InvestorTypeFilterOptions} from "../../../constants/filteroptions/InvestorTypeFilterOptions";
import sharedClasses from "../CreateProfile.module.css";

class TypeStep extends Component {

    render() {
        return (
            <div>
                <div className={classes.CreateProfileHeader}>What type of investor are you?</div>
                <div className={sharedClasses.CreateProfileDescription}>Indicate what type of investor you are and make it easier for startups to find you.</div>
                <div className={classes.FormContainer}>
                    <SingleSelectCards options={InvestorTypeFilterOptions}
                                       setSelected={(option) => this.props.setInvestorType(option.id)}
                                       currentlySelected={InvestorTypeFilterOptions.find(option => option.id === this.props.investorType)}/>
                </div>
                <div className={classes.Navigation}>
                    <SubmitButton onClick={this.props.previousStep} className={sharedClasses.PreviousButton}>Previous</SubmitButton>
                    <SubmitButton onClick={this.props.nextStep}
                                  isDisabled={!this.props.investorType}
                                  className={classes.NextButton}>Next</SubmitButton>
                </div>
                <div className={classes.PageDetailsContainer}>
                    2 of 4
                </div>
            </div>
        )
    }
}

export default TypeStep;
