import axios from 'axios';


class S3Client {

    uploadImage = (image, url) => {
        const config = {
            headers: {
                'Content-Type': 'application/octet-stream',
            }
        };

        return axios.put(url, image, config);
    };
}

export default S3Client;