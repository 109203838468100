import React, {Component} from 'react';
import Description from '../Description/Description';
import classes from "./PersonalInformation.module.css";
import sharedClasses from '../Styles/SharedStyles.module.css';
import EditOverview from "../EditableField/Overview/EditOverview";

class PersonalInformation extends Component {

    render() {
        return (
            <div className={sharedClasses.Container}>
                {this.props.canEdit &&
                <div className={classes.EditIconContainer}>
                    <EditOverview profile={this.props.profile}
                                  setProfile={this.props.setProfile}
                                  title={'Biography'}/>
                </div>
                }
                <div className={sharedClasses.Section}>
                    <Description description={this.props.profile.description}/>
                </div>
            </div>
        );
    }
}

export default PersonalInformation;
