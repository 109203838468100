import React, {Component, createRef, Fragment} from "react";
import {NavLink} from "react-router-dom";
import {withRouter} from "react-router-dom";
import classes from './Conversations.module.css';
import ProfilePhotoWithDefault from "../../ProfilePage/ProfilePhotoWithDefault/ProfilePhotoWithDefault";
import AppContext from "../../../context/AppProvider";
import Conversation from "../Conversation/Conversation";
import PlaceHolder from "./PlaceHolder/PlaceHolder";
import {ArrowLeftIcon} from "../../Icons/ArrowLeftIcon";
import CreateConversation from "../CreateConversation/CreateConversation";
import moment from "moment";
import {EditIcon} from "../../Icons/EditIcon";
import {ProfileType} from "../../../constants/ProfileTypeConstants";
import InvestorSubTitle from "../../ProfilePage/InvestorSubTitle/InvestorSubTitle";
import CompanySubTitle from "../../ProfilePage/CompanySubTitle/CompanySubTitle";
import PersonalSubTitle from "../../ProfilePage/PersonalSubTitle/PersonalSubTitle";
import Spinner from "../../Spinner/spinner";


class Conversations extends Component {

    static contextType = AppContext;

    state = {
        scrollRef: createRef(),
        selectedConversation: null,
        conversations: [],
        isLoading: false,
        isInCreateMode: false,
        lastEvaluatedKey: null,
        reachedEndOfResults: false
    }

    componentDidMount = async () => {
        this.setState({conversations: [], isLoading: true}, async () => {
            if (this.props.location.pathname.includes('messages') && this.props.location.pathname.includes('create')) {
                this.setState({isInCreateMode: true}, this.init);
            } else {
                await this.init();
            }
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps === this.props) {
            return;
        }
        this.init();
    }

    init = async () => {
        const result = await this.fetchConversations();

        let selectedConversation;
        if (this.props.location.pathname.includes('messages')) {
            selectedConversation = result.conversations.find(conversation => conversation.conversationId === this.props.match.params.conversationId);
        }

        this.setState({
            conversations: result.conversations,
            isLoading: false,
            lastEvaluatedKey: result.lastEvaluatedKey,
            reachedEndOfResults: !result.lastEvaluatedKey,
            selectedConversation: selectedConversation
        });
    }

    handleScroll = async (e) => {
        if (this.state.isLoading || this.state.reachedEndOfResults) {
            return;
        }
        if (e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 1000) {
            this.setState({isLoading: true}, async () => {
                const result = await this.fetchConversations();
                this.setState({
                    conversations: [...this.state.conversations, ...result.conversations],
                    isLoading: false,
                    lastEvaluatedKey: result.lastEvaluatedKey,
                    reachedEndOfResults: result.conversations.length === 0 || !result.lastEvaluatedKey
                })
            });
        }
    };

    setSelectedConversation = (selectedConversation) => {
        // TODO: this is horrendous code. This relies on 'messages' only appearing in the URI for the messages link
        if (this.props.location.pathname.includes('messages')) {
            if (!!selectedConversation) {
                this.props.history.push(`/messages/${selectedConversation.conversationId}`);
            } else {
                this.props.history.push('/messages');
            }
        }
        this.setState({selectedConversation: selectedConversation, isInCreateMode: false});
    }

    toggleCreateMode = (e) => {
        e.stopPropagation();
        if (this.props.location.pathname.includes('messages')) {
            if (this.state.isInCreateMode) {
                this.props.history.push('/messages');
            } else {
                this.props.history.push('/messages/create');
            }
        }
        this.setState({isInCreateMode: !this.state.isInCreateMode, selectedConversation: null});
        if (!!this.props.onCreateButtonClick) {
            this.props.onCreateButtonClick();
        }
    }

    navigateToConversation = async (conversationId) => {
        const result = await this.fetchConversations();
        this.setState({conversations: result.conversations, isInCreateMode: false}, () => {
            this.setSelectedConversation(this.state.conversations.find(conversation => conversation.conversationId === conversationId));
        })
    }

    // TODO: lot of duplication here
    onMessageCreation = async () => {
        const result = await this.fetchConversations();
        this.setState({
            conversations: result.conversations,
            isLoading: false,
            lastEvaluatedKey: result.lastEvaluatedKey,
            reachedEndOfResults: !result.lastEvaluatedKey
        })
    }

    fetchConversations = async () => {
        const result = (await this.context.appSyncClient.getConversations(this.context.currentProfile.profileId, this.state.lastEvaluatedKey))['getConversations'];
        const enrichedConversations = await Promise.all(result['conversations'].map(async conversation => {
            const otherProfile = conversation.participants.filter(participant => participant.profileId !== this.context.currentProfile.profileId)[0];
            const profile = (await this.context.apiGatewayClient.getProfile(otherProfile.profileId, this.context.currentProfile.profileId)).data.profile;
            return {
                ...conversation,
                otherProfile: profile
            }
        }));
        return {
            conversations: enrichedConversations,
            lastEvaluatedKey: result['lastEvaluatedKey']
        }
    }

    onHeaderClick = () => {
        if (!!this.props.onHeaderClick) {
            this.props.onHeaderClick();
        }
    }

    getMessagePreview = (content) => {
        return !!content && content.length > 150
            ? content.slice(0,150) + '...'
            : content;
    }

    render() {
        return (
            <Fragment>
                <div className={classes.Header} onClick={this.onHeaderClick}>
                    {!!this.state.selectedConversation &&
                    <Fragment>
                        <div onClick={e => {e.stopPropagation(); this.setSelectedConversation(null)}} className={classes.Icon}>
                            <ArrowLeftIcon />
                        </div>
                        <div className={classes.HeaderTitle}>
                            <NavLink to={`/profile/${this.state.selectedConversation.otherProfile.profileId}`}>{this.state.selectedConversation.otherProfile.name}</NavLink>
                            <div className={classes.ProfileMetadata}>
                                {this.state.selectedConversation.otherProfile.profileType === ProfileType.Investor &&
                                <InvestorSubTitle profile={this.state.selectedConversation.otherProfile}
                                                  charLimit={20}/>
                                }
                                {this.state.selectedConversation.otherProfile.profileType === ProfileType.Startup &&
                                <CompanySubTitle profile={this.state.selectedConversation.otherProfile}
                                                 charLimit={20}/>
                                }
                                {this.state.selectedConversation.otherProfile.profileType === ProfileType.Personal &&
                                <PersonalSubTitle profile={this.state.selectedConversation.otherProfile}
                                                  charLimit={20}/>
                                }
                            </div>
                        </div>
                        <div className={classes.HeaderIcons}>
                            <div className={classes.Icon} onClick={this.toggleCreateMode}>
                                <EditIcon />
                            </div>
                            {!!this.props.toggleIcon &&
                            <div className={classes.Icon} onClick={this.props.toggle}>
                                {this.props.toggleIcon}
                            </div>
                            }
                        </div>
                    </Fragment>
                    }
                    {!this.state.selectedConversation && !this.state.isInCreateMode &&
                    <Fragment>
                        <div className={classes.HeaderLogo}>
                            <ProfilePhotoWithDefault logo={this.context.currentProfile.logo} name={this.context.currentProfile.name} />
                        </div>
                        <div className={classes.HeaderTitle}>
                            <h3>Messages</h3>
                        </div>
                        <div className={classes.HeaderIcons}>
                            <div className={classes.Icon} onClick={this.toggleCreateMode}>
                                <EditIcon />
                            </div>
                            {!!this.props.toggleIcon &&
                            <div className={classes.Icon} onClick={this.props.toggle}>
                                {this.props.toggleIcon}
                            </div>
                            }
                        </div>
                    </Fragment>
                    }
                    {this.state.isInCreateMode &&
                    <Fragment>
                        <div onClick={this.toggleCreateMode} className={classes.Icon}>
                            <ArrowLeftIcon />
                        </div>
                        <div className={classes.HeaderTitle}>
                            <h3>New message</h3>
                        </div>
                        <div className={classes.HeaderIcons}>
                            {!!this.props.toggleIcon &&
                            <div className={classes.Icon} onClick={this.props.toggle}>
                                {this.props.toggleIcon}
                            </div>
                            }
                        </div>
                    </Fragment>
                    }
                </div>
                {this.state.isLoading && this.state.conversations.length === 0 && !this.state.isInCreateMode && !this.state.selectedConversation &&
                    <Fragment>
                        <PlaceHolder />
                        <PlaceHolder />
                        <PlaceHolder />
                        <PlaceHolder />
                    </Fragment>
                }
                {!this.state.selectedConversation && !this.state.isInCreateMode &&
                <div className={classes.Conversations} onScroll={this.handleScroll} ref={this.state.scrollRef}>
                    {!! this.state.conversations && this.state.conversations.map(conversation => (
                        <div className={classes.Message} onClick={() => this.setSelectedConversation(conversation)}>
                            <div className={classes.MessageLeft}>
                                <div className={classes.Logo}>
                                    <ProfilePhotoWithDefault logo={conversation.otherProfile.logo} name={conversation.otherProfile.name}/>
                                </div>
                            </div>
                            <div className={classes.MessageRight}>
                                <div className={classes.MessageTitle}>
                                    <div className={classes.MessageName}>
                                        {conversation.otherProfile.name}
                                    </div>
                                    <div className={classes.Timestamp}>
                                        {moment.unix(parseInt(conversation.mostRecentMessage.sentAtDateEpoch) / 1000).fromNow()}
                                    </div>
                                </div>
                                <div className={classes.MessageContent}>
                                    <pre>
                                        {this.getMessagePreview(conversation.mostRecentMessage.content)}
                                    </pre>
                                </div>
                            </div>
                        </div>
                    ))}
                    {!this.state.reachedEndOfResults && !!this.state.lastEvaluatedKey && this.state.isLoading &&
                    <div className={classes.SpinnerContainer}>
                        <Spinner/>
                    </div>
                    }
                </div>
                }
                {!!this.state.selectedConversation &&
                <Conversation selectedConversation={this.state.selectedConversation}
                              onMessageCreation={this.onMessageCreation}/>
                }
                {this.state.isInCreateMode &&
                <CreateConversation navigateToConversation={this.navigateToConversation}/>
                }
            </Fragment>
        );
    }
}

export default withRouter(Conversations);
