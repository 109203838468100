import React, {Component, createRef, Fragment} from "react";
import classes from './FeedSubmit.module.css';
import {withRouter} from "react-router-dom";
import {PhotoIcon} from "../../../Icons/PhotoIcon";
import {VideoIcon} from "../../../Icons/VideoIcon";
import SubmitButton from "../../../Forms/SubmitButton/SubmitButton";
import Image from "../../../../model/Image";
import {CloseIcon} from "../../../Icons/CloseIcon";
import {FeedAssetsBucketPrefix} from "../../../../constants/FeedAssetsBucketPrefix";
import GlobalPopup from "../../../Popup/GlobalPopup/GlobalPopup";
import {ExclamationIcon} from "../../../Icons/ExclamationIcon";


const MAX_FILE_SIZE_MB = 10;

class FeedSubmit extends Component {

    state = {
        imageInputRef: createRef(),
        videoInputRef: createRef(),
        images: !!this.props.images ? this.props.images : [],
        videos: !!this.props.videos ? this.props.videos : [],
        shouldShowFileToLargeAlert: false,
    }

    onSubmit = () => {
        const images = this.state.images;
        const videos = this.state.videos;
        this.setState({images: [], videos: []});
        this.props.onSubmit(images, videos);
    }

    handleImageOptionClick = () => {
        this.state.imageInputRef.current.click();
    };

    handleVideoOptionClick = () => {
        this.state.videoInputRef.current.click();
    };

    handleUploadedImage = (e) => {
        const [file] = e.target.files;
        if (file.size / 1000000 > MAX_FILE_SIZE_MB) {
            this.setState({shouldShowFileToLargeAlert: true});
            return;
        }
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                this.setState({images: [...this.state.images, new Image(file, event.target.result)]});
            };
            reader.readAsDataURL(file);
        }
    };

    handleUploadedVideo = (e) => {
        const [file] = e.target.files;
        if (file.size / 1000000 > MAX_FILE_SIZE_MB) {
            this.setState({shouldShowFileToLargeAlert: true});
            return;
        }
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                this.setState({videos: [...this.state.videos, new Image(file, event.target.result)]});
            };
            reader.readAsDataURL(file);
        }
    };

    removeImage = (image) => {
        this.setState({images: this.state.images.filter(img => image !== img)});
    }

    removeVideo = (video) => {
        this.setState({videos: this.state.videos.filter(vid => video !== vid)});
    }

    closeFileSizePopup = () => {
        this.setState({shouldShowFileToLargeAlert: false});
    }

    render() {
        return (
            <Fragment>
                {this.state.shouldShowFileToLargeAlert &&
                <GlobalPopup onOutsideClick={this.closeFileSizePopup}>
                    <div className={classes.FileSizePopup}>
                        <div className={classes.Exclamation}>
                            <ExclamationIcon />
                        </div>
                        <div className={classes.FileSizePopupText}>
                            File size cannot exceed {MAX_FILE_SIZE_MB}MB
                        </div>
                        <SubmitButton onClick={this.closeFileSizePopup} className={classes.FileSizePopupButton}>
                            Close
                        </SubmitButton>
                    </div>
                </GlobalPopup>
                }
                <div className={classes.MediaPreviewContainer}>
                    {this.state.images.map(image => (
                        <div className={classes.ImagePreview}>
                            <img src={image.isUploadedLocally ? `${FeedAssetsBucketPrefix}${image.src}` : image.src} />
                            <div className={classes.RemoveMedia} onClick={() => this.removeImage(image)}>
                                <CloseIcon />
                            </div>
                        </div>
                    ))}
                    {this.state.videos.map(video => (
                        <div className={classes.VideoPreview}>
                            <video controls muted name="media" autoPlay="autoplay"
                                   src={video.isUploadedLocally ? `${FeedAssetsBucketPrefix}${video.src}` : video.src}/>
                            <div className={classes.RemoveMedia} onClick={() => this.removeVideo(video)}>
                                <CloseIcon />
                            </div>
                        </div>
                    ))}
                </div>
                <div className={classes.MediaOptions}>
                    <input ref={this.state.imageInputRef} type="file" accept={['.png', '.jpg']} multiple={false} onChange={e => {
                        this.handleUploadedImage(e);
                        this.state.imageInputRef.current.value = '';
                    }}/>
                    <div className={classes.MediaOption} onClick={this.handleImageOptionClick}>
                        <PhotoIcon /> Photo
                    </div>
                    <input ref={this.state.videoInputRef} type="file" accept={['video/*']} multiple={false} onChange={e => {
                        this.handleUploadedVideo(e);
                        this.state.videoInputRef.current.value = '';
                    }}/>
                    <div className={classes.MediaOption} onClick={this.handleVideoOptionClick}>
                        <VideoIcon /> Video
                    </div>
                    <div className={classes.SubmitButtonContainer}>
                        {this.props.shouldShowCancel &&
                        <SubmitButton onClick={this.props.onCancel}
                                      className={classes.Cancel}>
                            Cancel
                        </SubmitButton>
                        }
                        <SubmitButton isSubmitting={this.props.isSubmitting}
                                      onClick={this.onSubmit}
                                      className={classes.FeedSubmit}
                                      isDisabled={this.props.isDisabled && this.state.images.length === 0 && this.state.videos.length === 0}>
                            Post
                        </SubmitButton>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default withRouter(FeedSubmit);
