import React, {Component, createRef, Fragment} from 'react';
import InvestorSearchResult from './SearchResult/InvestorSearchResult';
import {InvestorSearchFilters} from '../../constants/InvestorSearchFilters';
import {CompanySearchFilters} from '../../constants/CompanySearchFilters';
import NoResults from './NoResults/noresults';
import classes from "./Explore.module.css";
import sharedClasses from "../../styles/Styles.module.css";
import AppContext from '../../context/AppProvider';
import CompanySearchResult from "./SearchResult/CompanySearchResult";
import Placeholder from "./PlaceHolder/PlaceHolder";
import {NavLink, withRouter} from "react-router-dom";
import Recommendations from "../Recommendations/Recommendations";
import _ from "lodash";
import {SearchContext} from "../../constants/SearchContexts";
import PersonalSearchResult from "./SearchResult/PersonalSearchResult";
import Spinner from "../Spinner/spinner";
import {RecommendationsMatchType} from "../../constants/RecommendationMatchTypeConstants";
import SelectedContext from "../NavigationBar/SelectedContext/SelectedContext";
import SearchFilters from "./SearchFilters/SearchFilters";
import SideNav from "../SideNav/SideNav";
import {getUpdatedSelectedSearchFilters} from "../NavigationBar/Util/SearchUtil";
import SubmitButton from "../Forms/SubmitButton/SubmitButton";
import {ProfileType} from "../../constants/ProfileTypeConstants";
import PopupContext from "../../context/PopupsProvider";

const queryString = require('query-string');

class Explore extends Component {

    static contextType = AppContext;

    state = {
        searchContext: null,
        fullTextQuery: '',
        selectedFilters: [],
        results: [],
        reachedEndOfResults: false,
        resultsAll: [SearchContext.Investors, SearchContext.Startups, SearchContext.Personal].reduce((map, searchContext) => {
            map[searchContext] = [];
            return map;
        }, {}),
        totalAll: [SearchContext.Investors, SearchContext.Startups, SearchContext.Personal].reduce((map, searchContext) => {
            map[searchContext] = 0;
            return map;
        }, {}),
        isLoading: false,
        total: 0,
        pageNumber: 0,
        pageSize: 20,
        queryNum: 0,

        ref: createRef()
    };

    componentDidMount() {
        this.initSearchContext();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps === this.props) {
            return;
        }
        this.initSearchContext();
    }

    getSearchFilters = (context) => {
        switch (context) {
            case SearchContext.Investors:
                return InvestorSearchFilters;
            case SearchContext.Startups:
                return CompanySearchFilters;
            default:
                return [];
        }
    }

    initSearchContext = () => {
        let searchContext;
        if (this.props.location.pathname.includes('investors')) {
            searchContext = SearchContext.Investors;
        } else if (this.props.location.pathname.includes('companies')) {
            searchContext = SearchContext.Startups;
        } else if (this.props.location.pathname.includes('people')) {
            searchContext = SearchContext.Personal;
        } else {
            searchContext = SearchContext.All;
        }
        const searchFilters = this.getSearchFilters(searchContext);
        const parsed = queryString.parse(this.props.location.search);
        const selectedFilters = searchFilters.map(searchFilter => {
            const clone = _.cloneDeep(searchFilter);
            clone.filterOptions = [];
            if (!!parsed[searchFilter.id]) {
                const ids = parsed[searchFilter.id].split(',')
                clone.filterOptions = searchFilters.filter(sf => sf.id === searchFilter.id)[0].filterOptions.filter(filterOption => ids.includes(filterOption.id));
            }
            return clone;
        })
        this.setState({fullTextQuery: !!parsed['query'] ? parsed['query'] : '',selectedFilters: selectedFilters, searchContext: searchContext, results: [], pageNumber: 0}, this.query);
    }

    handleScroll = (e) => {
        if (this.state.isLoading || this.state.reachedEndOfResults || this.state.searchContext === SearchContext.All) {
            return;
        }
        if (e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 1000) {
            this.setState({pageNumber: this.state.pageNumber + 1}, this.query);
        }
    };

    query = () => {
        const filters = {};
        this.state.selectedFilters.forEach(filter => {
            filters[filter.id] = filter.filterOptions.map(filterOption => filterOption.id);
        });
        const nextQueryNum = this.state.queryNum + 1;
        this.setState({isLoading: true, queryNum: nextQueryNum}, () => {
            if (this.state.searchContext === SearchContext.All) {
                Promise.all([
                    this.context.apiGatewayClient.query(SearchContext.Investors, this.state.fullTextQuery, filters, 0, 3),
                    this.context.apiGatewayClient.query(SearchContext.Startups, this.state.fullTextQuery, filters, 0, 3),
                    this.context.apiGatewayClient.query(SearchContext.Personal, this.state.fullTextQuery, filters, 0, 3)
                ]).then(([investorResult, companyResult, personalResult]) => {
                    const resultsAll = {};
                    const totalAll = {}
                    resultsAll[SearchContext.Investors] = investorResult.data.profiles.filter(i => !!i);
                    resultsAll[SearchContext.Startups] = companyResult.data.profiles.filter(i => !!i);
                    resultsAll[SearchContext.Personal] = personalResult.data.profiles.filter(i => !!i);
                    totalAll[SearchContext.Investors] = investorResult.data.total;
                    totalAll[SearchContext.Startups] = companyResult.data.total;
                    totalAll[SearchContext.Personal] = personalResult.data.total;
                    this.setState({resultsAll: resultsAll, totalAll: totalAll, isLoading: false});
                });
            } else {
                this.context.apiGatewayClient.query(this.state.searchContext, this.state.fullTextQuery, filters, this.state.pageNumber, this.state.pageSize).then(result => {
                    if (this.state.queryNum === nextQueryNum) {
                        this.setState({
                            results: [...this.state.results, ...result.data.profiles.filter(i => !!i)],
                            total: result.data.total,
                            reachedEndOfResults: result.data.profiles.length < this.state.pageSize,
                            isLoading: false
                        });
                    }
                    if (this.state.pageNumber === 0 && !!this.state.ref && !!this.state.ref.current) {
                        this.state.ref.current.scrollTo({top: 0, behavior: 'smooth'});
                    }
                }).catch(() => {
                    this.setState({isLoading: false});
                });
            }
        });
    };

    clearFilters = () => {
        this.setState({
            searchContext: SearchContext.All,
            selectedFilters: this.getSearchFilters(this.state.searchContext).map(filter => {
                const clone = _.cloneDeep(filter);
                clone.filterOptions = [];
                return clone;
            })
        }, this.submitQuery);
    };

    setSearchContext = (searchContext) => {
        this.clearFilters();
        if (this.state.searchContext === searchContext) {
            this.setState({selectedFilters: this.getSearchFilters(searchContext).map(filter => {
                    const clone = _.cloneDeep(filter);
                    clone.filterOptions = [];
                    return clone;
                }), searchContext: null}, this.submitQuery);
        } else {
            this.setState({selectedFilters: this.getSearchFilters(searchContext).map(filter => {
                    const clone = _.cloneDeep(filter);
                    clone.filterOptions = [];
                    return clone;
                }), searchContext: searchContext}, this.submitQuery);
        }
    };

    getRedirectPath = (searchContext) => {
        switch (searchContext) {
            case SearchContext.Investors:
                return '/explore/investors';
            case SearchContext.Startups:
                return '/explore/companies';
            case SearchContext.Personal:
                return '/explore/people';
            default:
                return '/explore/all';
        }
    }

    submitQuery = () => {
        const params = {}
        this.state.selectedFilters.filter(filter => filter.filterOptions.length > 0).forEach(filter => {
            params[filter.id] = filter.filterOptions.map(filterOption => filterOption.id).join(',')
        })
        params['query'] = this.state.fullTextQuery;
        this.setState({suggestions: []})
        this.props.history.push({
            pathname: this.getRedirectPath(this.state.searchContext),
            search: "?" + new URLSearchParams(params).toString()
        });
    };

    toggleFilter = (filter, filterOption) => {
        const newSelectedFilters = getUpdatedSelectedSearchFilters(filter, filterOption, this.state.selectedFilters);
        this.setState({selectedFilters: newSelectedFilters, results: [], total: 0, pageNumber: 0}, this.submitQuery);
    };

    render() {
        const searchFilters = this.getSearchFilters(this.state.searchContext);
        let numberOfFiltersSelected = this.state.selectedFilters.reduce((count, filter) => count + filter.filterOptions.length, 0);
        if (this.state.searchContext !== SearchContext.All) {
            numberOfFiltersSelected += 1;
        }

        return (
            <div className={sharedClasses.ContentContainer} onScroll={this.handleScroll} ref={this.state.ref}>
                <div className={sharedClasses.ContentContainerInner}>
                    <div className={classes.SideNav}>
                        <SideNav openMessages={this.props.openMessages}/>
                    </div>
                    <div className={classes.SearchResults}>
                        <div className={classes.Header} ref={this.state.filtersRef}>
                            {this.state.searchContext !== SearchContext.All &&
                            <SelectedContext setSearchContext={this.setSearchContext}
                                             searchContext={this.state.searchContext}/>
                            }
                            {this.state.searchContext === SearchContext.All &&
                            <Fragment>
                                <div className={classes.HeaderOptionActive}
                                     onClick={() => this.setSearchContext(SearchContext.All)}>
                                    All
                                </div>
                                <div className={classes.HeaderOption}
                                     onClick={() => this.setSearchContext(SearchContext.Investors)}>
                                    Investors
                                </div>
                                <div className={classes.HeaderOption}
                                     onClick={() => this.setSearchContext(SearchContext.Startups)}>
                                    Startups
                                </div>
                                <div className={classes.HeaderOption}
                                     onClick={() => this.setSearchContext(SearchContext.Personal)}>
                                    People
                                </div>
                            </Fragment>
                            }
                            {this.state.searchContext !== SearchContext.All &&
                            <SearchFilters filters={searchFilters}
                                           selectedFilters={this.state.selectedFilters}
                                           toggleFilter={this.toggleFilter}
                                           numberOfFiltersSelected={numberOfFiltersSelected}/>
                            }
                        </div>

                        {this.state.searchContext === SearchContext.All &&
                        <Fragment>
                            {!!this.state.resultsAll[SearchContext.Investors] && this.state.resultsAll[SearchContext.Investors].length > 0 &&
                            <Fragment>
                                <div className={classes.ResultsAll}>
                                    <h3 className={classes.ResultsAllHeader}>Investors</h3>
                                    {this.state.resultsAll[SearchContext.Investors].map(investor =>
                                        <InvestorSearchResult investor={investor}/>)}
                                </div>
                                <div className={classes.ResultsAllLink}>
                                    <NavLink to={`/explore/investors?query=${this.state.fullTextQuery}`}>
                                        See all {this.state.totalAll[SearchContext.Investors]} investors
                                    </NavLink>
                                </div>
                            </Fragment>
                            }
                            {!!this.state.resultsAll[SearchContext.Startups] && this.state.resultsAll[SearchContext.Startups].length > 0 &&
                            <Fragment>
                                <div className={classes.ResultsAll}>
                                    <h3 className={classes.ResultsAllHeader}>Startups</h3>
                                    {this.state.resultsAll[SearchContext.Startups].map(company =>
                                        <CompanySearchResult company={company}/>)}
                                </div>
                                <div className={classes.ResultsAllLink}>
                                    <NavLink to={`/explore/companies?query=${this.state.fullTextQuery}`}>
                                        See all {this.state.totalAll[SearchContext.Startups]} startups
                                    </NavLink>
                                </div>
                            </Fragment>
                            }
                            {!!this.state.resultsAll[SearchContext.Personal] && this.state.resultsAll[SearchContext.Personal].length > 0 &&
                            <Fragment>
                                <div className={classes.ResultsAll}>
                                    <h3 className={classes.ResultsAllHeader}>People</h3>
                                    {this.state.resultsAll[SearchContext.Personal].map(personal =>
                                        <PersonalSearchResult personal={personal}/>)}
                                </div>
                                <div className={classes.ResultsAllLink}>
                                    <NavLink to={`/explore/people?query=${this.state.fullTextQuery}`}>
                                        See all {this.state.totalAll[SearchContext.Personal]} people
                                    </NavLink>
                                </div>
                            </Fragment>
                            }
                        </Fragment>
                        }
                        {this.state.searchContext !== SearchContext.All &&
                        <Fragment>
                            {(!this.context.currentProfile || this.context.currentProfile.profileType === ProfileType.Personal)
                            && this.state.searchContext === SearchContext.Investors &&
                            <Fragment>
                                <div className={classes.CreatePlaceholder}>
                                    <h3>Want to be a featured investor?</h3>
                                    <div className={classes.CreatePlaceholderDescription}>
                                        Create a page and join 100+ investors gaining visibility to climate tech startups.
                                    </div>
                                    <PopupContext.Consumer>
                                        {popupContext => (
                                            <SubmitButton className={classes.CreatePlaceholderButton}
                                                  onClick={() => popupContext.openCreateProfilePopUp()}>Create a page</SubmitButton>
                                        )}
                                    </PopupContext.Consumer>
                                </div>
                                <div className={classes.CreateBottom} />
                            </Fragment>
                            }
                            {(!this.context.currentProfile || this.context.currentProfile.profileType === ProfileType.Personal)
                            && this.state.searchContext === SearchContext.Startups &&
                            <Fragment>
                                <div className={classes.CreatePlaceholder}>
                                    <h3>Want to be a featured startup?</h3>
                                    <div className={classes.CreatePlaceholderDescription}>
                                        Create a page to highlight your startup in front of climate focused investors.
                                    </div>
                                    <PopupContext.Consumer>
                                        {popupContext => (
                                            <SubmitButton className={classes.CreatePlaceholderButton}
                                                          onClick={() => popupContext.openCreateProfilePopUp()}>Create a page</SubmitButton>
                                        )}
                                    </PopupContext.Consumer>
                                </div>
                                <div className={classes.CreateBottom} />
                            </Fragment>
                            }
                            <div className={classes.TotalResults}>
                                {this.state.total} result{this.state.total !== 1 ? 's' : ''}
                            </div>
                            <div className={classes.SearchResultsContainer}>
                                {(!this.state.results || this.state.results.length === 0) && !this.state.isLoading
                                    ? <NoResults/>
                                    : <SearchResults results={this.state.results}
                                                     resultsAll={this.state.resultsAll}
                                                     searchContext={this.state.searchContext}/>
                                }
                            </div>
                            {this.state.reachedEndOfResults && !!this.state.results && this.state.results.length > 0 &&
                            <div className={classes.EndOfResults}>
                                End of results.
                            </div>
                            }
                        </Fragment>
                        }
                        {this.state.isLoading && this.state.pageNumber === 0 &&
                        <PlaceHolders/>
                        }
                        {!this.state.reachedEndOfResults && this.state.searchContext !== SearchContext.All && !(this.state.pageNumber === 0 && this.state.isLoading) &&
                        <div className={classes.SpinnerContainer}>
                            <Spinner/>
                        </div>
                        }
                    </div>
                    <div className={sharedClasses.ClimateAds}>
                        <Recommendations profileId={this.context.profileId}
                                         toMatchWith={RecommendationsMatchType.Both}/>
                    </div>
                </div>
            </div>
        );
    }
}

const PlaceHolders = () => {
    return (
        <Fragment>
            <Placeholder />
            <Placeholder />
            <Placeholder />
            <Placeholder />
            <Placeholder />
            <Placeholder />
            <Placeholder />
            <Placeholder />
            <Placeholder />
        </Fragment>
    );
}

const SearchResults = (props) => {
    switch (props.searchContext) {
        case SearchContext.Startups:
            return props.results.map((company, index) => <CompanySearchResult key={index} company={company} />);
        case SearchContext.Investors:
            return props.results.map((investor, index) => <InvestorSearchResult key={index} investor={investor} />);
        case SearchContext.Personal:
            return props.results.map((personal, index) => <PersonalSearchResult key={index} personal={personal} />);
        default:
            return null;
    }
};

export default withRouter(Explore);
