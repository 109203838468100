class Image {

    file = null;
    src = null;
    isUploadedLocally = false;

    constructor(file, src, isUploadedLocally) {
        this.file = file;
        this.src = src;
        this.isUploadedLocally = isUploadedLocally;
    }
}

export default Image;