import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import BasicDetailsStep from "./BasicDetailsStep";
import FocusAreasStep from "./FocusAreasStep";
import AppContext from "../../../context/AppProvider";
import {ProfileType} from "../../../constants/ProfileTypeConstants";

const Step = {
    BasicDetails: 'BasicDetails',
    FocusAreas: 'FocusAreas'
}

const Steps = [
    Step.BasicDetails,
    Step.FocusAreas
]

class StartupQuestionnaire extends Component {

    static contextType = AppContext;

    state = {
        step: Step.BasicDetails,
        photo: null,
        name: '',
        location: '',
        website: '',
        focusAreas: [],
        isSubmitting: false,
    }

    nextStep = () => {
        this.setState({step: Steps[Steps.indexOf(this.state.step) + 1]});
    }

    previousStep = () => {
        this.setState({step: Steps[Steps.indexOf(this.state.step) - 1]});
    }

    setInvestorType = (investorType) => {
        this.setState({investorType: investorType});
    }

    setPhoto = (photo) => {
        this.setState({photo: photo});
    }

    setName = (name) => {
        this.setState({name: name});
    }

    setLocation = (location) => {
        this.setState({location: location});
    }

    setWebsite = (website) => {
        this.setState({website: website});
    }

    setFocusAreas = (focusAreas) => {
        this.setState({focusAreas: focusAreas});
    }

    onSubmit = async () => {
        this.setState({isSubmitting: true}, async () => {
            let logo = null;
            if (this.state.photo) {
                const re = /(?:\.([^.]+))?$/;
                const ext = re.exec(this.state.photo.file.name)[1]
                const imageUploadURLResponse = await this.context.apiGatewayClient.getImageUploadUrl(ext);
                logo = imageUploadURLResponse.data.fileName;
                await this.context.s3Client.uploadImage(this.state.photo.file, imageUploadURLResponse.data.uploadUrl);
            }

            const result = await this.context.apiGatewayClient.createProfile({
                name: this.state.name,
                logo: logo,
                location: this.state.location,
                website: this.state.website,
                focusAreas: this.state.focusAreas,
                profileType: ProfileType.Startup
            });

            this.setState({isSubmitting: false});
            this.context.setCurrentProfile(result.data.profile);
            this.props.history.push(`/profile/${result.data.profile.profileId}`);
            this.props.closePopUp();
        })

    }

    render() {
        return (
            <div>
                {this.state.step === Step.BasicDetails &&
                <BasicDetailsStep nextStep={this.nextStep}
                                  previousStep={this.props.clearProfileType}
                                  setPhoto={this.setPhoto}
                                  photo={this.state.photo}
                                  setName={this.setName}
                                  name={this.state.name}
                                  setLocation={this.setLocation}
                                  location={this.state.location}
                                  setWebsite={this.setWebsite}
                                  website={this.state.website}/>
                }
                {this.state.step === Step.FocusAreas &&
                <FocusAreasStep previousStep={this.previousStep}
                                onSubmit={this.onSubmit}
                                setFocusAreas={this.setFocusAreas}
                                focusAreas={this.state.focusAreas}
                                isSubmitting={this.state.isSubmitting}/>
                }
            </div>
        )
    }
}

export default withRouter(StartupQuestionnaire);
