import React, {Component} from 'react';
import Description from '../Description/Description';
import classes from "./InvestorInformation.module.css";
import sharedClasses from '../Styles/SharedStyles.module.css';
import {InvestmentRangeFilterOptions} from '../../../constants/filteroptions/InvestmentRangeFilterOptions';
import {FocusAreaFilterOptions} from '../../../constants/filteroptions/FocusAreaFilterOptions';
import EditOverview from "../EditableField/Overview/EditOverview";
import {FundingStageFilterOptions} from "../../../constants/filteroptions/FundingStageFilterOptions";
import {BusinessStageFilterOptions} from "../../../constants/filteroptions/BusinessStageFilterOptions";
import {TechnologyFilterOptions} from "../../../constants/filteroptions/TechnologyFilterOptions";
import EditInvestorDetails from "../EditableField/InvestorInformation/Details/EditInvestorDetails";

class InvestorInformation extends Component {

    render() {
        return (
            <div className={sharedClasses.Container}>
                {this.props.canEdit &&
                <div className={classes.EditIconContainer}>
                    <EditOverview profile={this.props.profile}
                                  setProfile={this.props.setProfile}
                                  title={'Overview'}/>
                </div>
                }
                <div className={sharedClasses.Section}>
                    <Description description={this.props.profile.description}/>
                </div>
                {this.props.canEdit &&
                <div className={classes.EditIconContainer}>
                    <EditInvestorDetails profile={this.props.profile}
                                         setProfile={this.props.setProfile}/>
                </div>
                }
                {!!this.props.profile.focusAreas && this.props.profile.focusAreas.length > 0 &&
                <div className={sharedClasses.SplitSection}>
                    <div className={sharedClasses.SplitSectionLeft}>
                        <h3>Focus Areas</h3>
                    </div>
                    <div className={sharedClasses.SplitSectionRight}>
                        <div className={sharedClasses.IconsContainer}>
                            {!!this.props.profile.focusAreas &&
                            this.props.profile.focusAreas.map(focusArea =>
                                FocusAreaFilterOptions.find(f => f.id === focusArea).displayName
                            ).join(', ')
                            }
                        </div>
                    </div>
                </div>
                }
                {!!this.props.profile.fundingStages && this.props.profile.fundingStages.length > 0 &&
                <div className={sharedClasses.SplitSection}>
                    <div className={sharedClasses.SplitSectionLeft}>
                        <h3>Funding rounds</h3>
                    </div>
                    <div className={sharedClasses.SplitSectionRight}>
                        <div className={classes.IconsContainer}>
                            {!!this.props.profile.fundingStages &&
                            this.props.profile.fundingStages.map(fundingStage =>
                                FundingStageFilterOptions.find(fundingStageFilterOption => fundingStageFilterOption.id === fundingStage).displayName
                            ).join(', ')
                            }
                        </div>
                    </div>
                </div>
                }
                {!!this.props.profile.businessStages && this.props.profile.businessStages.length > 0 &&
                <div className={sharedClasses.SplitSection}>
                    <div className={sharedClasses.SplitSectionLeft}>
                        <h3>Invests in</h3>
                    </div>
                    <div className={sharedClasses.SplitSectionRight}>
                        <div className={classes.IconsContainer}>
                            {!!this.props.profile.businessStages &&
                            this.props.profile.businessStages.map(businessStage =>
                                BusinessStageFilterOptions.find(b => b.id === businessStage).displayName
                            ).join(', ')
                            }
                        </div>
                    </div>
                </div>
                }
                {!!this.props.profile.investmentRanges && this.props.profile.investmentRanges.length > 0 &&
                <div className={sharedClasses.SplitSection}>
                    <div className={sharedClasses.SplitSectionLeft}>
                        <h3>Investment range</h3>
                    </div>
                    <div className={sharedClasses.SplitSectionRight}>
                        <div className={classes.IconsContainer}>
                            {!!this.props.profile.investmentRanges &&
                            this.props.profile.investmentRanges.map(investmentRange =>
                                InvestmentRangeFilterOptions.find(investmentRangeFilterOption => investmentRangeFilterOption.id === investmentRange).displayName
                            ).join(', ')
                            }
                        </div>
                    </div>
                </div>
                }
                {!!this.props.profile.technologies && this.props.profile.technologies.length > 0 &&
                <div className={sharedClasses.SplitSection}>
                    <div className={sharedClasses.SplitSectionLeft}>
                        <h3>Preferred technologies</h3>
                    </div>
                    <div className={sharedClasses.SplitSectionRight}>
                        <div className={classes.IconsContainer}>
                            {!!this.props.profile.technologies &&
                            this.props.profile.technologies.map(technology =>
                                TechnologyFilterOptions.find(t => t.id === technology).displayName
                            ).join(', ')
                            }
                        </div>
                    </div>
                </div>
                }
                {!!this.props.profile.pastInvestments && this.props.profile.pastInvestments.length > 0 &&
                <div className={sharedClasses.SplitSection}>
                    <div className={sharedClasses.SplitSectionLeft}>
                        <h3>Past Investments</h3>
                    </div>
                    <div className={sharedClasses.SplitSectionRight}>
                        <div className={classes.IconsContainer}>
                            {!!this.props.profile.pastInvestments &&
                            this.props.profile.pastInvestments.map(pastInvestment =>
                                pastInvestment
                            ).join(', ')
                            }
                        </div>
                    </div>
                </div>
                }
            </div>
        );
    }
}

export default InvestorInformation;
