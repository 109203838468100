import React from "react";

export const ExploreIcon = () => {
    return (
        <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="telescope"
             role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"
             className="svg-inline--fa fa-telescope fa-w-20 fa-2x">
            <path fill="currentColor"
                  d="M637.55438,197.1457,564.08568,19.76077A31.99673,31.99673,0,0,0,522.27907,2.44239L413.69712,47.4229c-15.24413,6.31446-22.5078,23.01956-17.96483,38.502L75.09384,241.41332c-8.76561,4.25-13.07225,13.584-9.85155,21.35744l8.74413,21.1094L9.881,310.43487a16.00153,16.00153,0,0,0-8.66015,20.90432l20.05663,48.42192a15.99879,15.99879,0,0,0,20.90428,8.66016l64.10542-26.55471,8.74218,21.10744c4.05469,9.79493,15.416,10.44337,22.06444,8.13868l133.89834-46.45121c1.28125,1.543,2.877,2.77344,4.291,4.19336L224.416,501.46826a8.0008,8.0008,0,0,0,7.58984,10.53126h16.84569a8.0008,8.0008,0,0,0,7.58984-5.47071l47-140.97865c12.43749,3.4336,21.67772,3.08789,33.11521-.00586l47.00192,140.98451a8.0008,8.0008,0,0,0,7.58983,5.47071H407.994a8.00079,8.00079,0,0,0,7.58983-10.53126l-50.67965-152.047a63.91267,63.91267,0,0,0,18.94335-43.91215l90.52728-31.40628c6.7207,8.53321,21.11717,16.53517,37.28317,9.832l108.58391-44.97856A31.999,31.999,0,0,0,637.55438,197.1457ZM44.71691,352.73374,36.90637,333.8763l49.32418-20.43166,7.81055,18.85744Zm275.283-16.73439a32,32,0,1,1,32-32A32.03165,32.03165,0,0,1,319.99992,335.99935Zm56.32027-61.75006a63.69444,63.69444,0,0,0-119.14835,41.334L138.76372,356.66148,100.63094,264.593,407.39829,115.83117l53.46481,129.088Zm123.09171-19.877L425.9432,76.98739l108.58-44.97856,73.4687,177.383Z"
                  className="">
            </path>
        </svg>
    );
};
