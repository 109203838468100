import React, {Component, createRef, Fragment} from "react";
import {withRouter} from "react-router-dom";
import classes from './CreateConversation.module.css';
import AppContext from "../../../context/AppProvider";
import SubmitButton from "../../Forms/SubmitButton/SubmitButton";
import {PaperAirplaneIcon} from "../../Icons/PaperAirplaneIcon";
import {SearchContext} from "../../../constants/SearchContexts";
import ProfilePhotoWithDefault from "../../ProfilePage/ProfilePhotoWithDefault/ProfilePhotoWithDefault";
import {CloseIcon} from "../../Icons/CloseIcon";


class CreateConversation extends Component {

    static contextType = AppContext;

    state = {
        currentConversations: [],
        newMessageContent: '',
        recipientText: '',
        suggestedRecipients: [SearchContext.Investors, SearchContext.Startups, SearchContext.Personal].reduce((map, searchContext) => {
            map[searchContext] = [];
            return map;
        }, {}),
        selectedRecipient: null,
        isSubmitting: false,
        isLoading: false,
        inputRef: createRef()
    }

    componentDidMount = async () => {
        if (!!this.props.match.params.profileId) {
            const recipient = (await this.context.apiGatewayClient.getProfile(this.props.match.params.profileId)).data.profile;
            this.setState({selectedRecipient: recipient});
        }

        const result = await this.context.appSyncClient.getConversations(this.context.currentProfile.profileId);
        this.setState({currentConversations: result['getConversations']['conversations']});
    }

    onRecipientChange = (e) => {
        const recipientText = e.target.value;
        this.setState({recipientText: recipientText});
        Promise.all([
            this.context.apiGatewayClient.getSuggestions(SearchContext.Investors, recipientText),
            this.context.apiGatewayClient.getSuggestions(SearchContext.Startups, recipientText),
            this.context.apiGatewayClient.getSuggestions(SearchContext.Personal, recipientText)
        ]).then(([investorResult, companyResult,personalResult ]) => {
            const suggestedRecipients = {};
            suggestedRecipients[SearchContext.Investors] = investorResult.data.profiles;
            suggestedRecipients[SearchContext.Startups] = companyResult.data.profiles;
            suggestedRecipients[SearchContext.Personal] = personalResult.data.profiles;
            this.setState({suggestedRecipients: suggestedRecipients});
        });
    }

    selectRecipient = (recipient) => {
        if (this.props.location.pathname.includes('messages')) {
            if (!!recipient) {
                this.props.history.push(`/messages/create/${recipient.profileId}`);
            } else {
                this.props.history.push('/messages/create');
            }
        }
        this.setState({selectedRecipient: recipient, suggestedRecipients: [], recipientText: ''});
    }

    onNewMessageContentChange = (e) => {
        this.setState({newMessageContent: e.target.value});
    }

    handleKeyDown = async (event) => {
        if (event.key === 'Enter') {
            this.state.inputRef.current.blur();
            await this.onMessageSubmit();
        }
    };

    onMessageSubmit = async () => {
        if (!this.state.newMessageContent || this.state.newMessageContent.trim().length === 0) {
            return;
        }
        this.setState({isSubmitting: true}, async () => {
            // fetch all conversations
            let reachedEndOfResult = false;
            let lastEvaluatedKey;
            let conversation;
            while (!reachedEndOfResult) {
                const result = await this.context.appSyncClient.getConversations(this.context.currentProfile.profileId, lastEvaluatedKey);
                const conversations = result['getConversations']['conversations'];
                lastEvaluatedKey = result['getConversations']['lastEvaluatedKey'];
                if (!lastEvaluatedKey) {
                    reachedEndOfResult = true;
                }

                conversation = conversations.find(conversation =>
                    conversation.participants.find(participant => participant.profileId === this.state.selectedRecipient.profileId)
                );
            }

            let conversationId;
            if (!conversation) {
                const result = await this.context.appSyncClient.createConversation({
                    creator: {
                        profileId: this.context.currentProfile.profileId
                    },
                    participants: {
                        profileId: this.state.selectedRecipient.profileId
                    }
                });
                conversationId = result['createConversation'];
            } else {
                conversationId = conversation.conversationId;
            }

            const message = {
                content: this.state.newMessageContent,
                contentType: "text",
                author: {
                    profileId: this.context.currentProfile.profileId
                }
            }

            await this.context.appSyncClient.sendMessage(conversationId, message);
            this.props.navigateToConversation(conversationId);
        });
    }

    render() {
        const suggestions = [];
        Object.keys(this.state.suggestedRecipients).map(key => {
            if (this.state.suggestedRecipients[key].length > 0) {
                suggestions.push(
                    <div>
                        <div className={classes.SuggestionHeader}>
                            {key}
                        </div>
                        {this.state.suggestedRecipients[key].map(suggestion => (
                            <div className={classes.Suggestion} onMouseDown={() => this.selectRecipient(suggestion)}>
                                <div className={classes.SuggestionLogo}>
                                    <ProfilePhotoWithDefault logo={suggestion.logo} name={suggestion.name}/>
                                </div>
                                <div className={classes.SuggestionDetails}>
                                    <div>
                                        {highlightSubText(suggestion.name, this.state.recipientText.trim())}
                                    </div>
                                    <div className={classes.SuggestionDescription}>
                                        {!!suggestion.description && suggestion.description.length > 60 ? `${suggestion.description.slice(0, 60)}...` : suggestion.description}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )
            }
        });

        return (
            <Fragment>
                <div className={classes.Messages}>
                    {!this.state.selectedRecipient &&
                    <Fragment>
                        <div className={classes.RecipientInputContainer}>
                            <input type="text"
                                   onChange={this.onRecipientChange}
                                   value={this.state.recipient}
                                   placeholder={"Search profiles or pages..."}/>
                        </div>
                        {suggestions}
                    </Fragment>
                    }
                    {this.state.selectedRecipient &&
                    <div className={classes.SelectedRecipientContainer}>
                        <div className={classes.SelectedRecipientLogo}>
                            <ProfilePhotoWithDefault logo={this.state.selectedRecipient.logo} name={this.state.selectedRecipient.name}/>
                        </div>
                        <div className={classes.SelectedRecipientName}>
                            {this.state.selectedRecipient.name}
                        </div>
                        <div onClick={() => this.selectRecipient(null)} className={classes.SelectedRecipientClose}>
                            <CloseIcon />
                        </div>
                    </div>
                    }
                </div>
                <div className={classes.CreateMessage}>
                    <div className={classes.InputContainer}>
                        <input type="text"
                               onChange={this.onNewMessageContentChange}
                               value={this.state.newMessageContent}
                               placeholder={"Send a message..."}
                               ref={this.state.inputRef}
                               onKeyDown={this.handleKeyDown}/>
                    </div>
                    <SubmitButton onClick={this.onMessageSubmit}
                                  className={classes.CreateMessageButton}
                                  isSubmitting={this.state.isSubmitting}>
                        {!this.state.isSubmitting && <PaperAirplaneIcon />}
                    </SubmitButton>
                </div>
            </Fragment>
        );
    }
}

const highlightSubText = (text, subText) => {
    const idx = text.toLowerCase().indexOf(subText);
    if (idx < 0) {
        return text;
    } else {
        return <span>
            {text.slice(0, idx)}<b>{text.slice(idx, idx + subText.length)}</b>{text.slice(idx + subText.length, text.length)}
        </span>
    }
}

export default withRouter(CreateConversation);
