import {BusinessModelFilterOptions} from './filteroptions/BusinessModelFilterOptions';
import {InvestorTypeFilterOptions} from './filteroptions/InvestorTypeFilterOptions';
import {FocusAreaFilterOptions} from './filteroptions/FocusAreaFilterOptions';
import {TechnologyFilterOptions} from './filteroptions/TechnologyFilterOptions';


export const InvestorSearchFilters = [
    {
        id: 'focusAreas',
        displayName: 'Focus Areas',
        filterOptions: FocusAreaFilterOptions
    },
    {
        id: 'technologies',
        displayName: 'Technology',
        filterOptions: TechnologyFilterOptions
    },
    {
        id: 'businessModels',
        displayName: 'Business Model',
        filterOptions: BusinessModelFilterOptions
    },
    {
        id: 'investorType',
        displayName: 'Type',
        filterOptions: InvestorTypeFilterOptions
    }
];
