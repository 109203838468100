import React, {Component} from 'react';
import Avatar from 'react-avatar';
import classes from './ProfilePhotoWithDefault.module.css';
import DefaultProfilePhoto from '../../../assets/DefaultProfilePhoto.jpeg';
import {ProfileAssetsBucketPrefix} from "../../../constants/ProfileAssetsBucketPrefix";


class ProfilePhotoWithDefault extends Component {

    render() {
        let src = this.props.logo;
        if (!this.props.isUploadedLocally && !!this.props.logo && !this.props.logo.includes('climate')) {
            src = `${ProfileAssetsBucketPrefix}${this.props.logo}`
        }
        if (!src && !this.props.name) {
            src = DefaultProfilePhoto;
        }

        return (
            <div className={classes.Container}>
                <Avatar name={this.props.name}
                        src={src}
                        size={125}/>
            </div>
        );
    }
}

export default ProfilePhotoWithDefault;
