import investorImage from '../assets/ProfileTypeIcons/handshake.png';
import startupImage from '../assets/ProfileTypeIcons/diagram.png';

export const ProfileType = {
    Investor: 'INVESTOR',
    Startup: 'COMPANY',
    Personal: 'PERSONAL'
};

export const ProfileTypes = [
    {
        id: ProfileType.Investor,
        displayName: 'Investor',
        icon: investorImage,
    },
    {
        id: ProfileType.Startup,
        displayName: 'Startup',
        icon: startupImage,
    },
    {
        id: ProfileType.Personal,
        displayName: 'Personal',
        icon: startupImage,
    }
];
