import Aerial from '../assets/Featured/Companies/aerial_featured.png';
import Airly from '../assets/Featured/Companies/airly_featured.jpg';
import Airthium from '../assets/Featured/Companies/airthium_featured.jpg';
import Audette from '../assets/Featured/Companies/audette_featured.jpg';
import Epiclay from '../assets/Featured/Companies/epliclay_featured.jpg';
import Nori from '../assets/Featured/Companies/nori_featured.jpg';


export const FEATURED_STARTUPS = [
    {
        name: 'Aerial',
        logo: 'https://prod-climate-investment-profile-images.s3-us-west-2.amazonaws.com/0d5194e2-74fb-45f9-9870-ff4e6d36e5f0.jpg',
        image: Aerial,
        link: 'https://climatehub.io/profile/af0d57df-fca4-4f8f-9e78-ab13e6185350',
        location: 'San Francisco, CA'
    },
    {
        name: 'Airly',
        logo: 'https://prod-climate-investment-profile-images.s3-us-west-2.amazonaws.com/40f2a03c-e7d1-44ab-99e8-d0398ce93515.png',
        image: Airly,
        link: 'https://climatehub.io/profile/95a662a2-a594-458b-8ec5-b4e6de9e833c',
        location: 'Palo Alto, CA'
    },
    {
        name: 'Airthium',
        logo: 'https://prod-climate-investment-profile-images.s3-us-west-2.amazonaws.com/d014cb56-691d-422f-a126-7403902107c9.jpg',
        image: Airthium,
        link: 'https://climatehub.io/profile/6986b45f-0071-49c0-a7a5-a0bae1014ecd',
        location: 'Paris, France'
    },
    {
        name: 'Audette',
        logo: 'https://prod-climate-investment-profile-images.s3-us-west-2.amazonaws.com/48671be9-9dca-43fb-ae78-f23328cc0037.png',
        image: Audette,
        link: 'https://climatehub.io/profile/0bc19ef4-ef52-40ed-9e05-e877cb985314',
        location: 'Victoria, BC'
    },

    {
        name: 'Epiclay',
        logo: 'https://prod-climate-investment-profile-images.s3-us-west-2.amazonaws.com/79afa1b9-94e0-4bf8-9f26-20011ff1b3a5.png',
        image: Epiclay,
        link: 'https://climatehub.io/profile/3a617a27-08a5-4587-b04c-933003b7e915',
        location: 'Vienna, Austria'
    },
    {
        name: 'Nori',
        logo: 'https://prod-climate-investment-profile-images.s3-us-west-2.amazonaws.com/29689230-8b89-451b-a703-0704860fc76e.png',
        image: Nori,
        link: 'https://climatehub.io/profile/eaa37c38-2643-4862-b7d3-ac53dca2e710',
        location: 'Seattle, WA'
    }
];
