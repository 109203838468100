import _ from "lodash";

export const getUpdatedSelectedSearchFilters = (toggledFilter, toggledFilterOption, selectedFilters) => {
    const selectedFiltersClone = _.cloneDeep(selectedFilters);
    const newFilter = selectedFiltersClone.find(selectedFilter => toggledFilter.id === selectedFilter.id);
    if (newFilter.filterOptions.some(option => option.id === toggledFilterOption.id)) {
        newFilter.filterOptions = newFilter.filterOptions.filter(option => option.id !== toggledFilterOption.id);
    } else {
        newFilter.filterOptions = [toggledFilterOption, ...newFilter.filterOptions];
    }
    return selectedFiltersClone.map(selectedFilter => {
        return toggledFilter.id === selectedFilter.id ? newFilter : selectedFilter;
    });
};