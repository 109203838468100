import React, {Component} from "react";
import AppContext from "../../../context/AppProvider";
import {ProfileType} from "../../../constants/ProfileTypeConstants";
import {withRouter} from "react-router-dom";
import Spinner from "../../Spinner/spinner";
import classes from './GoogleAccountRedirect.module.css';

class GoogleAccountRedirect extends Component {

    static contextType = AppContext;

    componentDidMount = async () => {
        const url = new URL(window.location.href.replace('#', '?'));
        const idToken = url.searchParams.get('id_token');
        this.context.apiGatewayClient.setAuthToken(idToken);
        const result = await this.context.apiGatewayClient.getAccount();
        this.context.setAuthToken(idToken);
        this.context.setAccountData({email: result.data.accountData.email, accountId: result.data.accountData.accountId});
        if (result.data.profiles.filter(profile => profile.profileType === ProfileType.Personal).length > 0) {
            this.context.setCurrentProfile(result.data.profiles.find(profile => profile.profileType === ProfileType.Personal));
        } else {
            this.context.setCurrentProfile(result.data.profiles[0]);
        }
        this.props.history.push('/');
    }

    render() {
        return (
            <div className={classes.Container}>
                <div className={classes.Spinner}>
                    <Spinner />
                </div>
                <div>
                    Signing you in...
                </div>
            </div>
        );
    }
}

export default withRouter(GoogleAccountRedirect);
