import React, {Component} from 'react';
import classes from './Carousel.module.css';
import AppContext from "../../context/AppProvider";
import {AngleRightIcon} from "../Icons/AngleRightIcon";
import {AngleLeftIcon} from "../Icons/AngleLeftIcon";

let lastX = 0;
let currentX = 0;
let isInGracePeriod = false;
class Carousel extends Component {

    static contextType = AppContext;

    state = {
        position: 0,
        currentX: 0,
        lastX: 0,
    };

    slideRight = (e) => {
        e.stopPropagation();
        if (this.state.position < this.getCount() - 1) {
            this.setState({position: this.state.position + 1});
        }
    };

    slideLeft = (e) => {
        e.stopPropagation();
        if (this.state.position > 0) {
            this.setState({position: this.state.position - 1});
        }
    };

    handleTouchMove = (e) => {
        e.stopPropagation();
        if (isInGracePeriod) {
            return;
        } else {
            isInGracePeriod = true;
            setTimeout(() => {
                isInGracePeriod = false;
            }, 100);
        }

        currentX = e.nativeEvent.touches[0].clientX;

        if (lastX === 0) {
            lastX = currentX;
        }

        if (currentX < lastX - 10) {
            this.slideRight();
        } else if (currentX > lastX + 10) {
            this.slideLeft();
        }

        lastX = currentX;
    }

    getCount = () => {
        return !!this.props.children ? this.props.children.length : 0;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps === this.props) {
            return;
        }

        if (!!this.props.children && this.state.position > this.props.children.length - 1) {
            this.setState({position: this.props.children.length - 1});
        }
    }

    render() {
        const count = this.getCount();
        const width = `${count * 100}%`;
        const imagesContainerPositionStyle = {
            width: width,
            marginLeft: `-${100 * this.state.position}%`
        };

        const dots = [];
        for (let i = 0; i < count; i++) {
            dots.push(<span className={i === this.state.position ? [classes.Dot, classes.ActiveDot].join(' ') : classes.Dot}><i className="fas fa-circle"/></span>);
        }
        return (
            <div>
                {count > 0 &&
                <div className={classes.Test}>
                    {this.state.position > 0 &&
                    <div className={[classes.Arrow, classes.ArrowLeft].join(' ')} onClick={this.slideLeft}>
                        <AngleLeftIcon />
                    </div>
                    }
                    <div className={classes.ImagesContainer} onTouchMove={this.handleTouchMove}>
                        <div className={classes.ImagesContainerInner} style={imagesContainerPositionStyle}>
                            {this.props.children}
                        </div>
                    </div>
                    {this.state.position < count - 1 &&
                    <div className={[classes.Arrow, classes.ArrowRight].join(' ')} onClick={this.slideRight}>
                        <AngleRightIcon />
                    </div>
                    }
                </div>
                }
                {dots.length > 1 &&
                <div className={classes.Dots}>
                    {dots}
                </div>
                }
            </div>
        );
    }
}

export default Carousel;
