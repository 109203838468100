import React, {Component, createRef} from 'react';
import classes from './GlobalPopup.module.css';

class GlobalPopup extends Component {

    state = {
        wrapperRef: createRef()
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick);
    }

    handleClick = (event) => {
        if (!!this.state.wrapperRef && !!this.state.wrapperRef.current && !this.state.wrapperRef.current.contains(event.target) && !!this.props.onOutsideClick) {
            this.props.onOutsideClick();
        }
    }

    render() {
        return (
            <div className={classes.PopUp}>
                <div className={classes.PopUpInner} ref={this.state.wrapperRef}>
                    {this.props.children}
                </div>
            </div>
        );
    }

}

export default GlobalPopup;
