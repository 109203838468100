import React, {Component, createContext} from "react";
import AppContext from "./AppProvider";


const PopupContext = createContext({});

export class PopupProvider extends Component {

    static contextType = AppContext;

    state = {
        createProfilePopupTitle: null,
        isSignInPopUpOpen: false,
        isSignUpPopUpOpen: false,
        isCreateProfilePopUpOpen: false,
        isPersonalProfileOnBoardingPopupOpen: false,
    };

    openSignUpPopUp = () => {
        this.setState({
            createProfilePopupTitle: null,
            isSignUpPopUpOpen: true,
            isSignInPopUpOpen: false,
            isThirdPartySignInOpen: false,
            isPersonalProfileOnBoardingPopupOpen: false,
            isCreateProfilePopUpOpen: false
        });
    };

    closeSignUpPopUp = () => {
        this.closePopups();
    };

    openSignInPopUp = () => {
        this.setState({
            createProfilePopupTitle: null,
            isSignUpPopUpOpen: false,
            isSignInPopUpOpen: true,
            isThirdPartySignInOpen: false,
            isPersonalProfileOnBoardingPopupOpen: false,
            isCreateProfilePopUpOpen: false
        });
    };

    closeSignInPopUp = () => {
        this.closePopups();
    };

    openCreateProfilePopUp = (title) => {
        if (!!this.context.authToken) {
            this.setState({
                createProfilePopupTitle: title,
                isSignUpPopUpOpen: false,
                isSignInPopUpOpen: false,
                isThirdPartySignInOpen: false,
                isPersonalProfileOnBoardingPopupOpen: false,
                isCreateProfilePopUpOpen: true
            });
        } else {
            this.openSignInPopUp();
        }
    };

    closeCreateProfilePopUp = () => {
        this.closePopups();
    };

    openPersonalProfileOnBoarding = () => {
        this.setState({
            createProfilePopupTitle: null,
            isSignUpPopUpOpen: false,
            isSignInPopUpOpen: false,
            isThirdPartySignInOpen: false,
            isPersonalProfileOnBoardingPopupOpen: true,
            isCreateProfilePopUpOpen: false
        });
    }

    closePersonalProfileOnBoardingPopup = () => {
        this.closePopups();
    };

    openSignThirdPartySignIn = () => {
        this.setState({
            createProfilePopupTitle: null,
            isSignUpPopUpOpen: false,
            isSignInPopUpOpen: false,
            isThirdPartySignInOpen: true,
            isPersonalProfileOnBoardingPopupOpen: false,
            isCreateProfilePopUpOpen: false
        });
    }

    closePopups = () => {
        this.setState({
            createProfilePopupTitle: null,
            isSignUpPopUpOpen: false,
            isSignInPopUpOpen: false,
            isThirdPartySignInOpen: false,
            isPersonalProfileOnBoardingPopupOpen: false,
            isCreateProfilePopUpOpen: false
        });
    }

    render () {
        const {
            createProfilePopupTitle,
            isSignInPopUpOpen,
            isSignUpPopUpOpen,
            isCreateProfilePopUpOpen,
            isPersonalProfileOnBoardingPopupOpen,
        } = this.state;
        const {
            openSignUpPopUp,
            closeSignUpPopUp,
            openSignInPopUp,
            closeSignInPopUp,
            openCreateProfilePopUp,
            closeCreateProfilePopUp,
            openPersonalProfileOnBoarding,
            closePersonalProfileOnBoardingPopup
        } = this;

        return (
            <PopupContext.Provider value={{
                createProfilePopupTitle,
                isSignInPopUpOpen,
                isSignUpPopUpOpen,
                isCreateProfilePopUpOpen,
                isPersonalProfileOnBoardingPopupOpen,
                openSignUpPopUp,
                closeSignUpPopUp,
                openSignInPopUp,
                closeSignInPopUp,
                openCreateProfilePopUp,
                closeCreateProfilePopUp,
                openPersonalProfileOnBoarding,
                closePersonalProfileOnBoardingPopup
            }}>
                {this.props.children}
            </PopupContext.Provider>
        )
    }
}

export default PopupContext;
