import React from 'react';
import {NavLink} from "react-router-dom";
import {FocusAreaFilterOptions} from "../../../constants/filteroptions/FocusAreaFilterOptions";
import {TechnologyFilterOptions} from "../../../constants/filteroptions/TechnologyFilterOptions";
import classes from './SearchResult.module.css';
import GetDescription from "./SearchResultDescription/SearchResultDescriptionHighlighter";
import ProfilePhotoWithDefault from "../../ProfilePage/ProfilePhotoWithDefault/ProfilePhotoWithDefault";

const CompanySearchResult = (props) => {
    const profile = props.company.profile;
    const description = GetDescription(props.company.tokenOffsets, profile.description);

    return (
        <div className={classes.Container}>
            <NavLink to={`/profile/${profile.profileId}`} className={classes.LinkContainer}>
                <div className={classes.SearchResult}>
                    <div className={classes.Left}>
                        <div className={classes.Logo}>
                            <ProfilePhotoWithDefault logo={profile.logo} name={profile.name} />
                        </div>
                        <div className={classes.Metadata}>
                            <h3>
                                {profile.name}
                            </h3>
                            <div className={classes.SubTitle}>
                                <FocusArea focusAreas={profile.focusAreas}/>
                            </div>
                        </div>
                    </div>
                    <div className={classes.Right}>
                        <div className={classes.Description}>
                            {description}
                        </div>
                        <div className={classes.Technologies}>
                            <Technologies technologies={profile.technologies} />
                        </div>
                    </div>
                </div>
            </NavLink>
        </div>
    );
};

const Technologies = ({technologies}) => {
    if (!technologies) {
        return null;
    }

    return technologies.map((technology, index) => {
        return (
            <div key={index} className={classes.Technology}>
                {TechnologyFilterOptions.find(technologyFilterOption => technology === technologyFilterOption.id).displayName}
            </div>
        );
    });

};

const FocusArea = ({focusAreas}) => {
    if (!focusAreas) {
        return null;
    }
    return FocusAreaFilterOptions.find(focusAreaFilterOption => focusAreas[0] === focusAreaFilterOption.id).displayName
};

export default CompanySearchResult;
