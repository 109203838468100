import React, {Component, Fragment} from "react";
import classes from "./SearchFilters.module.css";
import Expand from "react-expand-animated";
import SearchFiltersScrollMenu from "./ScrollMenu/SearchFiltersScrollMenu";

class SearchFilters extends Component {
    state = {
        expandedFilters: []
    };

    toggleFilterSectionExpansion = (filterToToggle) => {
        if (this.state.expandedFilters.some(filter => filter.id === filterToToggle.id)) {
            this.setState({expandedFilters: []});
        } else {
            this.setState({expandedFilters : [filterToToggle]});
        }
    };

    render() {
        return (
            <Fragment>
                {!!this.props.filters && this.props.filters.length > 0 &&
                    <SearchFiltersScrollMenu
                        filters={this.props.filters}
                        selectedFilters={this.props.selectedFilters}
                        expandedFilters={this.state.expandedFilters}
                        toggleFilterSectionExpansion={this.toggleFilterSectionExpansion}/>
                }
                <div>
                    {
                        this.props.filters.map(filter => {
                            const isExpanded = this.state.expandedFilters.some(expandedFilter => expandedFilter.id === filter.id);
                            return (
                                <Expand open={isExpanded} duration={200}>
                                    <div className={classes.FilterOptions}>
                                        {filter.filterOptions.map(filterOption => (
                                            <FilterOption filter={filter}
                                                          filterOption={filterOption}
                                                          selectedFilters={this.props.selectedFilters}
                                                          toggleFilter={this.props.toggleFilter}/>
                                        ))}
                                    </div>
                                </Expand>
                            )
                        })
                    }
                </div>
                {this.state.expandedFilters.length > 0 &&
                <div className={classes.CloseFiltersButton}
                     onClick={() => this.toggleFilterSectionExpansion(this.state.expandedFilters[0])}>
                    <i className="fas fa-angle-up"/>
                </div>
                }
            </Fragment>
        );
    }
}

const FilterOption = (props) => {
    const selected = props.selectedFilters.some(filter => {
        return filter.id === props.filter.id && filter.filterOptions.some(filterOption => filterOption.id === props.filterOption.id)
    });

    return (
        <div className={selected ? [classes.FilterOption, classes.FilterOptionSelected].join(' ') : classes.FilterOption}
             onClick={() => props.toggleFilter(props.filter, props.filterOption)}>
            {props.filterOption.displayName}
        </div>
    );
};

export default SearchFilters;
