import React, {Component, Fragment} from "react";
import classes from './FeedPost.module.css';
import moment from 'moment';
import AppContext from "../../../context/AppProvider";
import SubmitButton from "../../Forms/SubmitButton/SubmitButton";
import {NavLink, withRouter} from "react-router-dom";
import {ProfileType} from "../../../constants/ProfileTypeConstants";
import GlobalPopup from "../../Popup/GlobalPopup/GlobalPopup";
import FeedPostOptions from "./FeedPostOptions/FeedPostOptions";
import FeedPostContent from "./FeedPostContent/FeedPostContent";
import ProfilePhotoWithDefault from "../../ProfilePage/ProfilePhotoWithDefault/ProfilePhotoWithDefault";
import Carousel from "../../Carousel/Carousel";
import FeedSubmit from "./FeedSubmit/FeedSubmit";
import Image from "../../../model/Image";
import CreateModal from "../CreateModal/CreateModal";
import {CloseIcon} from "../../Icons/CloseIcon";
import WebLinkPreview from "./FeedPostContent/WebLinkPreview/WebLinkPreview";
import {FeedAssetsBucketPrefix} from "../../../constants/FeedAssetsBucketPrefix";
import PopupContext from "../../../context/PopupsProvider";


class FeedPost extends Component {

    static contextType = AppContext;

    state = {
        isPostSubmitting: false,
        isPostDeleting: false,
        isInEditMode: false,
        isDeleteFeedModalOpen: false,
        isCommentModalOpen: false,
        newFeedContent: this.props.feedPost.content,
        isCommentSectionExpanded: false,
        totalLikes: this.props.feedPost.totalLikes,
        isLiked: this.props.feedPost.isLiked,
        isCommentSubmitting: false,
        webLinkPreviews: [],
    };

    componentDidMount() {
        this.context.apiGatewayClient.getWeblinkPreviews(this.props.feedPost.content).then(result => {
            this.setState({webLinkPreviews: result.data});
        })
    }

    openDeleteFeedPostModal = () => {
        this.setState({isDeleteFeedModalOpen: true});
    };

    closeDeleteFeedPostModal = () => {
        this.setState({isDeleteFeedModalOpen: false});
    };

    openCommentModal = (e, popupContext) => {
        e.stopPropagation();
        if (!!this.context.currentProfile) {
            // this.op
            this.setState({isCommentModalOpen: true});
        } else {
            popupContext.openSignInPopUp();
        }
    }

    closeCommentModal = () => {
        this.setState({isCommentModalOpen: false});
    }

    editFeedPost = async (images, videos) => {
        await this.setState({isPostSubmitting: true}, async () => {
            const imageRefs = await Promise.all(images.filter(img => !img.isUploadedLocally).map(async img => {
                const re = /(?:\.([^.]+))?$/;
                const ext = re.exec(img.file.name)[1]
                const result = await this.context.apiGatewayClient.getFeedMediaUploadUrl(ext);
                await this.context.s3Client.uploadImage(img.file, result.data.uploadUrl);
                return result.data.fileName;
            }));
            const videoRefs = await Promise.all(videos.filter(vid => !vid.isUploadedLocally).map(async vid => {
                const re = /(?:\.([^.]+))?$/;
                const ext = re.exec(vid.file.name)[1]
                const result = await this.context.apiGatewayClient.getFeedMediaUploadUrl(ext);
                await this.context.s3Client.uploadImage(vid.file, result.data.uploadUrl);
                return result.data.fileName;
            }));

            const feedPost = {
                postId: this.props.feedPost.postId,
                profileId: this.context.currentProfile.profileId,
                content: this.state.newFeedContent,
                images: [...images.filter(img => img.isUploadedLocally).map(img => img.src), ...imageRefs],
                videos: [...videos.filter(video => video.isUploadedLocally).map(vid => vid.src), ...videoRefs],
            };

            const result = await this.context.apiGatewayClient.editFeedPost(feedPost);
            this.props.editFeedPost(result.data.feedPost);
            this.setState({isPostSubmitting: false, isInEditMode: false});
        });
    };

    deleteFeedPost = () => {
        const feedPost = {
            feedPostId: this.props.feedPost.postId,
            profileId: this.context.currentProfile.profileId,
        };
        this.setState({isPostDeleting: true}, () => {
            this.context.apiGatewayClient.deleteFeedPost(feedPost).then(() => {
                this.props.deleteFeedPost(this.props.feedPost.postId);
                this.setState({isPostDeleting: false, isDeleteFeedModalOpen: false});
            });
        });
    };

    createComment = async (content, images, videos) => {
        await this.setState({isCommentSubmitting: true}, async () => {
            const imageRefs = await Promise.all(images.map(async img => {
                const re = /(?:\.([^.]+))?$/;
                const ext = re.exec(img.file.name)[1]
                const result = await this.context.apiGatewayClient.getFeedMediaUploadUrl(ext);
                await this.context.s3Client.uploadImage(img.file, result.data.uploadUrl);
                return result.data.fileName;
            }));
            const videoRefs = await Promise.all(videos.map(async vid => {
                const re = /(?:\.([^.]+))?$/;
                const ext = re.exec(vid.file.name)[1]
                const result = await this.context.apiGatewayClient.getFeedMediaUploadUrl(ext);
                await this.context.s3Client.uploadImage(vid.file, result.data.uploadUrl);
                return result.data.fileName;
            }));

            const comment = {
                profileId: this.context.currentProfile.profileId,
                content: content,
                images: imageRefs,
                videos: videoRefs
            };

            const result = await this.context.apiGatewayClient.createFeedPostComment(this.props.feedPost.postId, comment);
            this.setState({isCommentModalOpen: false, isCommentSubmitting: false});
            if (!!this.props.onCommentSubmit) {
                this.props.onCommentSubmit(result.data.comment);
            }
            this.navigateToPost();
        });

    };

    setNewFeedContent = (newFeedContent) => {
        this.setState({newFeedContent: newFeedContent})
    };

    enterEditMode = () => {
        this.setState({isInEditMode: true});
    };

    exitEditMode = () => {
        this.setState({isInEditMode: false});
    };

    onLikeButtonClicked = (e, popupContext) => {
        e.stopPropagation();
        if (!this.context.authToken || !this.context.currentProfile) {
            popupContext.openSignInPopUp();
            return;
        }

        this.setState({isLiked: !this.state.isLiked, totalLikes: this.state.isLiked ? this.state.totalLikes - 1 : this.state.totalLikes + 1});
        this.context.apiGatewayClient.setFeedPostLikedStatus(this.props.feedPost.profileId, this.props.feedPost.postId, !this.state.isLiked, this.context.currentProfile.profileId,);
    }

    onFollowButtonClick = (e) => {
        e.stopPropagation();

        this.props.onFollowButtonClick(this.props.feedPost);
    }

    navigateToPost = () => {
        this.props.history.push(`/feedPost/${this.props.feedPost.postId}/profile/${this.props.feedPost.profileId}`);
    }

    render() {
        const {currentProfile} = this.context;
        const profileRedirectUrl = this.props.feedPost.profileType === ProfileType.Investor
            ? `/profile/${this.props.feedPost.profileId}`
            : `/profile/${this.props.feedPost.profileId}`;

        const content = [...getVideos(this.props.feedPost.videos), ...getImages(this.props.feedPost.images), ...getWebLinkPreviews(this.state.webLinkPreviews)];
        return (
            <div className={classes.FeedPost}>
                {this.state.isDeleteFeedModalOpen &&
                <GlobalPopup onOutsideClick={this.closeDeleteFeedPostModal}>
                    <div className={classes.DeleteFeedPostContainer}>
                        <h3>
                            Are you sure you want to delete this post?
                        </h3>
                        <div className={classes.DeleteSubmitContainer}>
                            <SubmitButton className={classes.EditSubmitCancelButton}
                                          isDisabled={this.state.isPostDeleting}
                                          onClick={this.closeDeleteFeedPostModal}>Cancel</SubmitButton>
                            <SubmitButton className={classes.EditSubmitButton}
                                          isSubmitting={this.state.isPostDeleting}
                                          onClick={this.deleteFeedPost}>Delete</SubmitButton>
                        </div>
                    </div>
                </GlobalPopup>
                }
                {this.state.isCommentModalOpen &&
                <GlobalPopup onOutsideClick={this.closeCommentModal}>
                    {!!currentProfile &&
                        <div className={classes.CreateCommentPopupContainer}>
                            <div className={classes.CloseCommentIconContainer} onClick={this.closeCommentModal}>
                                <CloseIcon />
                            </div>
                            <div className={classes.CommentReplyToConent}>
                                <div className={classes.FeedPostLeft}>
                                    {!this.props.feedPost.isProfileDeleted &&
                                    <NavLink to={profileRedirectUrl} onClick={e => e.stopPropagation()}>
                                        <div className={classes.Logo}>
                                            <ProfilePhotoWithDefault logo={this.props.feedPost.logo}
                                                                     name={this.props.feedPost.name}/>
                                        </div>
                                    </NavLink>
                                    }
                                    {this.props.feedPost.isProfileDeleted &&
                                    <div className={classes.Logo}>
                                        <i className="fas fa-user-slash"/>
                                    </div>
                                    }
                                    <div className={classes.FeedPostHeader}>
                                        <h3>{this.props.feedPost.isProfileDeleted ?
                                            <i>This profile no longer exists</i>
                                            : <NavLink to={profileRedirectUrl} onClick={e => e.stopPropagation()}>{this.props.feedPost.name}</NavLink>}
                                        </h3>
                                        <div className={classes.Timestamp}>
                                            {this.props.feedPost.creationDateEpoch !== this.props.feedPost.lastEditedDateEpoch &&
                                            <span>Edited</span>
                                            }
                                            {moment.unix(this.props.feedPost.lastEditedDateEpoch).fromNow()}
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.FeedPostRight}>
                                    <div className={classes.FeedPostContent}>
                                        {!this.state.isInEditMode &&
                                        <Fragment>
                                            <div className={classes.FeedPostText}>
                                                <FeedPostContent content={this.props.feedPost.content}/>
                                            </div>
                                            <Carousel>
                                                {content}
                                            </Carousel>
                                        </Fragment>
                                        }
                                    </div>
                                </div>
                            </div>
                            <CreateModal onSubmit={this.createComment}
                                         placeholder={"Create a comment..."}
                                         isSubmitting={this.state.isCommentSubmitting}/>
                        </div>
                    }
                </GlobalPopup>
                }
                <div className={classes.FeedPostPrimary} onClick={this.navigateToPost}>
                    <div className={classes.FeedPostLeft}>
                        {!!currentProfile && currentProfile.profileId === this.props.feedPost.profileId &&
                        <FeedPostOptions enterEditMode={this.enterEditMode}
                                         openDeleteFeedPostModal={this.openDeleteFeedPostModal}/>
                        }
                        {!this.props.feedPost.isProfileDeleted &&
                        <NavLink to={profileRedirectUrl} onClick={e => e.stopPropagation()}>
                            <div className={classes.Logo}>
                                <ProfilePhotoWithDefault logo={this.props.feedPost.logo}
                                                         name={this.props.feedPost.name}/>
                            </div>
                        </NavLink>
                        }
                        {this.props.feedPost.isProfileDeleted &&
                        <div className={classes.Logo}>
                            <i className="fas fa-user-slash"/>
                        </div>
                        }
                        <div className={classes.FeedPostHeader}>
                            <h3>{this.props.feedPost.isProfileDeleted ?
                                <i>This profile no longer exists</i>
                                : <NavLink to={profileRedirectUrl} onClick={e => e.stopPropagation()}>{this.props.feedPost.name}</NavLink>}
                            </h3>
                            <div className={classes.Timestamp}>
                                {this.props.feedPost.creationDateEpoch !== this.props.feedPost.lastEditedDateEpoch &&
                                <span>Edited</span>
                                }
                                {moment.unix(this.props.feedPost.lastEditedDateEpoch).fromNow()}
                            </div>
                        </div>
                    </div>
                    <div className={classes.FeedPostRight}>
                        <div className={classes.FeedPostContent}>
                            {!this.state.isInEditMode &&
                                <Fragment>
                                    <div className={classes.FeedPostText}>
                                        <FeedPostContent content={this.props.feedPost.content}/>
                                    </div>
                                    <Carousel>
                                        {content}
                                    </Carousel>
                                </Fragment>
                            }
                            {this.state.isInEditMode &&
                            <div className={classes.EditContainer} onClick={e => e.stopPropagation()}>
                                <div className={classes.Input}>
                                    <textarea maxLength="2000"
                                              onChange={e => this.setNewFeedContent(e.target.value)}
                                              value={this.state.newFeedContent}
                                    />
                                </div>
                                <div className={classes.EditSubmitContainer}>
                                    <FeedSubmit onSubmit={this.editFeedPost}
                                                images={!!this.props.feedPost.images ? this.props.feedPost.images.map(image => new Image(null, image, true)) : []}
                                                videos={!!this.props.feedPost.videos ? this.props.feedPost.videos.map(video => new Image(null, video, true)) : []}
                                                isDisabled={this.state.isPostSubmitting}
                                                shouldShowCancel={true}
                                                onCancel={this.exitEditMode}
                                                isSubmitting={this.state.isPostSubmitting}/>
                                </div>
                            </div>
                            }
                        </div>
                        <div className={classes.FeedPostActions}>
                            <PopupContext.Consumer>
                                {popupContext => (
                                    <Fragment>
                                        <div className={classes.FeedPostAction} onClick={e => this.openCommentModal(e, popupContext)}>
                                            <i className="far fa-comment" />
                                            <div className={classes.FeedPostActionValue}>
                                                {this.props.feedPost.totalComments}
                                            </div>
                                        </div>
                                        <div className={this.state.isLiked ? [classes.FeedPostAction, classes.FeedPostActionLiked].join(' ') : classes.FeedPostAction} onClick={e => this.onLikeButtonClicked(e, popupContext)}>
                                            <i className="far fa-thumbs-up"/>
                                            <div className={classes.FeedPostActionValue}>
                                                {this.state.totalLikes}
                                            </div>
                                        </div>
                                    </Fragment>
                                )}
                            </PopupContext.Consumer>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const getImages = (images) => {
    if (!images || images.length === 0) {
        return [];
    }
    return images.map((image, index) => {
        return (
            <div key={index} className={classes.ImageContainer}>
                <div className={classes.ImageContainerInner}>
                    <img loading="lazy" src={`${FeedAssetsBucketPrefix}${image}`} />
                </div>
            </div>
        );
    });
};

const getVideos = (videos) => {
    if (!videos || videos.length === 0) {
        return [];
    }
    return videos.map(video=> {
        return (
            <div className={classes.ImageContainer}>
                <div className={classes.ImageContainerInner}>
                    <video controls muted autoPlay playsInline loop src={`${FeedAssetsBucketPrefix}${video}`} />
                </div>
            </div>
        );
    });
};

const getWebLinkPreviews = (webLinkPreviews) => {
    if (!webLinkPreviews || webLinkPreviews.length === 0) {
        return [];
    }
    return webLinkPreviews.map(webLinkPreview => {
        return (
            <div className={classes.ImageContainer}>
                <div className={classes.ImageContainerInner}>
                    <WebLinkPreview
                        url={webLinkPreview.url}
                        domain={webLinkPreview.domain}
                        title={webLinkPreview.title}
                        img={webLinkPreview.img}
                        description={webLinkPreview.description}
                    />
                </div>
            </div>
        );
    });
}


export default withRouter(FeedPost);
